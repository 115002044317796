<template>
  <div
    id="inbox-messages-list"
    ref="messageListRef"
    class="messages-container is-flex is-flex-direction-column"
    v-chat-scroll="{ always: false, smooth: true, notSmoothOnInit: true }"
    @v-chat-scroll-top-reached="loadNextPage"
  >
    <div class="is-flex-grow-1"></div>
    <list-loading-indicator class="p-3" v-if="isLoading" :active="true"></list-loading-indicator>
    <message-list-item v-for="message in messages" :key="message.id" :message="message"></message-list-item>
    <list-loading-indicator class="p-3" v-if="isSending" :active="true"></list-loading-indicator>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import MessageListItem from "@/web/components/inbox/InboxMessageListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "InboxMessages",

  components: { ListLoadingIndicator, MessageListItem },

  props: {
    threadId: {
      type: Number,
    },

    isSending: {
      type: Boolean,
    },
  },

  data() {
    return {
      initScroll: true,
      savedScrollHeight: null,
      visibilityListener: null,
      isViewHidden: true,
    };
  },

  mounted() {
    this.loadMessagesFromNewThread();
    this.markThreadAsRead({ threadId: this.threadId });
    this.setInboxThreadVisible(this.threadId);
    this.addVisibilityListener();
  },

  destroyed() {
    this.removeVisibilityListener();
    this.setInboxThreadInvisible(this.threadId);
  },

  watch: {
    threadId(value) {
      this.markThreadAsRead({ threadId: value });
      this.setInboxThreadVisible(this.threadId);
      this.loadMessagesFromNewThread();
    },
  },

  updated() {
    //v-chat-scroll is handling scrolling for new elements and pagination callback, but somehow it doesn't want to scroll on the list initialization
    const listElement = this.$refs.messageListRef;

    if (this.savedScrollHeight && !this.isLoading) {
      //SCROLL TO SAVED POSITION PRE PAGINATION REQUEST
      listElement.scrollTop = listElement.scrollHeight - this.savedScrollHeight;
      this.savedScrollHeight = null;
    } else if (this.initScroll) {
      //INITIAL SCROLL
      if (this.isInitialized) {
        this.initScroll = false;
      }
      listElement.scrollTop = listElement.scrollHeight;
    } else {
      // new messages, vue-chat-scroll is handling this scenario
      if (!this.isViewHidden) {
        this.markThreadAsRead({ threadId: this.threadId });
      }
    }
  },

  methods: {
    ...mapActions("inboxMessages", ["loadMessagesPage"]),
    ...mapActions("inboxThreads", ["markThreadAsRead"]),
    ...mapMutations("notifications", ["setInboxThreadVisible", "setInboxThreadInvisible"]),

    loadMessagesFromNewThread() {
      //reset params
      this.initScroll = true;
      this.savedScrollHeight = null;
      // this.resetMeta(this.threadId);

      //load first page from new thread
      this.loadMessagesPage({ threadId: this.threadId });
    },

    loadNextPage() {
      //save scroll position only when there is more items to load
      if (this.isMore) {
        this.savedScrollHeight = this.$refs.messageListRef.scrollHeight;
        this.loadMessagesPage({ threadId: this.threadId });
      }
    },

    addVisibilityListener() {
      if (!this.visibilityListener) {
        this.isViewHidden = document[this.visibilityHiddenValue];
        this.visibilityListener = e => {
          if (this.isViewHidden !== document[this.visibilityHiddenValue]) {
            this.isViewHidden = document[this.visibilityHiddenValue];
            if (this.isViewHidden) {
              this.onInvisible();
            } else {
              this.onVisible();
            }
          }
        };
        document.addEventListener(this.visibilityChangeEvent, this.visibilityListener);
      }
    },

    removeVisibilityListener() {
      if (this.visibilityListener) {
        document.removeEventListener(this.visibilityChangeEvent, this.visibilityListener);
      }
    },

    onVisible() {
      this.markThreadAsRead({ threadId: this.threadId });
      this.setInboxThreadVisible(this.threadId);
    },

    onInvisible() {
      this.setInboxThreadInvisible(this.threadId);
    },
  },

  computed: {
    ...mapGetters("inboxMessages", ["messagesFromThread", "getIsLoading", "getIsMore", "getLastMessageTimestamp"]),

    messages() {
      return this.messagesFromThread(this.threadId);
    },

    isMore() {
      return this.getIsMore(this.threadId);
    },

    isInitialized() {
      return !!this.getLastMessageTimestamp(this.threadId);
    },

    isLoading() {
      return this.getIsLoading(this.threadId);
    },

    visibilityHiddenValue() {
      if (typeof document.msHidden !== "undefined") {
        return "msHidden";
      } else {
        return "hidden";
      }
    },

    visibilityChangeEvent() {
      if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange";
      } else {
        return "visibilitychange";
      }
    },
  },
};
</script>

<style scoped>
.messages-container {
  overflow-y: auto;
  max-height: calc(100% - 100px);
  height: calc(100% - 100px);
  padding-top: 14px;
}
</style>
