<template>
  <authorization-layout>
    <template>
      <div v-if="finished" class="has-text-centered mt-6">
        <div class="subtitle">
          {{ $t("auth.set_password_success") }}
        </div>
        <div class="button is-primary mt-2 px-4" @click="goToLoginPage" v-if="eventId">
          {{ $t("auth.log_in") }}
        </div>
      </div>
      <form v-else action="." method="POST" @submit.prevent="submit" class="modal-card">
        <div class="modal-card-subtitle mb-2 has-text-centered">
          {{ $t("auth.set_password_title") }}
        </div>
        <div class="field" v-if="email && email.length">
          <label class="label has-text-primary">{{ $t("auth.email") }}</label>
          <div class="control">
            <input class="input pl-1" type="text" :placeholder="$t('auth.email')" v-model="email" :disabled="true" />
          </div>
        </div>
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.password") }}</label>
          <div class="control">
            <input class="input is-simple" type="password" :placeholder="$t('auth.password')" v-model="password" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.password.required" class="help is-danger">{{ $t("auth.error_password") }}</p>
            <p v-if="!$v.password.minLength" class="help is-danger">{{ $t("common.field_too_short") }}</p>
          </template>
        </div>
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.repeat_password") }}</label>
          <div class="control">
            <input class="input is-simple" type="password" :placeholder="$t('auth.repeat_password')" v-model="repeatPassword" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.repeatPassword.sameAsPassword" class="help is-danger">{{ $t("auth.error_repeat_password") }}</p>
          </template>
        </div>

        <custom-register-form
          v-if="termsEnabled && customFormData"
          ref="customRegisterForm"
          :current-locale="currentLocale"
          :form-data="customFormData"
          :include-register-form="false"
          update-type="input"
          @input="handleCustomFormInput"
        />

        <b-loading :active="isRequesting"></b-loading>

        <button class="button is-primary is-fullwidth mt-1" type="submit" :disabled="isRequesting">
          {{ $t("auth.set_password") }}
        </button>

        <button class="button is-fullwidth mt-1" @click="goToMainPage" type="button" v-if="eventId && isNotEfniEvent">
          {{ $t("common.go_to_main_page") }}
        </button>
      </form>
    </template>
  </authorization-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";
import RequestMixin from "@/shared/mixins/RequestMixin";
import UnauthorizedRouteMixin from "@/web/mixins/UnauthorizedRouteMixin";
import Constants from "@/web/constants";
import ResetPasswordErrorModal from "@/web/views/modals/ResetPasswordErrorModal";
import { pluckErrorCode } from "@/shared/utils";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";

export default {
  name: "ResetPassword",

  components: { CustomRegisterForm, AuthorizationLayout },

  mixins: [RequestMixin, UnauthorizedRouteMixin],

  data() {
    return {
      submitted: false,
      password: "",
      repeatPassword: "",
      finished: false,
      customFormData: null,
      customFormValue: null,
    };
  },

  validations() {
    let validations = {
      password: { required, minLength: minLength(7) },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    };
    return validations;
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters("locales", ["currentLocale"]),
    ...mapGetters("consents", ["systemConsentsForm"]),
    ...mapState("consents", ["systemConsents"]),

    email() {
      return decodeURIComponent(this.$route.query.email || "");
    },

    token() {
      return this.$route.query.token;
    },

    termsEnabled() {
      return this.$route.query.terms === "true";
    },
  },

  methods: {
    ...mapActions("auth", ["updateUserPasswordWithToken"]),
    ...mapActions("consents", ["fetchSystemConsents"]),

    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      // Validate custom form
      const customForm = this.$refs.customRegisterForm;
      if (customForm) {
        customForm.touch();
      }

      // Check both standard and custom form validation
      const isCustomFormValid = customForm ? customForm.isValid() : true;

      if (!this.$v.$invalid && isCustomFormValid) {
        const { password, repeatPassword } = this.$data;
        const formData = {
          token: this.token,
          newPassword: password,
          newPasswordConfirmation: repeatPassword,
        };

        // Map custom form data to consents array if exists
        if (customForm) {
          const customFormValues = this.customFormValues;
          const consents = this.systemConsents.map(consent => ({
            consent_schema_id: consent.id,
            version_number: consent.version_number,
            accepted: customFormValues[consent.id] === true,
          }));
          formData.consents = consents;
        } else {
          formData.consents = [];
        }

        this.save(formData);
      }
    },

    handleCustomFormInput(formData) {
      this.customFormValues = formData?.metaData;
    },

    goToLoginPage() {
      this.$router.replace({ name: Constants.ROUTE_LOGIN });
    },

    goToMainPage() {
      this.$router.replace({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    },
  },

  requests: {
    async loadConsents() {
      await this.fetchSystemConsents();
    },

    async save(formData) {
      await this.updateUserPasswordWithToken(formData).then(result => {
        this.finished = true;
      });
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        const errorCode = pluckErrorCode(newVal);
        if (errorCode === Constants.REST_RESET_PASSWORD_TOKEN_EXPIRED) {
          this.$buefy.modal.open({
            parent: this,
            component: ResetPasswordErrorModal,
            hasModalCard: true,
            canCancel: true,
            trapFocus: true,
          });
        } else {
          this.$root.openErrorModal(newVal);
        }
        this.error = null;
      }
    },

    token: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.$router.replace({ name: Constants.ROUTE_MAIN_INTRODUCTION });
        }
      },
    },

    currentLocale: {
      immediate: true,
      handler: async function () {
        await this.loadConsents();
      },
    },

    systemConsentsForm: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$mergeCustomTranslations(newValue[FormBuilderConstants.TRANSLATIONS], FormBuilderConstants.TRANSLATIONS_NAMESPACE);
          this.customFormData = newValue;
        }
      },
    },
  },
};
</script>

<style scoped>
.subtitle {
  white-space: pre-line;
}
</style>
