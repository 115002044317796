<template>
  <div class="buttons">
    <!-- Profile navigation -->
    <template v-if="isAuthorized && currentUser">
      <b-button
        type="is-text"
        v-if="inboxEnabled"
        class="is-navbar-item is-borderless pl-1 pr-1"
        @click="navigateToInbox"
        v-prevent-parent-scroll
      >
        <action-overlay-view right="-20%" top="-10%">
          <template>
            <div class="navbar-icon-container">
              <message-icon class="navbar-icon-color inbox-button-icon" :style="fill"></message-icon>
            </div>
          </template>

          <template #overlay>
            <div v-if="unreadMessagesCount > 0" class="unread-circle"></div>
          </template>
        </action-overlay-view>
      </b-button>

      <b-dropdown position="is-bottom-left" ref="notificationsDropdown" aria-role="menu" trap-focus>
        <template #trigger>
          <b-button type="is-text" class="is-navbar-item is-borderless pl-1 pr-1">
            <action-overlay-view right="0" top="5%">
              <template>
                <div class="navbar-icon-container">
                  <notifications-icon class="navbar-icon-color notifications-button-icon"
                                      :style="fill"></notifications-icon>
                </div>
              </template>

              <template #overlay>
                <div v-if="hasUnreadNotifications" class="unread-circle"></div>
              </template>
            </action-overlay-view>
          </b-button>
        </template>

        <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
          <nav-notifications @toggle-dropdown="$refs.notificationsDropdown.toggle()"></nav-notifications>
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown position="is-bottom-left" trap-focus can-close close-on-click v-prevent-parent-scroll>
        <b-button type="is-text" class="is-navbar-item is-borderless pl-1 pr-1" slot="trigger">
          <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <profile-picture class="is-28x28 mr-1" style="font-size: 11px" :picture="picture"
                             :initials="initials"></profile-picture>
            <arrow-icon class="navbar-icon-color" :style="fill"></arrow-icon>
          </div>
        </b-button>
        <b-dropdown-item custom :focusable="false">
          <strong class="has-text-primary">{{ fullName }}</strong>
        </b-dropdown-item>
        <b-dropdown-item :has-link="true" @click="$emit('toggle')">
          <router-link :to="{ name: Constants.ROUTE_MY_PROFILE }">
            {{ $t("my_profile.my_profile_title") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item :has-link="true" v-if="isModerator">
          <router-link :to="{ name: Constants.ROUTE_MODERATOR_MAIN }">
            {{ $t("moderator.nav_profile_label") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item :has-link="true">
          <router-link :to="{ name: Constants.ROUTE_FRIENDS }">
            {{ $t("friends.my_friends") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item :has-link="true" v-if="businessMatchingModules.length" @click="$emit('toggle')">
          <router-link :to="{ name: Constants.ROUTE_AVAILABILITY }">
            {{ $t("business_matching.availability_button") }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item @click="logoutUser">
          <span>{{ $t("auth.logout") }}</span>
        </b-dropdown-item>
      </b-dropdown>

      <div v-if="eventId === 1643 || eventId === 104">
        <button type="button" class="ml-2 button is-primary" @click="navigateToTicketsSection">
          <strong>{{ $t("custom.rzecommerce_get_ticket") }}</strong>
        </button>
      </div>

      <div v-if="eventId === 1419">
        <button type="button" class="ml-2 button is-primary" @click="navigateToPedBuyTicket">
          <strong>{{ $t("custom.ped_get_ticket") }}</strong>
        </button>
      </div>
    </template>

    <div v-else-if="eventId === 1298">
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
      <button type="button" class="button is-primary" style="background: #cb8204" @click="navigateToBuyTicket">
        <strong>{{ $t("ticket.buy_ticket") }}</strong>
      </button>
    </div>

    <div v-else-if="eventId === 1347">
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("custom.forumfinansow_get_ticket") }}</strong>
      </button>
    </div>

    <!-- ForFin -->
    <div v-else-if="eventId === 1538">
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("custom.forfin_get_ticket") }}</strong>
      </button>
    </div>

    <!-- ARAWU -->
    <div v-else-if="eventId === 1426">
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("custom.arawu_get_ticket") }}</strong>
      </button>
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
    </div>

    <!-- Rzecommerce 2024 -->
    <div v-else-if="eventId === 1643 || this.eventId === 104">
      <button type="button" class="button is-primary" @click="navigateToTicketsSection">
        <strong>{{ $t("custom.rzecommerce_get_ticket") }}</strong>
      </button>
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
    </div>

    <!-- PED -->
    <div v-else-if="eventId === 1419 || eventId === 118">
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("custom.ped_get_ticket") }}</strong>
      </button>
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
    </div>

    <div v-else-if="eventId === 1312">
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("custom.pism_get_ticket") }}</strong>
      </button>
    </div>

    <div v-else-if="eventId === 1251">
      <button type="button" class="button is-primary" @click="navigateToBuyTicket">
        <strong>{{ $t("ticket.buy_ticket") }}</strong>
      </button>
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
    </div>

    <!-- Sign up/in buttons -->
    <div v-else v-prevent-parent-scroll>
      <button type="button" class="button is-primary" @click="openRegisterModal">
        <strong>{{ $t("auth.dont_have_an_account_substring") }}</strong>
      </button>
      <button type="button" class="button is-light" @click="openLoginModal">
        {{ $t("auth.already_have_an_account_substring") }}
      </button>
    </div>
  </div>
</template>

<script>
import Constants from "@/web/constants";
import {mapActions, mapGetters, mapState} from "vuex";
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import MessageIcon from "@/assets/navbar/message-icon.svg";
import NotificationsIcon from "@/assets/navbar/notifications-icon.svg";
import ArrowIcon from "@/assets/navbar/arrow-icon.svg";
import NavNotifications from "@/web/components/notifications/NavNotifications";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import {navScrollTo} from "@/shared/utils";

export default {
  name: "NavProfile",

  components: {
    NavNotifications,
    ActionOverlayView,
    MessageIcon,
    ProfilePicture,
    NotificationsIcon,
    ArrowIcon,
  },

  props: {
    fill: {
      type: Object,
      default: () => {
      },
    },
  },

  methods: {
    ...mapActions(["logout"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithUser"]),

    openLoginModal() {
      this.$root.openLoginModal();
      this.$emit("toggle");
    },

    openRegisterModal() {
      this.$root.openRegisterModal();
      this.$emit("toggle");
    },

    logoutUser() {
      this.logout();
      this.$emit("toggle");
    },

    onToggleNotificationDropdown() {
      this.$refs.notificationsDropdown.toggle();
      this.$emit("toggle");
    },

    navigateToBuyTicket() {
      this.$router.push({name: Constants.ROUTE_BUY_TICKET_NO_SELECTION});
    },

    navigateToPedBuyTicket() {
      this.$router.push({name: Constants.ROUTE_BUY_TICKET, params: {ticketId: 510}});
    },

    navigateToTicketsSection() {
      navScrollTo("module-4", "smooth");
    },

    navigateToInbox() {
      this.openInboxBubbleWithUser(null);
    },
  },

  computed: {
    ...mapGetters(["requiresLogin", "inboxEnabled", "businessMatchingModules"]),
    ...mapState(["eventId"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("currentUser", ["currentUser", "fullName", "initials", "picture"]),
    ...mapGetters("inbox", ["unreadMessagesCount"]),
    ...mapGetters("notifications", ["hasUnreadNotifications"]),
    ...mapGetters("moderatorAccesses", {isModerator: "hasAccess"}),

    Constants: () => Constants,
  },

  watch: {
    isUnauthorized() {
      // Redirect user on logout
      // Works only on events that requires login
      if (this.requiresLogin) {
        this.$router.push({name: Constants.ROUTE_LOGIN});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-icon-color {
  fill: #848484;
}

.button:active,
.button.is-active {
  .navbar-icon-color {
    fill: #ffffff;
  }
}

.loading-overlay.is-small {
  font-size: 10px;
}

.unread-circle {
  background: var(--primary-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.profile-button-icon {
  width: 24px;
  height: 24px;
}

.inbox-button-icon {
  width: 24px;
  height: 24px;
}

.notifications-button-icon {
  width: 30px;
  height: 30px;
}
</style>
