<template>
  <div class="survey-answer-open">
    <p>{{ responseText }}</p>
  </div>
</template>

<script>
export default {
  props: ["response"],

  computed: {
    responseText() {
      return this.response.response;
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-answer-open {
  margin-bottom: 20px;
  padding: 0.8em 1em 1em 1em;
  background-color: #f3f5ff;
  border-radius: 10px;
}

p {
  margin: 0;
  line-height: 1.2;
}
</style>
