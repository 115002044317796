<template>
  <section class="container mt-3">
    <separator class="separator is-primary"></separator>
    <h2 class="title is-2 has-text-centered mb-4">
      {{ title }}
    </h2>
    <search-input class="mb-4" :placeholder="$t('attendees.search_hint_attendees')" v-model="searchQuery"></search-input>
    <attendees-list @load-more="loadAttendeesPage" :attendees="attendees" :is-loading="isLoading"></attendees-list>
  </section>
</template>

<script>
import AttendeesList from "@/web/components/attendees/AttendeesList";
import SearchInput from "@/shared/components/SearchInput";
import Separator from "@/web/components/shared/Separator";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";

export default {
  name: "Attendees",

  components: { SearchInput, Separator, AttendeesList },

  mixins: [AuthorizedRouteMixin],

  watch: {
    agendaSessionId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.resetMeta();
        this.setAgendaSessionTitle(null);
        if (newVal) {
          this.setAgendaSessionAttendeesSource(newVal);
          this.loadAgendaSession({ agendaSessionId: newVal, agendaComponentId: this.$route.params.componentId });
        } else {
          this.setEventAttendeesSource();
        }
        this.loadAttendeesPage();
      },
    },

    searchQuery: function (newVal) {
      if (newVal.length) {
        this.searchAttendees(newVal);
      } else {
        this.clearSearchResult();
      }
    },
  },

  data() {
    return {
      searchQuery: "",
    };
  },

  methods: {
    ...mapActions("attendees", ["loadAttendeesPage", "searchAttendees", "loadAgendaSession"]),
    ...mapMutations("attendees", [
      "clearSearchResult",
      "setAgendaSessionAttendeesSource",
      "setEventAttendeesSource",
      "resetMeta",
      "setAgendaSessionTitle",
    ]),
  },

  computed: {
    ...mapGetters("components", ["getComponentById"]),
    ...mapGetters("attendees", ["getAttendees", "isLoading"]),
    ...mapState("attendees", ["agendaSessionTitle"]),

    componentId() {
      return Number(this.$route.params.componentId);
    },

    attendeesComponent() {
      return this.getComponentById(this.componentId);
    },

    title() {
      if (this.agendaSessionId) {
        return this.agendaSessionTitle || this.$t("attendees.title");
      } else {
        return this.attendeesComponent.label;
      }
    },

    attendees() {
      return this.getAttendees(this.searchQuery);
    },

    agendaSessionId() {
      return this.$route.params.agendaSessionId;
    },
  },
};
</script>
