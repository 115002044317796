import axios from "@/web/services/axios";

export async function login(email, password) {
  const requestData = { email, password };
  return makeAuthRequest("post", "login", requestData);
}

export async function loginViaFacebook(accessToken, gdprConsent) {
  const requestData = { token: accessToken };
  if (gdprConsent) {
    requestData.gdpr = true;
  }
  return makeAuthRequest("post", "sign_in_facebook", requestData);
}

export async function loginViaGoogle(googleToken, gdprConsent) {
  const requestData = { token: googleToken };
  if (gdprConsent) {
    requestData.gdpr = true;
  }
  return makeAuthRequest("post", "sign_in_google", requestData);
}

export async function loginViaApple(appleToken, gdprConsent, firstName, lastName) {
  const requestData = { token: appleToken, first_name: "Apple", last_name: "User" };
  if (gdprConsent) {
    requestData.gdpr = true;
    requestData.first_name = firstName;
    requestData.last_name = lastName;
  }
  return makeAuthRequest("post", "sign_in_apple", requestData);
}

export async function loginViaMagicLink(magicLinkToken) {
  const requestData = { token: magicLinkToken };
  return makeAuthRequest("post", "magic_link/authenticate", requestData);
}

export async function register(email, password, firstName, lastName, eventId, consents) {
  let requestData = {
    email,
    password,
    password_confirmation: password,
    first_name: firstName,
    last_name: lastName,
    consents: consents,
    source: "lp_legacy",
    event_id: eventId,
  };
  return makeAuthRequest("post", "register", requestData);
}

export async function refresh(refreshToken) {
  const requestData = { refresh_token: refreshToken };
  return makeAuthRequest("post", "refresh_token", requestData);
}

export async function logout() {
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "delete", url: "logout" });
  return true;
}

export async function reset(email, event_id) {
  const data = {
    email: email,
    event_id: event_id,
    source: "lp_legacy",
  };
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "post", url: "password/forgot", data: data });
  return true;
}

export async function joinToEvent(eventId) {
  const res = await axios.post(`events/${eventId}/join`);
  const status = res && res.data && res.data["status"];
  return status === "ok";
}

export async function requestJoinToEvent(eventId) {
  await axios.post(`events/${eventId}/user/request_join_to_event`);
  return true;
}

export async function deleteAccount() {
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "delete", url: "user" });
  return true;
}

export async function changePassword(newPassword, newPasswordConfirmation, currentPassword) {
  const data = {
    current_password: currentPassword,
    password: newPassword,
    password_confirmation: newPasswordConfirmation,
  };
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "put", url: "password", data: data });
  return true;
}

export async function resetPasswordWithToken(token, newPassword, newPasswordConfirmation, consents) {
  const data = {
    token: token,
    password: newPassword,
    password_confirmation: newPasswordConfirmation,
    consents: consents,
  };
  //consents: [:consent_schema_id, :version_number, :accepted]
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "post", url: "password/reset", data: data });
  return true;
}

export async function resendEmailConfirmation({ email, eventId }) {
  const data = {
    email: email,
    event_id: eventId,
    source: "lp_legacy",
  };
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "post", url: "confirm/resend", data: data });
  return true;
}

async function sendMagicLink(email, eventId) {
  const data = {
    email: email,
    event_id: eventId,
    source: "lp_legacy",
  };
  await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method: "post", url: "magic_link", data: data });
  return true;
}

async function makeAuthRequest(method, url, data) {
  const response = await axios.request({ baseURL: process.env.VUE_APP_API_AUTH_HOST_URL, method, url, data });
  return parseResponse(response);
}

function parseResponse(response) {
  const { data } = response;

  return {
    jwtToken: data.jwt_token,
    refreshToken: data.refresh_token,
    expirationDate: data.expiration_date,
  };
}

export default {
  login,
  loginViaFacebook,
  loginViaGoogle,
  loginViaApple,
  register,
  refresh,
  logout,
  reset,
  joinToEvent,
  requestJoinToEvent,
  deleteAccount,
  changePassword,
  resetPasswordWithToken,
  resendEmailConfirmation,
  sendMagicLink,
  loginViaMagicLink,
};
