import accessCodeService from "@/web/services/access-code";
import createStorage from "@/web/services/storage";

const accessCodeStorage = createStorage("access-code");

export const state = () => ({
  accessCode: null,
});

export const mutations = {
  setAccessCode(state, accessCode) {
    state.accessCode = accessCode;
  },

  clearAccessCode(state) {
    state.accessCode = null;
  },
};

export const actions = {
  async checkAccessCode({ commit }, { eventId, accessCode }) {
    try {
      await accessCodeService.check(eventId, accessCode);
      accessCodeStorage.store(eventId, accessCode);
      await commit("setAccessCode", accessCode);
    } catch (err) {
      accessCodeStorage.clear(eventId);
      await commit("clearAccessCode");
      throw err;
    }
  },

  async retrieveAccessCode({ dispatch }, eventId) {
    const accessCode = accessCodeStorage.retrieve(eventId);

    if (accessCode && accessCode.length > 0) {
      await dispatch("checkAccessCode", { eventId, accessCode });
    }
  },

  async clearAccessCode({ commit, rootState }) {
    const eventId = rootState.eventId;
    accessCodeStorage.clear(eventId);
    await commit("clearAccessCode");
  },
};

export const getters = {
  isValid(state) {
    return !!state.accessCode;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
