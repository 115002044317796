<template>
  <div></div>
</template>

<script>
export default {
  name: "ModeratorSpeaker",

  props: {
    speakerId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
