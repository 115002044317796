<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">{{ $t("admin_panel.header_configuration_title") }}</div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--BACKGROUND IMAGE URL-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.header_background_image_url_title") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editBackgroundImageUrl">
        {{ backgroundImageUrlTranslationKey }}
      </b-button>
    </div>

    <!--AUTO RESIZE-->
    <b-field>
      <b-switch v-model="$data[headerFields.IMAGE_AUTO_RESIZE]">
        {{ $t("admin_panel.header_background_auto_resize_title") }}
      </b-switch>
    </b-field>

    <!--SHOW DETAILS-->
    <b-field v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <b-switch v-model="$data[headerFields.SHOW_DETAILS]">
        {{ $t("admin_panel.header_background_auto_show_details_title") }}
      </b-switch>
    </b-field>

    <!--SHOW DATE-->
    <b-field v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <b-switch v-model="$data[headerFields.SHOW_DATE]" :disabled="!$data[headerFields.SHOW_DETAILS]">
        {{ $t("admin_panel.header_show_date_title") }}
      </b-switch>
    </b-field>

    <!--SHOW LOCALIZATION-->
    <b-field v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <b-switch v-model="$data[headerFields.SHOW_LOCATION]" :disabled="!$data[headerFields.SHOW_DETAILS]"
        >{{ $t("admin_panel.header_show_location_title") }}
      </b-switch>
    </b-field>

    <!--IMAGE REPEAT-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_repeat_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper :items="imageRepeatValues" v-model="$data[headerFields.IMAGE_REPEAT]"></dropdown-select-wrapper>
      </div>
    </div>

    <!--IMAGE POSITION-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_position_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper :items="imagePositionValues" v-model="$data[headerFields.IMAGE_POSITION]"></dropdown-select-wrapper>
      </div>
    </div>

    <!--IMAGE SIZE-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_size_title") }}</label>
      <div class="control">
        <dropdown-select-wrapper :items="imageSizeValues" v-model="$data[headerFields.IMAGE_SIZE]"></dropdown-select-wrapper>
      </div>
    </div>

    <!--HEADER HEIGHT-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_height_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.header_height_title')"
          v-model="$data[headerFields.HEIGHT]"
        />
      </div>
    </div>

    <!--HEADER BACKGROUND COLOR-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_background_hex_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.header_background_hex_color_title')"
          v-model="$data[headerFields.BACKGROUND_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[headerFields.BACKGROUND_COLOR].hexColor" class="help is-danger">
          {{ $t("admin_panel.error_hex_color") }}
        </p>
      </template>
    </div>

    <!--HEADER TEXT COLOR-->
    <div class="field" v-if="!$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_text_hex_color_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="color"
          :placeholder="$t('admin_panel.header_text_hex_color_title')"
          v-model="$data[headerFields.TEXT_COLOR]"
        />
      </div>
      <template>
        <p v-if="!$v[headerFields.TEXT_COLOR].hexColor" class="help is-danger">{{ $t("admin_panel.error_hex_color") }}</p>
      </template>
    </div>

    <!--BACKGROUND IMAGE AUTO RESIZE MARGIN TOP-->
    <div class="field" v-if="$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_margin_top_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.header_image_margin_top_title')"
          v-model="$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP]"
        />
      </div>
    </div>
    <!--BACKGROUND IMAGE AUTO RESIZE MARGIN BOTTOM-->
    <div class="field" v-if="$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_margin_bottom_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.header_image_margin_bottom_title')"
          v-model="$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM]"
        />
      </div>
    </div>
    <!--BACKGROUND IMAGE AUTO RESIZE MARGIN LEFT-->
    <div class="field" v-if="$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_margin_left_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.header_image_margin_left_title')"
          v-model="$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT]"
        />
      </div>
    </div>
    <!--BACKGROUND IMAGE AUTO RESIZE MARGIN RIGHT-->
    <div class="field" v-if="$data[headerFields.IMAGE_AUTO_RESIZE]">
      <label class="label has-text-primary">{{ $t("admin_panel.header_image_margin_right_title") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.header_image_margin_right_title')"
          v-model="$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import DropdownSelectWrapper from "@/shared/components/form/DropdownSelectWrapper";
import { hexColor } from "@/shared/utils/form-validations";

export default {
  name: "AdminHeaderConfiguration",
  components: { DropdownSelectWrapper },
  data() {
    const headerFields = LpConfigConstants.HEADER_FIELDS;

    return {
      [headerFields.IMAGE_URL]: null,
      [headerFields.IMAGE_AUTO_RESIZE]: null,
      [headerFields.SHOW_DETAILS]: null,
      [headerFields.SHOW_DATE]: null,
      [headerFields.SHOW_LOCATION]: null,
      [headerFields.HEIGHT]: null,
      [headerFields.TEXT_COLOR]: null,
      [headerFields.BACKGROUND_COLOR]: null,
      [headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM]: null,
      [headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP]: null,
      [headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT]: null,
      [headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT]: null,
      [headerFields.IMAGE_REPEAT]: null,
      [headerFields.IMAGE_SIZE]: null,
      [headerFields.IMAGE_POSITION]: null,

      awaitingFieldForTranslationKey: null,
    };
  },

  validations() {
    const headerFields = LpConfigConstants.HEADER_FIELDS;

    return {
      [headerFields.TEXT_COLOR]: { hexColor },
      [headerFields.BACKGROUND_COLOR]: { hexColor },
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapState("adminPanel", ["header"]),

    headerFields: () => LpConfigConstants.HEADER_FIELDS,

    backgroundImageUrlTranslationKey() {
      return this.$data[this.headerFields.IMAGE_URL] || this.$t("admin_panel.select_translation_key");
    },

    imageRepeatValues() {
      return Object.values(LpConfigConstants.BACKGROUND_REPEAT_VALUES);
    },

    imageSizeValues() {
      return Object.values(LpConfigConstants.BACKGROUND_SIZE_VALUES);
    },

    imagePositionValues() {
      return Object.values(LpConfigConstants.BACKGROUND_POSITION_VALUES);
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setHeaderConfig"]),

    editBackgroundImageUrl() {
      this.awaitingFieldForTranslationKey = LpConfigConstants.HEADER_FIELDS.IMAGE_URL;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey === LpConfigConstants.HEADER_FIELDS.IMAGE_URL) {
        this.$data[this.headerFields.IMAGE_URL] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },

    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setHeaderConfig(this.getHeaderConfig());
        this.$root.$emit("close-side-panel");
        this.$emit("navigate-back");
      }
    },

    getHeaderConfig() {
      const headerFields = LpConfigConstants.HEADER_FIELDS;
      return {
        [headerFields.IMAGE_URL]: this.$data[headerFields.IMAGE_URL],
        [headerFields.BACKGROUND_COLOR]: this.$data[headerFields.BACKGROUND_COLOR],
        [headerFields.TEXT_COLOR]: this.$data[headerFields.TEXT_COLOR],
        [headerFields.HEIGHT]: this.$data[headerFields.HEIGHT],
        [headerFields.SHOW_DATE]: this.$data[headerFields.SHOW_DATE],
        [headerFields.SHOW_LOCATION]: this.$data[headerFields.SHOW_LOCATION],
        [headerFields.SHOW_DETAILS]: this.$data[headerFields.SHOW_DETAILS],
        [headerFields.IMAGE_AUTO_RESIZE]: this.$data[headerFields.IMAGE_AUTO_RESIZE],
        [headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM]: this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM],
        [headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP]: this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP],
        [headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT]: this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT],
        [headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT]: this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT],
        [headerFields.IMAGE_REPEAT]: this.$data[headerFields.IMAGE_REPEAT],
        [headerFields.IMAGE_POSITION]: this.$data[headerFields.IMAGE_POSITION],
        [headerFields.IMAGE_SIZE]: this.$data[headerFields.IMAGE_SIZE],
      };
    },
  },

  watch: {
    header: {
      immediate: true,
      handler: function (newValue) {
        const headerFields = LpConfigConstants.HEADER_FIELDS;

        this.$data[headerFields.IMAGE_URL] = newValue[headerFields.IMAGE_URL];
        this.$data[headerFields.IMAGE_AUTO_RESIZE] = newValue[headerFields.IMAGE_AUTO_RESIZE];
        this.$data[headerFields.SHOW_DETAILS] = newValue[headerFields.SHOW_DETAILS];
        this.$data[headerFields.SHOW_DATE] = newValue[headerFields.SHOW_DATE];
        this.$data[headerFields.SHOW_LOCATION] = newValue[headerFields.SHOW_LOCATION];
        this.$data[headerFields.HEIGHT] = newValue[headerFields.HEIGHT];
        this.$data[headerFields.TEXT_COLOR] = newValue[headerFields.TEXT_COLOR];
        this.$data[headerFields.BACKGROUND_COLOR] = newValue[headerFields.BACKGROUND_COLOR];
        this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM] = newValue[headerFields.IMAGE_AUTO_RESIZE_MARGIN_BOTTOM];
        this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP] = newValue[headerFields.IMAGE_AUTO_RESIZE_MARGIN_TOP];
        this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT] = newValue[headerFields.IMAGE_AUTO_RESIZE_MARGIN_LEFT];
        this.$data[headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT] = newValue[headerFields.IMAGE_AUTO_RESIZE_MARGIN_RIGHT];
        this.$data[headerFields.IMAGE_REPEAT] = newValue[headerFields.IMAGE_REPEAT];
        this.$data[headerFields.IMAGE_POSITION] = newValue[headerFields.IMAGE_POSITION];
        this.$data[headerFields.IMAGE_SIZE] = newValue[headerFields.IMAGE_SIZE];
      },
    },

    [LpConfigConstants.HEADER_FIELDS.SHOW_DETAILS]: function (newValue) {
      if (newValue) {
        this.$data[LpConfigConstants.HEADER_FIELDS.SHOW_DATE] = true;
        this.$data[LpConfigConstants.HEADER_FIELDS.SHOW_LOCATION] = true;
      } else {
        this.$data[LpConfigConstants.HEADER_FIELDS.SHOW_DATE] = false;
        this.$data[LpConfigConstants.HEADER_FIELDS.SHOW_LOCATION] = false;
      }
    },
  },
};
</script>

<style scoped></style>
