import axios from "@/web/services/axios";

export async function initP24Payment(
  eventId,
  ticketId,
  personalForm,
  discountCode,
  marketingAgreement,
  gdprConsent,
  guestsTickets,
  currentUserSpecificFields,
  returnUrl
) {
  const body = {
    ticket_id: ticketId,
    personal_form: personalForm,
    specific_fields: currentUserSpecificFields,
    return_url: returnUrl,
    discount_code: discountCode,
    marketing_agreement: marketingAgreement,
    gdpr_consent: gdprConsent,
    tickets_for: guestsTickets,
  };
  const res = await axios.post(`events/${eventId}/payment/p24/init`, body);
  return res.data && res.data.token;
}

export async function initStripePayment(
  eventId,
  ticketId,
  personalForm,
  discountCode,
  marketingAgreement,
  gdprConsent,
  guestsTickets,
  currentUserSpecificFields
) {
  const body = {
    ticket_id: ticketId,
    personal_form: personalForm,
    specific_fields: currentUserSpecificFields,
    discount_code: discountCode,
    marketing_agreement: marketingAgreement,
    gdpr_consent: gdprConsent,
    tickets_for: guestsTickets,
  };
  const res = await axios.post(`events/${eventId}/payment/stripe/init`, body);
  return res.data && res.data.client_secret;
}

export async function initPayuPayment(
  eventId,
  ticketId,
  personalForm,
  discountCode,
  marketingAgreement,
  gdprConsent,
  guestsTickets,
  currentUserSpecificFields,
  returnUrl
) {
  const body = {
    ticket_id: ticketId,
    personal_form: personalForm,
    specific_fields: currentUserSpecificFields,
    return_url: returnUrl,
    discount_code: discountCode,
    marketing_agreement: marketingAgreement,
    gdpr_consent: gdprConsent,
    tickets_for: guestsTickets,
  };
  const res = await axios.post(`events/${eventId}/payment/payu/init`, body);
  const redirect_url = res.data && res.data.redirect_url;
  const order_id = res.data && res.data.order_id;

  return {
    redirect_url: redirect_url,
    order_id: order_id,
  };
}

export async function initFreePayment(
  eventId,
  ticketId,
  personalForm,
  guestsTickets,
  discountCode,
  marketingAgreement,
  gdprConsent,
  currentUserSpecificFields
) {
  const body = {
    ticket_id: ticketId,
    personal_form: personalForm,
    specific_fields: currentUserSpecificFields,
    discount_code: discountCode,
    marketing_agreement: marketingAgreement,
    gdpr_consent: gdprConsent,
    tickets_for: guestsTickets,
  };
  const res = await axios.post(`events/${eventId}/payment/free/init`, body);
  return res.data;
}

export async function initBlueMediaPayment(
  eventId,
  ticketId,
  personalForm,
  discountCode,
  marketingAgreement,
  gdprConsent,
  guestsTickets,
  currentUserSpecificFields,
  blueMediaGatewayId
) {
  const body = {
    ticket_id: ticketId,
    personal_form: personalForm,
    specific_fields: currentUserSpecificFields,
    discount_code: discountCode,
    marketing_agreement: marketingAgreement,
    gdpr_consent: gdprConsent,
    tickets_for: guestsTickets,
    gateway_id: blueMediaGatewayId,
  };
  const res = await axios.post(`events/${eventId}/payment/bluemedia/init`, body);
  const redirect_url = res.data && res.data.redirect_url;
  const order_id = res.data && res.data.redirect_url;
  return {
    redirect_url: redirect_url,
    order_id: order_id,
  };
}

export async function initProformaPayment(eventId, ticketId, personalForm, guestsTickets, discountCode, marketingAgreement, gdprConsent, currentUserSpecificFields) {
  const body = { ticket_id: ticketId, personal_form: personalForm, specific_fields: currentUserSpecificFields, discount_code: discountCode, marketing_agreement: marketingAgreement, gdpr_consent: gdprConsent, tickets_for: guestsTickets };
  const res = await axios.post(`events/${eventId}/payment/proforma/init`, body);
  return res.data && res.data.invoice_download_url;
}

export async function checkVatNumberInVies(eventId, vatNumber) {
  const res = await axios.get(`events/${eventId}/payment/invoice/vies/`, { params: { vat_number: vatNumber } });
  return res.data;
}

export default {
  initP24Payment,
  initPayuPayment,
  initStripePayment,
  initFreePayment,
  initBlueMediaPayment,
  checkVatNumberInVies,
  initProformaPayment,
};
