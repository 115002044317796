<template>
  <div class="py-3 is-fullheight">
    <div class="moderator-accesses-container box is-radius-large is-paddingless">
      <div class="moderator-accesses-section">
        <div class="moderator-accesses-section-title">
          {{ $t("moderator.exhibitors_access_section_title") }}
        </div>
        <template v-if="exhibitorAccesses.length">
          <exhibitor-access-list-item
            v-for="exhibitor in exhibitorAccesses"
            :key="`exhibitor-access-${exhibitor.id}`"
            :exhibitor="exhibitor"
          ></exhibitor-access-list-item>
        </template>
        <empty-exhibitor-accesses-placeholder v-else></empty-exhibitor-accesses-placeholder>
      </div>

      <div class="moderator-accesses-section">
        <div class="moderator-accesses-section-title">
          {{ $t("moderator.speakers_access_section_title") }}
        </div>
        <template v-if="speakerAccesses.length">
          <speaker-access-list-item
            v-for="speaker in speakerAccesses"
            :key="`speaker-access-${speaker.id}`"
            :speaker="speaker"
          ></speaker-access-list-item>
        </template>
        <empty-speaker-accesses-placeholder v-else></empty-speaker-accesses-placeholder>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExhibitorAccessListItem from "@/web/components/moderator/accesses/ExhibitorAccessListItem";
import SpeakerAccessListItem from "@/web/components/moderator/accesses/SpeakerAccessListItem";
import EmptySpeakerAccessesPlaceholder from "@/web/components/moderator/placeholders/EmptySpeakerAccessesPlaceholder";
import EmptyExhibitorAccessesPlaceholder from "@/web/components/moderator/placeholders/EmptyExhibitorAccessesPlaceholder";

export default {
  name: "ModeratorAccesses",
  components: {
    EmptyExhibitorAccessesPlaceholder,
    EmptySpeakerAccessesPlaceholder,
    SpeakerAccessListItem,
    ExhibitorAccessListItem,
  },

  computed: {
    ...mapGetters("moderatorAccesses", ["exhibitorAccesses", "speakerAccesses"]),
  },

  methods: {},
};
</script>

<style scoped></style>
