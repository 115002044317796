<template>
  <div v-if="tickets.length > 0">
    <div v-for="ticket in eventTickets" :key="ticket.id">
      <ticket-card
        class="ticket-item"
        :id="ticket.id"
        :type="ticket.title"
        :title="ticket.subtitle"
        :price="ticket.price"
        :currency="ticket.currency"
        :price-invisible="isMebleEvent || isTutloEvent || isDtsEvent"
      >
        <div class="pl-2 is-flex is-flex-direction-row">
          <b-button class="button is-outlined mr-1" @click="requestTicketDownload()">
            <download-ticket-icon class="svg-icon has-fill-primary"></download-ticket-icon>
          </b-button>
          <b-button
            class="button is-outlined mr-1"
            @click="requestInvoiceDownload()"
            v-if="
              ticket.has_invoice && !isMebleEvent && !isJustJoinEvent && !isForumFinansow && !isGoogleSummit && !isTutloEvent && !isDtsEvent
            "
          >
            <download-invoice-icon class="svg-icon has-fill-primary"></download-invoice-icon>
          </b-button>
        </div>
      </ticket-card>
    </div>
    <b-loading :active="isDownloading" :is-full-page="false" class="is-small"></b-loading>
  </div>

  <div v-else>
    <empty-ticket-list-view></empty-ticket-list-view>
  </div>
</template>

<script>
import TicketCard from "@/web/components/tickets/TicketCard";
import DownloadTicketIcon from "@/assets/icons/download.svg";
import DownloadInvoiceIcon from "@/assets/tickets/icon_invoice_download.svg";
import EmptyTicketListView from "@/web/components/tickets/EmptyTicketListView";
import { mapActions, mapState } from "vuex";

export default {
  name: "EventTicketListView",
  components: { TicketCard, EmptyTicketListView, DownloadTicketIcon, DownloadInvoiceIcon },
  props: ["tickets"],

  data() {
    return {
      isDownloading: false,
    };
  },

  computed: {
    ...mapState(["settings", "eventId"]),

    isMebleEvent() {
      return this.eventId === 1343 || this.eventId === 245;
    },

    isJustJoinEvent() {
      return this.eventId === 1273;
    },

    isForumFinansow() {
      return this.eventId === 1347;
    },

    isGoogleSummit() {
      return this.eventId === 1387;
    },

    isTutloEvent() {
      return this.eventId === 1408;
    },

    isDtsEvent() {
      return this.eventId === 1411;
    },

    eventTickets() {
      return this.tickets.map(ticket => {
        return {
          id: ticket.id,
          title: ticket.event_display_data.title,
          subtitle: ticket.event_ticket.name || this.getCustomTicketName(ticket.event_ticket.id),
          price: ticket.amount !== null ? ticket.amount * 0.01 : ticket.event_ticket.price * 0.01,
          currency: ticket.event_ticket.currency,
          has_invoice: ticket.has_invoice,
        };
      });
    },
  },
  methods: {
    ...mapActions("userTickets", ["downloadTicket", "downloadInvoice"]),

    getCustomTicketName(id) {
      if (this.settings.ticket_types) {
        return this.$t(this.settings.ticket_types[id]);
      }
    },

    requestTicketDownload() {
      this.isDownloading = true;
      this.downloadTicket()
        .catch(err => console.error(err))
        .finally(() => (this.isDownloading = false));
    },

    requestInvoiceDownload() {
      this.downloadInvoice();
    },
  },
};
</script>

<style scoped>
.button {
  width: 45px;
  height: 45px;
  padding: 8px;
  z-index: 10;
}
</style>
