import { Constants } from "@/web/constants";
import axios from "@/web/services/axios";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export const state = () => ({
  statusSystemConsents: null,
  statusMissingSystemConsents: null,
  statusSubmitMissingSystemConsents: null,
  error: null,
  systemConsents: null,
  missingSystemConsents: null,
});

export const types = {
  LOAD_SYSTEM_CONSENTS: "loadSystemConsents",
  LOAD_SYSTEM_CONSENTS_SUCCESS: "loadSystemConsentsSuccess",
  LOAD_SYSTEM_CONSENTS_ERROR: "loadSystemConsentsError",
  LOAD_MISSING_SYSTEM_CONSENTS: "loadMissingSystemConsents",
  LOAD_MISSING_SYSTEM_CONSENTS_SUCCESS: "loadMissingSystemConsentsSuccess",
  LOAD_MISSING_SYSTEM_CONSENTS_ERROR: "loadMissingSystemConsentsError",
  SUBMIT_MISSING_SYSTEM_CONSENTS: "submitMissingSystemConsents",
  SUBMIT_MISSING_SYSTEM_CONSENTS_SUCCESS: "submitMissingSystemConsentsSuccess",
  SUBMIT_MISSING_SYSTEM_CONSENTS_ERROR: "submitMissingSystemConsentsError",
};

export const mutations = {
  [types.LOAD_SYSTEM_CONSENTS](state) {
    state.statusSystemConsents = Constants.STATUS_LOADING;
  },
  [types.LOAD_SYSTEM_CONSENTS_SUCCESS](state, consents) {
    state.statusSystemConsents = Constants.STATUS_LOADED;
    state.systemConsents = consents;
  },
  [types.LOAD_SYSTEM_CONSENTS_ERROR](state, error) {
    state.statusSystemConsents = Constants.STATUS_ERROR;
    state.error = error;
  },
  [types.LOAD_MISSING_SYSTEM_CONSENTS](state) {
    state.statusMissingSystemConsents = Constants.STATUS_LOADING;
  },
  [types.LOAD_MISSING_SYSTEM_CONSENTS_SUCCESS](state, consents) {
    state.statusMissingSystemConsents = Constants.STATUS_LOADED;
    state.missingSystemConsents = consents;
  },
  [types.LOAD_MISSING_SYSTEM_CONSENTS_ERROR](state, error) {
    state.statusMissingSystemConsents = Constants.STATUS_ERROR;
    state.error = error;
  },
  [types.SUBMIT_MISSING_SYSTEM_CONSENTS](state) {
    state.statusSubmitMissingSystemConsents = Constants.STATUS_LOADING;
  },
  [types.SUBMIT_MISSING_SYSTEM_CONSENTS_SUCCESS](state) {
    state.statusSubmitMissingSystemConsents = Constants.STATUS_LOADED;
    state.missingSystemConsents = null;
  },
  [types.SUBMIT_MISSING_SYSTEM_CONSENTS_ERROR](state, error) {
    state.statusSubmitMissingSystemConsents = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async fetchSystemConsents({ commit }) {
    commit(types.LOAD_SYSTEM_CONSENTS);
    try {
      const response = await axios.request({
        baseURL: process.env.VUE_APP_API_AUTH_HOST_URL,
        method: "get",
        url: "consent_schemas/user_app_consents",
      });
      commit(types.LOAD_SYSTEM_CONSENTS_SUCCESS, response.data);
    } catch (error) {
      commit(types.LOAD_SYSTEM_CONSENTS_ERROR, error);
      throw error;
    }
  },

  async submitMissingSystemConsents({ commit }, acceptedConsents) {
    const data = {
      consents: acceptedConsents,
    };
    commit(types.SUBMIT_MISSING_SYSTEM_CONSENTS);
    try {
      const response = await axios.request({
        baseURL: process.env.VUE_APP_API_AUTH_HOST_URL,
        method: "post",
        url: "user_consents",
        data: data,
      });
      commit(types.SUBMIT_MISSING_SYSTEM_CONSENTS_SUCCESS);
    } catch (error) {
      commit(types.SUBMIT_MISSING_SYSTEM_CONSENTS_ERROR, error);
      throw error;
    }
  },

  async fetchMissingSystemConsents({ commit }) {
    commit(types.LOAD_MISSING_SYSTEM_CONSENTS);
    try {
      const response = await axios.request({
        baseURL: process.env.VUE_APP_API_AUTH_HOST_URL,
        method: "get",
        url: "user_consents/user_app_consents",
      });
      commit(types.LOAD_MISSING_SYSTEM_CONSENTS_SUCCESS, response.data);
    } catch (error) {
      commit(types.LOAD_MISSING_SYSTEM_CONSENTS_ERROR, error);
      throw error;
    }
  },
};

const mapConsentsToFormStructure = (consents, locale) => {
  const fields = consents.map(mapConsentToFormField);

  // Initialize translations object with nested structure
  const translations = consents.reduce((acc, consent) => {
    const consentTranslations = mapConsentToTranslations(consent, locale);
    // Merge translations for the same locale
    if (!acc[locale]) {
      acc[locale] = {};
    }
    acc[locale] = { ...acc[locale], ...consentTranslations[locale] };
    return acc;
  }, {});

  return {
    [FormBuilderConstants.FIELDS]: fields,
    [FormBuilderConstants.TRANSLATIONS]: translations,
  };
};

const mapConsentToTranslations = (consent, locale) => {
  const translations = {};
  translations[locale] = {};

  // Add main text translation with _label suffix
  translations[locale][`${consent.id}_label`] = consent.display_config.text;

  // Add translations for placeholders as arrays
  if (consent.display_config.placeholders?.length > 0) {
    translations[locale][`${consent.id}_substrings`] = consent.display_config.placeholders.map(p => p.label);
    translations[locale][`${consent.id}_links`] = consent.display_config.placeholders.map(p => p.value);
  }

  // Add translations for expandable content
  if (consent.display_config.expandable) {
    translations[locale][`${consent.id}_button`] = consent.display_config.expandable.button;
    translations[locale][`${consent.id}_collapsed_text_labels`] = consent.display_config.expandable.text;

    // Add translations for expandable placeholders as arrays
    if (consent.display_config.expandable.placeholders?.length > 0) {
      translations[locale][`${consent.id}_collapsed_substrings`] = consent.display_config.expandable.placeholders.map(p => p.label);
      translations[locale][`${consent.id}_collapsed_links`] = consent.display_config.expandable.placeholders.map(p => p.value);
    }
  }

  return translations;
};

const mapConsentToFormField = consent => {
  const baseField = {
    id: consent.id,
    required: consent.required,
    order: consent.id,
    collapsed: !!consent.display_config.expandable,
  };

  return {
    ...baseField,
    type: consent.display_type === "checkbox" ? FormBuilderConstants.FIELD_TYPES.CHECKBOX : FormBuilderConstants.FIELD_TYPES.INFORMATION,
  };
};

export const getters = {
  systemConsentsForm: (state, getters, rootState, rootGetters) => {
    if (state.systemConsents) {
      const currentLocale = rootGetters["locales/currentLocale"]?.localeCode || "en";
      return mapConsentsToFormStructure(state.systemConsents, currentLocale);
    } else {
      return null;
    }
  },

  missingSystemConsentsForm: (state, getters, rootState, rootGetters) => {
    if (state.missingSystemConsents) {
      const currentLocale = rootGetters["locales/currentLocale"]?.localeCode || "en";
      return mapConsentsToFormStructure(state.missingSystemConsents, currentLocale);
    } else {
      return null;
    }
  },

  isLoadingSystemConsents: state => {
    return state.statusSystemConsents === Constants.STATUS_LOADING;
  },

  isLoadedSystemConsents: state => {
    return state.statusSystemConsents === Constants.STATUS_LOADED;
  },

  isLoadingMissingSystemConsents: state => {
    return state.statusMissingSystemConsents === Constants.STATUS_LOADING;
  },

  isLoadedMissingSystemConsents: state => {
    return state.statusMissingSystemConsents === Constants.STATUS_LOADED;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
