<template>
  <div class="mb-6">
    <b-loading v-if="!exhibitor && isLoadingExhibitor" :active="true"></b-loading>
    <!-- Exhibitor not found -->
    <section v-else-if="!exhibitor" class="hero is-danger">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t("exhibitors.not_found_title") }}
          </h1>
          <h2 class="subtitle">
            {{ $t("exhibitors.not_found_subtitle") }}
          </h2>
        </div>
      </div>
    </section>

    <template v-else>
      <!-- Exhibitor header -->
      <section class="container mb-4">
        <div class="mb-5 mt-1">
          <a class="back-button" @click.prevent="navigateBack">
            <div class="columns is-inline-flex is-mobile is-gapless is-vcentered">
              <back-icon class="column is-narrow back-button-icon-color"></back-icon>
              <div class="column is-narrow mx-2 my-1">
                {{ $t("common.back") }}
              </div>
            </div>
          </a>
          <exhibitor-banner :exhibitor="exhibitor"></exhibitor-banner>
        </div>
        <div class="content description">
          <p v-html="description"></p>
        </div>
        <!-- Additional content via iframe -->
        <div v-if="iframe" v-html="iframe"></div>
        <!-- Video presentation -->
        <div v-if="presentationVideoUrl">
          <embed-video :source="presentationVideoUrl"></embed-video>
        </div>
      </section>

      <!-- Contact details and social media -->
      <section v-if="contactDetailsVisible" class="container mb-6">
        <div class="columns">
          <div class="column">
            <h2 class="title is-2">
              {{ $t("common.contact_details") }}
            </h2>

            <!-- TODO: Move to another "dumb" component -->
            <contact-detail v-if="www" class="contact-detail">
              <template #icon>
                <website-icon class="social-icon"></website-icon>
              </template>
              <p class="title">{{ $t("common.contact_website") }}</p>
              <a :href="www" rel="noopener noreferrer">{{ www }}</a>
            </contact-detail>

            <contact-detail v-if="email" class="contact-detail">
              <template #icon>
                <email-icon class="social-icon"></email-icon>
              </template>
              <p class="title">{{ $t("common.contact_email") }}</p>
              <a :href="`mailto:${email}`">{{ email }}</a>
            </contact-detail>

            <contact-detail v-if="phone" class="contact-detail">
              <template #icon>
                <phone-icon class="social-icon"></phone-icon>
              </template>
              <p class="title">{{ $t("common.concact_phone") }}</p>
              <a :href="`tel:${phone}`">{{ phone }}</a>
            </contact-detail>
          </div>

          <div v-if="socials.length > 0" class="column">
            <h2 class="title is-2">{{ $t("common.social_media") }}</h2>
            <social-links :links="socials"></social-links>
          </div>
        </div>
      </section>

      <!-- Contact person -->
      <section v-if="contacts.length > 0" class="container">
        <h2 class="title is-2">{{ $t("common.contact_person") }}</h2>
        <paginated-content :items="contacts" :perPage="contactPersonsPageSize">
          <template v-slot="{ displayItems }">
            <div class="columns is-flex-wrap">
              <div v-for="(contact, index) in displayItems" :key="index" class="column is-half">
                <contact-card v-bind="contact" :inbox-enabled="inboxEnabled"></contact-card>
              </div>
            </div>
          </template>
        </paginated-content>
      </section>

      <!-- Attachments -->
      <section v-if="attachments.length > 0" class="container">
        <h2 class="title is-2">{{ $t("common.attachments_title") }}</h2>
        <attachment-list :attachments="attachments" column-class="is-half"></attachment-list>
      </section>
    </template>
  </div>
</template>

<script>
import { buildSocialLinks, buildContactModels } from "@/shared/utils";
import { mapGetters, mapState, mapActions } from "vuex";
import WebsiteIcon from "@/assets/contact/website-icon.svg";
import EmailIcon from "@/assets/contact/email-icon.svg";
import PhoneIcon from "@/assets/contact/phone-icon.svg";
import ExhibitorBanner from "@/shared/components/exhibitors/ExhibitorBanner";
import SocialLinks from "@/web/components/shared/SocialLinks";
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import ContactDetail from "@/web/components/shared/ContactDetail";
import ContactCard from "@/web/components/shared/ContactCard";
import AttachmentList from "@/web/components/shared/AttachmentList";
import EmbedVideo from "@/web/components/shared/EmbedVideo";
import BackIcon from "@/assets/icon_back_arrow.svg";
import Constants from "@/web/constants";
import NavigateBackMixin from "@/web/mixins/NavigateBackMixin";

export default {
  name: "Exhibitor",

  components: {
    WebsiteIcon,
    EmailIcon,
    PhoneIcon,
    ExhibitorBanner,
    SocialLinks,
    PaginatedContent,
    ContactDetail,
    ContactCard,
    AttachmentList,
    EmbedVideo,
    BackIcon,
  },

  mixins: [NavigateBackMixin],

  mounted() {
    if (!this.exhibitor) {
      this.fetchExhibitor();
    }
  },

  methods: {
    ...mapActions("exhibitors", ["fetchById"]),

    navigateBack() {
      this.handleBack({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    },

    fetchExhibitor() {
      const { componentId, exhibitorId } = this.$route.params;
      const eventId = this.eventId;
      this.fetchById({ eventId, componentId, exhibitorId });
    },
  },

  computed: {
    ...mapState(["settings", "eventId"]),
    ...mapState("locales", ["currentLocale"]),
    ...mapGetters(["moduleByComponentId", "inboxEnabled"]),
    ...mapGetters("exhibitors", ["getById", "isLoadingExhibitor"]),

    contactPersonsPageSize() {
      return 4;
    },

    exhibitor() {
      const { exhibitorId } = this.$route.params;
      return this.getById(Number(exhibitorId));
    },

    description() {
      return this.exhibitor.description && this.$options.filters.linkify(this.exhibitor.description);
    },

    email() {
      return this.exhibitor.email;
    },

    phone() {
      return `${this.exhibitor.phone_country_code ? `+${this.exhibitor.phone_country_code}` : ""}${
        this.exhibitor.phone ? this.exhibitor.phone : ""
      }`;
    },

    www() {
      return this.exhibitor.www;
    },

    socials() {
      return buildSocialLinks(this.exhibitor);
    },

    contactDetailsVisible() {
      return this.socials.some(social => social.target) || this.exhibitor.email || this.exhibitor.www || this.exhibitor.phone;
    },

    presentationVideoUrl() {
      return this.exhibitor.promotion_film_url;
    },

    contacts() {
      const ca = buildContactModels(this.exhibitor.exhibitor_contact_users);
      const cb = buildContactModels(this.exhibitor.exhibitor_contact_people);
      const cbSorted = cb.sort((a, b) => a.order - b.order);

      return [].concat(ca, cbSorted);
    },

    module() {
      const componentId = this.$route.params.componentId && Number.parseInt(this.$route.params.componentId);
      return this.moduleByComponentId(componentId);
    },

    attachments() {
      const attachments = this.exhibitor.exhibitor_attachments;
      return attachments.map(attachment => ({
        type: "file",
        name: attachment.file_name,
        url: attachment.file_url,
      }));
    },

    iframe() {
      const iframes = this.module && this.module.iframe;
      const exhibitorId = this.$route.params.exhibitorId;
      return (iframes && iframes[exhibitorId]) || null;
    },
  },

  watch: {
    //hack for reloading exhibitor on locale changes when module visibility is set to false
    currentLocale: {
      immediate: false,
      handler: function (newValue) {
        if (this.module && this.module.visible === false) {
          this.fetchExhibitor();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-detail {
  .title,
  .subtitle {
    margin: 0;
    line-height: 27px;
    font-size: 20px;
  }

  .title {
    font-weight: bold;
    color: var(--text-color-primary);
  }
}

.title.is-2 {
  font-size: 32px;
}

.description {
  white-space: pre-wrap;
  color: var(--text-color-primary);
}

.social-icon {
  fill: #ffffff;
  display: block;
  width: 26px !important;
  height: 26px !important;
}
.back-button {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}
.back-button-icon-color {
  fill: var(--link-color);
  height: 18px;
  width: 12px;
}
</style>
