<template>
  <div class="quiz-final-results" :class="smallClass">
    <div class="quiz-final-title mb-1" :class="smallClass">
      {{ $t("quizzes.thank_you") }}
    </div>
    <img src="@/assets/quizzes/quiz_finish_badge.png" :class="smallClass" class="quiz-finish-badge" />
    <div v-if="isSurvey" class="quiz-your-score mb-1" :class="smallClass">
      {{ $t("quizzes.survey_feedback_title") }}
    </div>
    <div v-else>
      <div v-if="visibleResults">
        <div class="quiz-your-score" :class="smallClass">
          {{ $t("quizzes.quiz_your_score") }}
        </div>
        <div class="quiz-score mb-1 has-text-primary" :class="smallClass">
          {{ score }}
        </div>
        <div class="quiz-correct-answers mb-1" :class="smallClass">
          <i18n path="quizzes.quiz_correct_answers" tag="span" class="is-block">
            <span class="has-text-primary">
              {{ $t("quizzes.quiz_correct_answers_substring", [correctAnswers, questionsCount]) }}
            </span>
          </i18n>
        </div>
      </div>
      <div v-else class="quiz-your-score">
        {{ $t("quizzes.quiz_feedback_title") }}
      </div>
    </div>
    <div class="button is-rounded is-primary quiz-finish-button mt-2" :class="finishButtonClasses" @click="returnToList">
      {{ $t("quizzes.quiz_final_return") }}
    </div>
  </div>
</template>

<script>
import QuizFinishBadge from "@/assets/quizzes/quiz_finish_badge.png";
import Constants from "@/web/constants";

export default {
  name: "QuizFinalResults",

  components: {},

  props: ["quizResults", "visibleResults", "quizType", "isSmall"],

  computed: {
    Constants: () => Constants,

    isSurvey() {
      return this.quizType === Constants.QUIZ_MODE.SURVEY;
    },

    score() {
      return this.quizResults.score;
    },

    correctAnswers() {
      return this.quizResults.score;
    },

    questionsCount() {
      return this.quizResults.totalCorrectAnswers;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },

    finishButtonClasses() {
      if (this.isSmall) {
        return "is-small has-fullwidth";
      } else {
        return "";
      }
    },
  },

  methods: {
    returnToList() {
      if (this.isSmall) {
        this.$emit("return");
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
