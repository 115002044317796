<template>
  <form action="." method="POST" @submit.prevent="() => {}" @input="input">
    <div class="field mt-2" v-for="filterGroup in businessMatchingFilterGroups" :key="`filter-group-${filterGroup.id}-order-${orderKey}`">
      <label class="label has-text-primary">{{ filterGroup.question }}</label>
      <dropdown-select
        class="mt-1"
        style="max-width: 520px"
        :items="filterGroup.business_matching_filter_tags.sort((a, b) => a.order - b.order)"
        :multiple="filterGroup.multi_choice"
        :value="$data[filterGroup.id]"
        @input="updateValue(filterGroup.id, $event)"
        :is-form-input="true"
      >
        <!-- Path tag items -->
        <template #dropdown="{ item }">
          <div class="mr-1">
            <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
            <span>{{ item.value }}</span>
          </div>
        </template>

        <template #trigger="{ item }">
          <div class="mr-1">
            <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
            <span>{{ item.value }}</span>
          </div>
        </template>

        <!-- Placeholder text -->
        <template #placeholder>
          <span>{{ $t("common.select_from_list") }}</span>
        </template>
      </dropdown-select>
      <template v-if="showErrors && getError(filterGroup.id)">
        <p class="help is-danger">{{ $t("common.field_required_error") }}</p>
      </template>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import DropdownSelect from "@/shared/components/DropdownSelect";
import PointIcon from "@/assets/icons/point.svg";

export default {
  name: "BusinessMatchingFiltersForm",
  components: {
    DropdownSelect,
    PointIcon,
  },
  props: {
    businessMatchingFilterGroups: {
      type: Array,
      required: true,
    },
    orderKey: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
    },
    error: {
      type: Object,
    },
  },

  data() {
    let data = {
      showErrors: false,
    };
    this.businessMatchingFilterGroups.forEach(filterGroup => {
      data[filterGroup.id] = [];
    });
    return data;
  },

  computed: {
    ...mapState(["eventId"]),
  },

  methods: {
    updateValue(filterGroupId, value) {
      this.$data[filterGroupId] = value;
      this.input();
    },
    input() {
      this.$emit("input", this.getFormData());
    },

    getFormData() {
      return this.businessMatchingFilterGroups.reduce((acc, val) => {
        let selected = this.$data[val.id];
        if (Array.isArray(selected)) {
          acc[val.id] = this.$data[val.id].map(selectedIndex => val.business_matching_filter_tags[selectedIndex].id);
        } else {
          acc[val.id] = [val.business_matching_filter_tags[this.$data[val.id]].id];
        }
        return acc;
      }, {});
    },

    touch() {
      this.showErrors = true;
    },

    isValid() {
      return this.businessMatchingFilterGroups.reduce((isValid, group) => {
        return isValid && !this.getError(group.id);
      }, true);
    },

    getError(filterGroupId) {
      let filterGroup = this.businessMatchingFilterGroups.find(group => group.id === filterGroupId);
      if (filterGroup.required) {
        return this.$data[filterGroup.id].length === 0;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
