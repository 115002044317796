<template>
  <div class="notification">
    <div class="columns is-mobile is-gapless">
      <div class="column is-narrow">
        <div class="notification-icon-container">
          <div v-if="eventLogo && notificationType === Constants.NOTIFICATION_TYPE.EVENT" class="box is-inline-flex has-logo is-clipped">
            <figure class="image is-centered is-36x36">
              <img class="logo-image" :src="eventLogo" />
            </figure>
          </div>
          <div v-else class="notification-icon-background">
            <component :is="notificationIcon" class="has-fill-primary"></component>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="notification-title">
          {{ title }}
        </div>
        <div class="notification-subtitle">{{ message }}</div>
        <div class="notification-elapsed-time">
          {{ elapsedTime }}
        </div>
      </div>
      <div class="column is-narrow">
        <div v-if="isUnread" class="unread-circle"></div>
      </div>
    </div>
    <div v-if="businessMatchingMeeting">
      <b-m-meeting-buttons :meeting="businessMatchingMeeting" :is-notification="true"></b-m-meeting-buttons>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { composeElapsedTime } from "@/shared/utils";
import Constants from "@/web/constants";
import NotificationInfoIcon from "@/assets/notification/notification_info.svg";
import NotificationFriendIcon from "@/assets/notification/notification_friend.svg";
import NotificationMeetingIcon from "@/assets/icons/business_matching.svg";
import NotificationVideoCallIcon from "@/assets/icons/video_cam_small.svg";
import { getBusinessMatchingMeetingId } from "@/shared/utils/deeplink-utils";
import BMMeetingButtons from "@/web/components/businessmatching/meeting/BMMeetingButtons";

export default {
  name: "Notification",
  components: { BMMeetingButtons },
  props: ["notification"],

  computed: {
    ...mapGetters("businessMatchingMeetings", ["getMeetingById"]),
    ...mapGetters(["currentTime"]),

    Constants: () => Constants,

    title() {
      return this.notification.title;
    },

    message() {
      const notificationSubtype = this.notification.notify_subtype;
      if (notificationSubtype === Constants.NOTIFICATION_SUBTYPE.FRIENDS_NEW_INVITATION) {
        return this.$t("friends.invitation_notification_text", [this.notification.message]);
      } else {
        return this.notification.message;
      }
    },

    elapsedTime() {
      if (this.currentTime) {
        return composeElapsedTime(this.notification.created_at, this.$i18n);
      } else {
        return "";
      }
    },

    isUnread() {
      return !this.notification.read_at;
    },

    notificationType() {
      return this.notification.notify_type;
    },

    eventLogo() {
      return this.notification.event && this.notification.event.logo && this.notification.event.logo.file_thumbnail_200_url;
    },

    notificationIcon() {
      const notificationType = this.notification.notify_type;
      const notificationSubtype = this.notification.notify_subtype;

      if (notificationType === Constants.NOTIFICATION_TYPE.FRIENDS) {
        return NotificationFriendIcon;
      } else if (notificationType === Constants.NOTIFICATION_TYPE.EVENT) {
        return NotificationInfoIcon;
      } else if (notificationType === Constants.NOTIFICATION_TYPE.BUSINESS_MATCHING) {
        return NotificationMeetingIcon;
      } else if (notificationSubtype === Constants.NOTIFICATION_SUBTYPE.USER_JOIN_VIDEO_CALL) {
        return NotificationVideoCallIcon;
      } else {
        return NotificationInfoIcon;
      }
    },

    businessMatchingMeeting() {
      const notificationType = this.notification.notify_type;
      if (notificationType === Constants.NOTIFICATION_TYPE.BUSINESS_MATCHING) {
        const deepLink = this.notification.dl;
        const meetingId = getBusinessMatchingMeetingId(deepLink);
        if (meetingId) {
          return this.getMeetingById(meetingId);
        }
      }
      return null;
    },
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.unread-circle {
  background: var(--primary-color);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 18px;
  margin-top: 18px;
}

.notification {
  margin: 0;
  width: 444px;
}

.notification-subtitle {
  margin-top: 4px;
  max-width: 370px;
}

.notification-elapsed-time {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: var(--primary-color);
  margin-top: 4px;
}
</style>
