<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div v-if="isAuthorized">
        <search-input class="mb-4" :placeholder="$t('hints.search_hint_attendees')" v-model="searchQuery"></search-input>
        <attendees-list @load-more="loadAttendeesPage" :attendees="attendees" :is-loading="isLoading" max-height="42vh"></attendees-list>
      </div>
      <div v-else class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="subtitle mb-3">
          {{ $t("attendees.login_required") }}
        </div>
        <button type="button" class="button is-primary" @click="openLoginModal">{{ $t("auth.log_in") }}</button>
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import SearchInput from "@/shared/components/SearchInput";
import AttendeesList from "@/web/components/attendees/AttendeesList";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "AttendeesComponent",

  components: {
    Separator,
    SearchInput,
    AttendeesList,
  },
  mixins: [LpConfigMixin],

  props: ["moduleData"],

  data() {
    return {
      searchQuery: "",
    };
  },

  watch: {
    agendaSessionId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.resetMeta();
        this.setAgendaSessionTitle(null);
        if (newVal) {
          this.setAgendaSessionAttendeesSource(newVal);
          this.loadAgendaSession({ agendaSessionId: newVal, agendaComponentId: this.componentId });
        } else {
          this.setEventAttendeesSource();
        }
        this.loadAttendeesPage();
      },
    },

    searchQuery: function (newVal) {
      if (newVal.length) {
        this.searchAttendees(newVal);
      } else {
        this.clearSearchResult();
      }
    },

    isAuthorized(newVal, oldVal) {
      if (newVal) {
        this.resetMeta();
        this.loadAttendeesPage();
      }
    },
  },

  computed: {
    ...mapGetters("components", ["getComponentById"]),
    ...mapGetters("attendees", ["getAttendees", "isLoading"]),
    ...mapGetters("currentUser", ["uuid"]),
    ...mapState("attendees", ["agendaSessionTitle"]),
    ...mapGetters("auth", ["isAuthorized"]),

    moduleFields: () => LpConfigConstants.ATTENDEES_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    componentId() {
      return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
    },

    component() {
      return this.getComponentById(this.componentId);
    },

    agendaSessionId() {
      return this.getFieldValueOrDefault(this.moduleData, this.moduleFields.AGENDA_SESSION_ID, null);
    },

    title() {
      if (this.agendaSessionId) {
        return this.agendaSessionTitle || this.$t("attendees.title");
      } else {
        return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, this.component.label);
      }
    },

    attendees() {
      return this.getAttendees(this.searchQuery);
    },
  },

  methods: {
    ...mapActions("attendees", ["loadAttendeesPage", "searchAttendees", "loadAgendaSession"]),
    ...mapMutations("attendees", [
      "clearSearchResult",
      "setAgendaSessionAttendeesSource",
      "setEventAttendeesSource",
      "resetMeta",
      "setAgendaSessionTitle",
    ]),

    openLoginModal() {
      this.$root.openLoginModal();
    },
  },
};
</script>
