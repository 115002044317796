export function setGTagScripts(eventId) {
  if (!eventId) {
    return;
  }

  // green energy tricity
  if (eventId === 1488) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `
      <!-- Google Tag Manager -->
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event:'gtm.js'
        });
        var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N9HSBMKL');
    `;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N9HSBMKL"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1441) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K3RC3SR2');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K3RC3SR2"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1538) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PCZV9DGB');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PCZV9DGB"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1468) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TLB8GD62');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TLB8GD62"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if (eventId === 1643) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'default_consent',
      'consent': {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'granted'
      }
    });

    const cookiebotScript = document.createElement('script');
    cookiebotScript.id = 'Cookiebot';
    cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
    cookiebotScript.setAttribute('data-cbid', 'f08bf60e-af13-45b4-8226-c47a405c425d');
    cookiebotScript.setAttribute('data-blockingmode', 'auto');
    cookiebotScript.async = true;
    document.head.appendChild(cookiebotScript);

    // Konfiguracja Consent Mode
    const consentScript = document.createElement('script');
    consentScript.innerHTML = `
    // Nasłuchiwanie na zmiany zgód w Cookiebot
    window.addEventListener('CookiebotOnAccept', function () {
      const consent = Cookiebot.consent;

      window.dataLayer.push({
        'event': 'update_consent',
        'consent': {
          'ad_storage': consent.marketing ? 'granted' : 'denied',
          'analytics_storage': consent.statistics ? 'granted' : 'denied',
          'functionality_storage': consent.preferences ? 'granted' : 'denied',
          'personalization_storage': consent.preferences ? 'granted' : 'denied'
        }
      });
    });

    // Nasłuchiwanie na odwołanie zgód
    window.addEventListener('CookiebotOnDecline', function () {
      window.dataLayer.push({
        'event': 'update_consent',
        'consent': {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'functionality_storage': 'denied',
          'personalization_storage': 'denied'
        }
      });
    });
  `;
    document.head.appendChild(consentScript);

    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WLCFH3BM');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLCFH3BM"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if(eventId === 1632) {
    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KRCLMN6P');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KRCLMN6P"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  } else if(eventId === 104) {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     'event': 'default_consent',
  //     'consent': {
  //       'ad_storage': 'denied',
  //       'analytics_storage': 'denied',
  //       'functionality_storage': 'denied',
  //       'personalization_storage': 'denied',
  //       'security_storage': 'granted'
  //     }
  //   });
  //
  //   const cookiebotScript = document.createElement('script');
  //   cookiebotScript.id = 'Cookiebot';
  //   cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
  //   cookiebotScript.setAttribute('data-cbid', 'f08bf60e-af13-45b4-8226-c47a405c425d');
  //   cookiebotScript.setAttribute('data-blockingmode', 'auto');
  //   cookiebotScript.async = true;
  //   document.head.appendChild(cookiebotScript);
  //
  //   // Konfiguracja Consent Mode
  //   const consentScript = document.createElement('script');
  //   consentScript.innerHTML = `
  //   // Nasłuchiwanie na zmiany zgód w Cookiebot
  //   window.addEventListener('CookiebotOnAccept', function () {
  //     const consent = Cookiebot.consent;
  //
  //     window.dataLayer.push({
  //       'event': 'update_consent',
  //       'consent': {
  //         'ad_storage': consent.marketing ? 'granted' : 'denied',
  //         'analytics_storage': consent.statistics ? 'granted' : 'denied',
  //         'functionality_storage': consent.preferences ? 'granted' : 'denied',
  //         'personalization_storage': consent.preferences ? 'granted' : 'denied'
  //       }
  //     });
  //   });
  //
  //   // Nasłuchiwanie na odwołanie zgód
  //   window.addEventListener('CookiebotOnDecline', function () {
  //     window.dataLayer.push({
  //       'event': 'update_consent',
  //       'consent': {
  //         'ad_storage': 'denied',
  //         'analytics_storage': 'denied',
  //         'functionality_storage': 'denied',
  //         'personalization_storage': 'denied'
  //       }
  //     });
  //   });
  // `;
  //   document.head.appendChild(consentScript);

    const headScript = document.createElement('script');
    headScript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PHTPFDPS');
    <!-- End Google Tag Manager -->`;
    document.head.appendChild(headScript);

    // Google Tag Manager - body noscript
    const bodyNoScript = document.createElement('noscript');
    bodyNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PHTPFDPS"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(bodyNoScript);
  }
}
