<template>
  <b-dropdown
    position="is-bottom-left"
    ref="localesDropdown"
    aria-role="menu"
    :disabled="isLocaleLoading"
    trap-focus
    can-close
    close-on-click
  >
    <b-button type="is-text" class="is-navbar-item is-borderless pl-1 pr-1 is-relative" slot="trigger">
      <small-loading-indicator class="loading-indicator" v-if="isLocaleLoading"></small-loading-indicator>
      <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
        <div class="has-flag-button mr-1">
          <country-flag :code="currentLocaleCountryCode"></country-flag>
        </div>
        <arrow-icon class="navbar-icon-color" :style="fill"></arrow-icon>
      </div>
    </b-button>

    <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
      <div ref="localesList" class="">
        <template v-for="locale in supportedLocales">
          <div
            class="locale-container"
            :class="{ 'has-country-name': showTitles }"
            @click="onLocaleClick(locale.localeCode)"
            :key="locale.localeCode"
          >
            <div class="has-flag">
              <country-flag :code="locale.countryCode"></country-flag>
            </div>
            <div class="country-name notranslate" v-if="showTitles">
              {{ languages[locale.localeCode].native }}
            </div>
          </div>
        </template>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CountryFlag from "@/web/components/shared/CountryFlag";
import ArrowIcon from "@/assets/navbar/arrow-icon.svg";
import SmallLoadingIndicator from "@/shared/components/common/loading/SmallLoadingIndicator";
import { languages } from "countries-list";

export default {
  name: "NavLocales",

  components: { SmallLoadingIndicator, CountryFlag, ArrowIcon },

  props: {
    fill: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("locales", ["supportedLocales", "currentLocale", "isLocaleLoading"]),
    ...mapState(["eventId"]),

    showTitles() {
      return !(this.eventId === 1399 || this.eventId === 298 || this.eventId === 1387 || this.eventId === 299);
    },

    currentLocaleCountryCode() {
      return this.currentLocale && this.currentLocale.countryCode;
    },

    languages: () => languages,
  },

  methods: {
    ...mapActions("locales", ["setCurrentLocale"]),

    onLocaleClick(locale) {
      this.$setLocale(locale);
      this.setCurrentLocale(locale);
      this.$refs.localesDropdown.toggle();
      document.documentElement.lang = locale;
    },
  },
};
</script>

<style scoped lang="scss">
.locale-container {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color-primary);

  &.has-country-name {
    min-width: 5rem;
  }

  &:hover {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
}

.country-name {
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  font-weight: bolder;
}

.has-flag-button img {
  display: block;
  width: 22px;
  height: auto;
}

.has-flag img {
  display: block;
  min-width: 20px;
  width: 20px;
  height: auto;
  //margin-right: 12px;
}

.button:active,
.button.is-active {
  .navbar-icon-color {
    fill: #ffffff;
  }
}

.navbar-icon-color {
  fill: #848484;
}

.loading-indicator {
  position: absolute;
  height: 50%;
  left: 25%;
}
</style>
