<template>
  <article class="module">
    <div :class="{ container: isPismEvent }">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <div class="is-relative mb-1">
        <h2 class="title primary-text-color is-2 has-text-centered">
          {{ title }}
        </h2>
        <div class="has-fullwidth is-flex is-flex-direction-row is-justify-content-flex-end px-3">
          <div
            class="button full-screen-disabled-button full-screen-disabled has-text-centered"
            @click="toggleFullScreen"
            v-if="fullscreenEnabled"
          >
            <div class="mr-1">Full Screen</div>
            <full-screen-enter class="svg-icon" style="width: 24px"></full-screen-enter>
          </div>
        </div>
      </div>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
      <b-tabs v-if="tabsEnabled" v-model="tabIndex" position="is-centered" :multiline="true" expanded>
        <template v-for="(tabTitle, index) in tabTitles">
          <b-tab-item :label="getCustomTranslation({ tabTitle }, 'tabTitle')" :key="`tabbed-iframe-${index}-${containerId}`"></b-tab-item>
        </template>
      </b-tabs>
      <fullscreen ref="fullscreen" @change="fullscreenChange">
        <div class="is-relative" :class="fullScreenContainerClasses" @touchstart="toggleIsSelected" @mouseover="toggleIsSelected">
          <div class="columns is-gapless">
            <div class="column" :class="iframeClass">
              <live-webinar-i-frame :html="html" :key="forceUpdateKey"></live-webinar-i-frame>
            </div>
          </div>
          <div class="rating-modal-container" v-if="fullScreen && rating">
            <div class="rating-modal">
              <i-love-marketing-rating-modal :component-id="rating.componentId" :quiz-id="rating.quizId"></i-love-marketing-rating-modal>
            </div>
          </div>

          <div class="button full-screen-button full-screen-enabled" :class="buttonsClasses" @click="toggleFullScreen" v-if="fullScreen">
            <div class="mr-1">Exit</div>
            <full-screen-exit class="svg-icon" style="width: 24px"></full-screen-exit>
          </div>
        </div>
      </fullscreen>
    </div>
    <img class="pism-planet-overlay" :src="pismOverlayImg" v-if="isPismEvent" />
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import { mapGetters, mapState } from "vuex";
import LiveWebinarIFrame from "@/web/components/livewebinar/LiveWebinarIFrame";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import FullScreenEnter from "@/assets/iframe/fullscreen_enter.svg";
import FullScreenExit from "@/assets/iframe/fullscreen_exit.svg";
import ILoveMarketingRatingModal from "@/web/components/custom/ILoveMarketingRatingModal";
import { getComponentId, getQuizId, isQuizzesComponent } from "@/shared/utils/deeplink-utils";
import { debounce } from "@/shared/utils";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import PismPlanetOverlay from "@/assets/custom/pism/pism_planet_overlay.png";

export default {
  name: "LiveWebinarTabbedComponent",
  mixins: [LpConfigMixin],
  components: {
    ListLoadingIndicator,
    LiveWebinarIFrame,
    Separator,
    FullScreenEnter,
    FullScreenExit,
    ILoveMarketingRatingModal,
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      scriptRef: null,
      forceUpdateKey: 0,
      iframeAuthorizationState: null,
      isLoading: false,
      fullScreen: false,
      rating: null,
      tabIndex: 0,
      isSelected: true,
    };
  },

  destroyed() {
    if (this.scriptRef) {
      this.scriptRef.remove();
      this.removeLiveWebinarsScripts();
    }
  },

  methods: {
    createLiveWebinarScript() {
      if (this.javascript) {
        this.scriptRef = document.createElement("script");
        this.scriptRef.type = "text/javascript";
        this.scriptRef.innerHTML = this.javascript;
        this.scriptRef = document.body.appendChild(this.scriptRef);
      }
    },

    removeLiveWebinarsScripts() {
      //Hack for livewebinars multiple injected scripts in head
      //They are inserted from livewebinar script not by this component
      const lwSrc = "https://embed.archiebot.com/";
      const scripts = document.querySelectorAll("body > script");
      const scriptsArray = [...scripts];
      scriptsArray.forEach(script => {
        if (script.src.startsWith(lwSrc)) {
          script.remove();
        }
      });
    },

    toggleFullScreen() {
      this.$refs.fullscreen.toggle();
      this.fullScreen = !this.fullScreen;
    },

    fullscreenChange(fullscreen) {
      this.fullScreen = fullscreen;
    },

    toggleIsSelected() {
      this.isSelected = true;
    },

    unselect() {
      this.isSelected = false;
    },

    unselectButtonsVisibilityWithDelay() {
      clearTimeout(this.hideButtonsTimeoutId);
      this.hideButtonsTimeoutId = setTimeout(() => {
        this.isSelected = false;
      }, 3000);
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("currentUser", ["fullName", "email", "picture"]),
    ...mapGetters(["agendaRatingPopupConfig"]),
    ...mapState("notifications", ["standardNotification"]),
    ...mapState(["eventId"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,
    lwModuleFields: () => LpConfigConstants.LIVEWEBINAR_MODULE_FIELDS,
    tabFields: () => LpConfigConstants.LIVEWEBINAR_TAB_FIELDS,

    isPismEvent() {
      return this.eventId === 1312;
    },

    pismOverlayImg() {
      return PismPlanetOverlay;
    },

    separatorVisible() {
      return !this.moduleData[this.commonModuleFields.SEPARATOR_DISABLED];
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    javascript() {
      return this.lwOptionsString + this.lwScriptFunctionString;
    },

    html() {
      return `<div id='${this.containerId}'></div>`;
    },

    tabsEnabled() {
      return !!this.moduleData[this.lwModuleFields.TABS_ENABLED];
    },

    tabsData() {
      return this.moduleData[this.lwModuleFields.TABS_DATA];
    },

    currentTabData() {
      return this.tabsData[this.tabIndex];
    },

    licenseKey() {
      return this.currentTabData[this.tabFields.LW_LICENSE_KEY];
    },

    tabTitles() {
      return this.tabsData.map(tabData => tabData[this.tabFields.TAB_TITLE_KEY]);
    },

    timeout() {
      const locale = this.$i18n.locale;
      if (this.forceUpdateKey <= 1) {
        return findLocalizedCode(this.moduleData, this.lwModuleFields.TIMEOUT, locale);
      } else {
        return null;
      }
    },

    containerId() {
      const locale = this.$i18n.locale;
      return findLocalizedCode(this.moduleData, this.lwModuleFields.LW_CONTAINER_ID, locale);
    },

    lwOptionsString() {
      return `var _options${this.moduleData[this.commonModuleFields.ORDER]} = {
        _license_key: "${this.licenseKey}",
        _role_token: "",
        _registration_token: "",
        _widget_containerID: "${this.containerId}",
        _widget_width: "100%",
        _widget_height: "900px",
        ${this.fullName ? `_nickname: "${this.fullName}",` : ""}
        ${this.email ? `_email: "${this.email}",` : ""}
      };`;
    },

    lwScriptFunctionString() {
      return `
            var fun = (function () {
        !(function (i) {
          (i.Widget = function (c) {
            "function" == typeof c && i.Widget.__cbs.push(c),
            i.Widget.initialized &&
            (i.Widget.__cbs.forEach(function (i) {
              i();
            }),
              (i.Widget.__cbs = []));
          }),
            (i.Widget.__cbs = []);
        })(window);
        var ab = document.createElement("script");
        ab.type = "text/javascript";
        ab.async = true;
        ab.src =
          "https://embed.archiebot.com/em?t=" +
          _options${this.moduleData.order}["_license_key"] +
          "&" +
          Object.keys(_options${this.moduleData.order})
            .reduce(function (a, k) {
              a.push(k + "=" + encodeURIComponent(_options${this.moduleData.order}[k]));
              return a;
            }, [])
            .join("&");
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ab, s.nextSibling);
      });
      ${this.timeout ? "setTimeout(fun, " + this.timeout + ");" : "fun()"}
      `;
    },

    fullscreenEnabled() {
      return !!this.moduleData[this.lwModuleFields.FULL_SCREEN_ENABLED];
    },

    iframeClass() {
      if (this.fullScreen) {
        return "is-three-fifth";
      } else {
        return "";
      }
    },

    fullScreenContainerClasses() {
      return {
        "full-screen-container": this.fullScreen,
      };
    },

    buttonsClasses() {
      return {
        "is-visible": this.isSelected,
      };
    },
  },

  watch: {
    isSelected: {
      immediate: false,
      handler: function (newVal) {
        if (newVal) {
          this.unselectButtonsVisibilityWithDelay();
        }
      },
    },

    fullScreen: {
      immediate: false,
      handler: function (newVal) {
        if (newVal) {
          this.unselectButtonsVisibilityWithDelay();
        }
      },
    },

    tabIndex: {
      immediate: false,
      handler: debounce(function (newValue) {
        this.removeLiveWebinarsScripts();
        this.forceUpdateKey += 1;
        this.createLiveWebinarScript();
      }, 1000),
    },

    email: {
      immediate: true,
      handler: function (newValue) {
        if ((this.isAuthorized && newValue) || (!this.isAuthorized && !newValue)) {
          this.removeLiveWebinarsScripts();
          this.forceUpdateKey += 1;
          this.createLiveWebinarScript();
        }
      },
    },

    timeout: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, newValue);
        }
      },
    },

    standardNotification: {
      handler: function (newVal) {
        if (newVal && this.fullScreen) {
          if (this.eventId === this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.EVENT_ID]) {
            if (newVal.dl) {
              const deepLink = newVal.dl;
              if (isQuizzesComponent(deepLink)) {
                const componentId = getComponentId(deepLink);
                if (
                  componentId ===
                  this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.QUIZ_COMPONENT_ID]
                ) {
                  const quizId = getQuizId(deepLink);
                  this.rating = {
                    componentId: componentId,
                    quizId: quizId,
                  };
                }
              }
            }
          }
        } else if (newVal === null) {
          this.rating = null;
        }
      },
    },
  },
};

function findLocalizedCode(source, key, locale) {
  const localizedKey = `${key}_${locale}`;
  if (localizedKey in source) {
    return source[localizedKey];
  } else if (key in source) {
    return source[key];
  } else {
    return null;
  }
}
</script>

<style lang="scss" scoped>
.fullscreen {
  overflow-x: hidden;
}

.full-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.full-screen-button {
  padding-left: 10px;
  padding-right: 10px;
  display: none;

  &.full-screen-enabled {
    position: absolute;
    top: 3%;
    right: 0.5%;
  }

  &.full-screen-disabled {
    margin-left: auto;
  }

  &.is-visible {
    display: flex;
  }
}

.rating-modal-container {
  background: rgba(black, 0.5);
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;

  .rating-modal {
  }
}
</style>
