<template>
  <div class="box">
    <div class="is-size-5 has-text-centered">{{ $t("admin_panel.side_menu_configuration_title") }}</div>
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <b-button type="is-primary" class="is-small mr-1" @click="save" :disabled="saveButtonDisabled">
        {{ $t("admin_panel.save") }}
      </b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="goBack" v-if="currentSubpanelIndex">
        {{ $t("admin_panel.return") }}
      </b-button>
    </div>

    <template v-if="!currentSubpanelIndex">
      <!--TOOLTIPS-->
      <b-button type="is-primary" class="is-fullwidth" @click="editTooltips">
        {{ $t("admin_panel.side_menu_bubble_tooltips") }}
      </b-button>
      <b-button type="is-primary" class="is-fullwidth mt-1" @click="editTitles">
        {{ $t("admin_panel.side_menu_bubble_titles") }}
      </b-button>
      <b-button type="is-primary" class="is-fullwidth mt-1" @click="editBubbleIcons">
        {{ $t("admin_panel.side_menu_bubble_icons") }}
      </b-button>
    </template>
    <template v-else-if="currentSubpanelIndex === SUBPANELS.BUBBLE_TOOLTIPS">
      <!--INBOX BUBBLE TOOLTIP-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_inbox") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editInboxTooltip">{{ inboxBubbleTooltip }} </b-button>
      </div>

      <!--QUIZ BUBBLE TOOLTIP-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_quizzes") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editQuizTooltip">{{ quizBubbleTooltip }} </b-button>
      </div>

      <!--FEEDWALL BUBBLE TOOLTIP-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_feedwall") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editFeedwallTooltip"
          >{{ feedWallBubbleTooltip }}
        </b-button>
      </div>

      <!--AGENDA SESSION QUESTIONS BUBBLE TOOLTIP-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_agenda_session_questions") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editAgendaSessionQuestionsTooltip">
          {{ agendaSessionQuestionsBubbleTooltip }}
        </b-button>
      </div>
    </template>
    <template v-else-if="currentSubpanelIndex === SUBPANELS.BUBBLE_TITLES">
      <!--INBOX BUBBLE TITLE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_inbox") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editInboxTitle">{{ inboxBubbleTitle }} </b-button>
      </div>

      <!--QUIZ BUBBLE TITLE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_quizzes") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editQuizTitle">{{ quizBubbleTitle }} </b-button>
      </div>

      <!--FEEDWALL BUBBLE TITLE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_feedwall") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editFeedwallTitle">
          {{ feedWallBubbleTitle }}
        </b-button>
      </div>

      <!--AGENDA SESSION QUESTIONS BUBBLE TITLE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_agenda_session_questions") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editAgendaSessionQuestionsTitle">
          {{ agendaSessionQuestionsBubbleTitle }}
        </b-button>
      </div>
    </template>
    <template v-else-if="currentSubpanelIndex === SUBPANELS.BUBBLE_ICONS">
      <!--INBOX BUBBLE ICON-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_inbox") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editInboxIcon">{{ inboxBubbleIcon }} </b-button>
      </div>

      <!--QUIZ BUBBLE ICON-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_quizzes") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editQuizIcon">{{ quizBubbleIcon }} </b-button>
      </div>

      <!--FEEDWALL BUBBLE ICON-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_feedwall") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editFeedwallIcon">{{ feedWallBubbleIcon }} </b-button>
      </div>

      <!--AGENDA SESSION QUESTIONS BUBBLE ICON-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.side_menu_agenda_session_questions") }}</label>
        <b-button type="is-primary" class="admin-translation-key-button" @click="editAgendaSessionQuestionsIcon">
          {{ agendaSessionQuestionsBubbleIcon }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

const SUBPANELS = {
  BUBBLE_TOOLTIPS: "bubble_tooltips",
  BUBBLE_TITLES: "bubble_titles",
  BUBBLE_ICONS: "bubble_icons",
};

export default {
  name: "AdminSideMenuConfiguration",
  mixins: [LpConfigMixin],
  data() {
    const sideMenuFields = LpConfigConstants.SIDE_MENU_FIELDS;

    return {
      [sideMenuFields.TOOLTIPS]: {},
      [sideMenuFields.TITLES]: {},
      [sideMenuFields.ICONS]: {},

      currentSubpanelIndex: null,
      currentTooltip: null,
      currentTitle: null,
      currentIcon: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapState("adminPanel", ["side_menu"]),

    SUBPANELS: () => SUBPANELS,

    sideMenuFields: () => LpConfigConstants.SIDE_MENU_FIELDS,

    //Tooltips

    inboxBubbleTooltip() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TOOLTIPS],
        this.sideMenuFields.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    quizBubbleTooltip() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TOOLTIPS],
        this.sideMenuFields.BUBBLE_TYPES.QUIZZES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    feedWallBubbleTooltip() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TOOLTIPS],
        this.sideMenuFields.BUBBLE_TYPES.FEED_WALL_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    agendaSessionQuestionsBubbleTooltip() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TOOLTIPS],
        this.sideMenuFields.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    //Titles

    inboxBubbleTitle() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TITLES],
        this.sideMenuFields.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    quizBubbleTitle() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TITLES],
        this.sideMenuFields.BUBBLE_TYPES.QUIZZES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    feedWallBubbleTitle() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TITLES],
        this.sideMenuFields.BUBBLE_TYPES.FEED_WALL_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    agendaSessionQuestionsBubbleTitle() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.TITLES],
        this.sideMenuFields.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    //Icons

    inboxBubbleIcon() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.ICONS],
        this.sideMenuFields.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    quizBubbleIcon() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.ICONS],
        this.sideMenuFields.BUBBLE_TYPES.QUIZZES_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    feedWallBubbleIcon() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.ICONS],
        this.sideMenuFields.BUBBLE_TYPES.FEED_WALL_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    agendaSessionQuestionsBubbleIcon() {
      return this.getFieldValueOrDefault(
        this.$data[this.sideMenuFields.ICONS],
        this.sideMenuFields.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE,
        this.$t("admin_panel.select_translation_key")
      );
    },

    saveButtonDisabled() {
      return (
        this.$data[this.sideMenuFields.TOOLTIPS] === this.side_menu[LpConfigConstants.SIDE_MENU_FIELDS.TOOLTIPS] &&
        this.$data[this.sideMenuFields.TITLES] === this.side_menu[LpConfigConstants.SIDE_MENU_FIELDS.TITLES] &&
        this.$data[this.sideMenuFields.ICONS] === this.side_menu[LpConfigConstants.SIDE_MENU_FIELDS.ICONS]
      );
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setSideMenuConfig"]),

    save() {
      this.setSideMenuConfig(this.getSideMenuConfig());
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    getSideMenuConfig() {
      const sideMenuFields = LpConfigConstants.SIDE_MENU_FIELDS;
      return {
        [sideMenuFields.TOOLTIPS]: this.$data[this.sideMenuFields.TOOLTIPS],
        [sideMenuFields.TITLES]: this.$data[this.sideMenuFields.TITLES],
        [sideMenuFields.ICONS]: this.$data[this.sideMenuFields.ICONS],
      };
    },

    goBack() {
      this.currentSubpanelIndex = null;
      this.$root.$emit("close-side-panel");
    },

    editTooltips() {
      this.currentSubpanelIndex = SUBPANELS.BUBBLE_TOOLTIPS;
    },

    editTitles() {
      this.currentSubpanelIndex = SUBPANELS.BUBBLE_TITLES;
    },

    editBubbleIcons() {
      this.currentSubpanelIndex = SUBPANELS.BUBBLE_ICONS;
    },

    // TOOLTIPS

    editInboxTooltip() {
      this.currentTooltip = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editFeedwallTooltip() {
      this.currentTooltip = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.FEED_WALL_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editQuizTooltip() {
      this.currentTooltip = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.QUIZZES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editAgendaSessionQuestionsTooltip() {
      this.currentTooltip = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    // TITLES

    editInboxTitle() {
      this.currentTitle = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editFeedwallTitle() {
      this.currentTitle = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.FEED_WALL_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editQuizTitle() {
      this.currentTitle = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.QUIZZES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editAgendaSessionQuestionsTitle() {
      this.currentTitle = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    // Icons

    editInboxIcon() {
      this.currentIcon = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.INBOX_MESSAGES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editFeedwallIcon() {
      this.currentIcon = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.FEED_WALL_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editQuizIcon() {
      this.currentIcon = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.QUIZZES_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    editAgendaSessionQuestionsIcon() {
      this.currentIcon = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.currentTooltip) {
        this.$data[this.sideMenuFields.TOOLTIPS] = {
          ...this.$data[this.sideMenuFields.TOOLTIPS],
          [this.currentTooltip]: translationKey,
        };
        this.currentTooltip = null;
      } else if (this.currentTitle) {
        this.$data[this.sideMenuFields.TITLES] = {
          ...this.$data[this.sideMenuFields.TITLES],
          [this.currentTitle]: translationKey,
        };
        this.currentTitle = null;
      } else if (this.currentIcon) {
        this.$data[this.sideMenuFields.ICONS] = {
          ...this.$data[this.sideMenuFields.ICONS],
          [this.currentIcon]: translationKey,
        };
        this.currentIcon = null;
      }
    },
  },

  watch: {
    side_menu: {
      immediate: true,
      handler: function (newValue) {
        this.$data[this.sideMenuFields.TOOLTIPS] = newValue[LpConfigConstants.SIDE_MENU_FIELDS.TOOLTIPS];
        this.$data[this.sideMenuFields.TITLES] = newValue[LpConfigConstants.SIDE_MENU_FIELDS.TITLES];
        this.$data[this.sideMenuFields.ICONS] = newValue[LpConfigConstants.SIDE_MENU_FIELDS.ICONS];
      },
    },
  },
};
</script>

<style scoped></style>
