import { Model } from "@vuex-orm/core";

export default class EventDayModel extends Model {
  static entity = "event_days";

  static fields() {
    return {
      id: this.attr(null),
      only_date: this.string(""),
    };
  }
}
