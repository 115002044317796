import { createApplication } from "./app";
import { pluckError, pluckErrorCode } from "@/shared/utils";

import LoginModal from "./views/modals/LoginModal";
import RegisterModal from "./views/modals/RegisterModal";
import GdprConsentModal from "./views/modals/GdprConsentModal";
import ForgotPasswordModal from "./views/modals/ForgotPasswordModal";
import WhitelistModal from "./views/modals/WhitelistModal";
import ErrorModal from "./views/modals/ErrorModal";
import SuccessModal from "./views/modals/SuccessModal";
import UserProfileModal from "./components/profile/UserProfileModal";
import NotificationDetailsModal from "@/web/components/notifications/NotificationDetailsModal";
import BMModal from "@/web/components/businessmatching/BMModal";
import AuthLoadingModal from "@/web/views/modals/AuthLoadingModal";
import InfoModal from "@/shared/components/modals/InfoModal";
import UserSpecificFieldsModal from "@/web/views/modals/UserSpecificFieldsModal";
import ConfirmationModal from "@/shared/components/modals/ConfirmationModal";
import ExhibitorDetailsModal from "@/shared/components/exhibitors/ExhibitorDetailsModal";
import SpeakerInfoModal from "@/shared/components/speakers/SpeakerInfoModal";
import MissingSystemConsentsModal from "@/web/views/modals/MissingSystemConsentsModal";

// TODO: Move to dedicated plugin
const methods = {
  openLoginModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: LoginModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: { ...props, isModal: true },
    });
  },

  openRegisterModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: RegisterModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: { ...props, isModal: true },
    });
  },

  openUserSpecificFieldsModal() {
    this.$buefy.modal.open({
      parent: this,
      component: UserSpecificFieldsModal,
      hasModalCard: true,
      canCancel: false,
      trapFocus: true,
    });
  },

  openMissingSystemConsentsModal() {
    this.$buefy.modal.open({
      parent: this,
      component: MissingSystemConsentsModal,
      hasModalCard: true,
      canCancel: false,
      trapFocus: true,
    });
  },

  openAuthLoadingModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AuthLoadingModal,
      hasModalCard: true,
      canCancel: false,
      trapFocus: true,
    });
  },

  openGdprConsentModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: GdprConsentModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props,
    });
  },

  openForgotPasswordModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: ForgotPasswordModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: { ...props, isModal: true },
    });
  },

  openWhitelistModal() {
    this.$buefy.modal.open({
      parent: this,
      component: WhitelistModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
    });
  },

  openUserModal(userId) {
    this.$buefy.modal.open({
      parent: this,
      component: UserProfileModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        userId: userId,
      },
    });
  },

  openNotificationModal(notification) {
    this.$buefy.modal.open({
      parent: this,
      component: NotificationDetailsModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        notification: notification,
      },
    });
  },

  openErrorModal(err) {
    const errorMessage = pluckError(err);
    const errorCode = pluckErrorCode(err);
    this.$buefy.modal.open({
      parent: this,
      component: ErrorModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        errorMessage,
        errorCode,
      },
    });
  },

  openSuccessModal(title, desc) {
    this.$buefy.modal.open({
      parent: this,
      component: SuccessModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        title,
        desc,
      },
    });
  },

  openBMModal({components, userUuid, meeting}) {
    this.$buefy.modal.open({
      parent: this,
      component: BMModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        components,
        userUuid,
        meeting,
      },
    });
  },

  openExhibitorModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: ExhibitorDetailsModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      fullScreen: false,
      width: 968,
      props,
    });
  },

  openSpeakerModal(props) {
    this.$buefy.modal.open({
      parent: this,
      component: SpeakerInfoModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props,
    });
  },

  openInfoModal({ title, message, imageUrl, messageAlignment }) {
    this.$buefy.modal.open({
      parent: this,
      component: InfoModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      props: {
        title: title,
        message: message,
        imageUrl: imageUrl,
        messageAlignment: messageAlignment,
      },
    });
  },

  /** This modal emits 'confirm' and 'close' events **/
  openConfirmationModal(title, message, events) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmationModal,
      hasModalCard: true,
      canCancel: true,
      trapFocus: true,
      events,
      props: {
        title: title,
        message: message,
      },
    });
  },
};

const render = h => {
  // No need for actual App component
  // Just display the router view
  return h("RouterView");
};

const app = createApplication({
  el: "#app",
  methods,
  render,
});

export default app;
