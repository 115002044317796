import { Model } from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";

export default class AgendaSessionQuestionModel extends Model {
  static entity = "agenda_session_questions";

  static fields() {
    return {
      id: this.attr(null),
      question: this.string(""),
      created_at: this.string(""),
      agenda_session_id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(UserModel, "user_id"),
      likes: this.number(0),
      liked_by_me: this.boolean(false),
      accepted_by_moderator: this.boolean(true),
    };
  }
}
