<template>
  <dropdown-select
    :items="allAgendaSessionLists"
    :multiple="false"
    :value="currentValue"
    radius="0px"
    :border-left="false"
    :border-right="false"
    :border-top="false"
    @input="selectAgendaSessionsList"
  >
    <!-- Path tag items -->
    <template #dropdown="{ item }">
      <div class="mr-1 filter-text">
        <!--        <point-icon class="point-icon" :style="{ fill: item.hex }"></point-icon>-->
        <span v-if="item === 0">{{ $t("session_questions.ongoing_sessions") }}</span>
        <span v-else-if="item === 1">{{ $t("session_questions.upcoming_sessions") }}</span>
        <span v-else-if="item === 2">{{ $t("session_questions.finished_sessions") }}</span>
      </div>
    </template>
  </dropdown-select>
</template>

<script>
import DropdownSelect from "@/shared/components/DropdownSelect";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "AgendaSessionListSelect",

  components: { DropdownSelect },

  data() {
    return {
      currentValue: 0,
    };
  },

  methods: {
    ...mapMutations("agendaSessionQuestions", ["selectAgendaSessionsList"]),
  },

  computed: {
    ...mapGetters("agendaSessionQuestions", ["allAgendaSessionLists"]),
    ...mapState("agendaSessionQuestions", ["selectedAgendaSessionList"]),
  },

  watch: {
    selectedAgendaSessionList: {
      immediate: true,
      handler: function (newValue) {
        this.currentValue = newValue;
      },
    },
  },
};
</script>

<style scoped>
.filter-text {
  font-size: 16px;
  line-height: 130%;
}
</style>
