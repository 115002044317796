<template>
  <footer class="footer" :style="footerStyle">
    <div class="content columns mb-0 is-vcentered is-multiline">
      <div class="column is-half has-text-centered">
        <p>{{ $t("footer.all_rights_reserved", ["2025"]) }}</p>
      </div>
      <div class="column is-half has-text-centered">
        <p>
          {{ $t("footer.powered_by") }}
          <img :src="meetingApplicationLogo" />
        </p>
      </div>
    </div>
    <div class="content columns mb-0 is-gapless is-centered">
      <div class="column is-narrow is-full-mobile has-text-centered mx-2">
        <router-link class="footer-button" :style="footerButtonStyle" :to="{ name: 'route_legal' }">
          {{ $t("footer.licenses") }}
        </router-link>
      </div>
      <div class="column is-narrow mx-2" v-if="ticketSalesTermsVisible">
        <a class="footer-button" :href="ticketSalesTermsUrl" :style="footerButtonStyle">
          {{ ticketSalesTermsString }}
        </a>
      </div>
      <div class="column is-narrow mx-2" v-if="isRzecommerce2024Event">
        <a class="footer-button" target="_blank" :href="$t('custom.rzecommerce_privacy_policy_url')" :style="footerButtonStyle">
          {{ $t("custom.rzecommerce_privacy_policy") }}
        </a>
      </div>
      <div class="column is-narrow mx-2" v-if="isRzecommerce2024Event">
        <a class="footer-button" target="_blank" href="mailto:marlena@orangewest.pl" :style="footerButtonStyle">
          {{ $t("custom.rze_contact") }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import tinycolor from "tinycolor2";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "Footer",
  mixins: [LpConfigMixin],
  computed: {
    ...mapGetters(["ticketSalesTerms", "lpSettings"]),
    ...mapState(["event"]),

    footerConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.FOOTER];
    },

    paymentConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.PAYMENT];
    },

    isRzecommerce2024Event() {
      return this.event.id === 1643;
    },

    ticketSalesTermsVisible() {
      return (
        this.event.terms_of_sale_url ||
        (this.paymentConfig &&
          this.paymentConfig[LpConfigConstants.PAYMENT_FIELDS.FOOTER_BUTTON_TEXT_KEY] &&
          this.paymentConfig[LpConfigConstants.PAYMENT_FIELDS.TICKET_TERMS_URL])
      );
    },

    ticketSalesTermsString() {
      if (this.event.terms_of_sale_url) {
        return this.$t("payment.accept_ticket_terms_substring");
      }
      return (
        this.paymentConfig && this.getCustomTranslation(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.FOOTER_BUTTON_TEXT_KEY, null)
      );
    },

    ticketSalesTermsUrl() {
      return (
        this.event.terms_of_sale_url ||
        (this.paymentConfig && this.getCustomTranslation(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.TICKET_TERMS_URL, null))
      );
    },

    backgroundColor() {
      return this.getFieldValueOrDefault(this.footerConfig, LpConfigConstants.FOOTER_FIELDS.BACKGROUND_COLOR, "#ffffff");
    },

    textColor() {
      return this.getFieldValueOrDefault(this.footerConfig, LpConfigConstants.FOOTER_FIELDS.TEXT_COLOR, "var(--text-color-primary)");
    },

    textColorHover() {
      return this.getFieldValueOrDefault(this.footerConfig, LpConfigConstants.FOOTER_FIELDS.TEXT_COLOR_HOVER, "var(--primary-color)");
    },

    footerStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
      };
    },

    footerButtonStyle() {
      return {
        "--footer-button-text-color": this.textColor,
        "--footer-button-hover-text-color": this.textColorHover,
      };
    },

    meetingApplicationLogo() {
      const luminance = tinycolor(this.backgroundColor).getLuminance();
      return luminance > 0.55 ? require("@/assets/logo_ma.jpg") : require("@/assets/logo_ma_white.png");
    },
  },
};
</script>
