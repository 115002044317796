export const keyPrefix = "app";

export function store(key, data) {
  const inflated = JSON.stringify(data);
  localStorage.setItem(`${keyPrefix}-${key}`, inflated);
}

export function retrieve(key) {
  const data = localStorage.getItem(`${keyPrefix}-${key}`);
  return JSON.parse(data);
}

export function clear(key) {
  localStorage.removeItem(`${keyPrefix}-${key}`);
}

export function createStorage(namespace) {
  const mkkey = createKeyFactory(namespace);

  return {
    store: (key, data) => store(mkkey(key), data),
    retrieve: key => retrieve(mkkey(key)),
    clear: key => clear(mkkey(key)),
  };
}

function createKeyFactory(namespace) {
  return key => (key ? `${namespace}-${key}` : namespace);
}

export default createStorage;
