<template>
  <form action="." method="POST" @submit.prevent="submit">
    <b-loading :active="isLoadingSystemConsents" :is-full-page="false"></b-loading>

    <!-- First name field -->
    <div class="columns is-mobile is-vcentered mb-0" ref="firstNameAndLastNameFields">
      <div class="column is-half">
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.auth_firstname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_firstname_placeholder')" v-model="firstName" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.firstName.required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Last name field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.auth_lastname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_lastname_placeholder')" v-model="lastName" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v.lastName.required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
          </template>
        </div>
      </div>
    </div>

    <!-- Email address field -->
    <div class="field" ref="emailField">
      <label class="label" :class="textColorClass">{{ $t("auth.email") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('auth.email')" v-model="email" />
      </div>
      <template v-if="submitted">
        <p v-if="!$v.email.required" class="help is-danger">{{ $t("auth.error_email") }}</p>
        <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
      </template>
    </div>

    <div class="columns is-mobile mb-1" ref="passwordFields">
      <div class="column is-half">
        <!-- Password field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.password") }}</label>
          <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <input class="input is-simple" :type="passwordInputType" :placeholder="$t('auth.password')" v-model="password" />
            <div class="password-visibility-button" @click="togglePasswordVisibility">
              <visibility-off-icon class="svg-icon has-fill-primary" v-if="isPasswordVisible"></visibility-off-icon>
              <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
            </div>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.password.required" class="help is-danger">{{ $t("auth.error_password") }}</p>
            <p v-if="!$v.password.minLength" class="help is-danger">{{ $t("common.field_too_short") }}</p>
          </template>
        </div>
      </div>

      <div class="column is-half">
        <!-- Repeat Password field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.repeat_password") }}</label>
          <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <input
              class="input is-simple"
              :type="repeatPasswordInputType"
              :placeholder="$t('auth.repeat_password')"
              v-model="repeatPassword"
            />
            <div class="password-visibility-button" @click="toggleRepeatPasswordVisibility">
              <visibility-off-icon class="svg-icon has-fill-primary" v-if="isRepeatPasswordVisible"></visibility-off-icon>
              <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
            </div>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.repeatPassword.sameAsPassword" class="help is-danger">{{ $t("auth.error_repeat_password") }}</p>
          </template>
        </div>
      </div>
    </div>

    <custom-register-form
      v-if="customFormData"
      ref="customRegisterForm"
      :current-locale="currentLocale"
      :form-data="customFormData"
      :include-register-form="false"
      update-type="input"
      @input="handleCustomFormInput"
    />

    <div class="field has-text-danger has-text-centered" v-if="$v.$anyError">
      {{ $t("auth.error_required_fields") }}
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("auth.dont_have_an_account_substring") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { mapGetters, mapState, mapActions } from "vuex";
import VisibilityIcon from "@/assets/auth/icon_visibility.svg";
import VisibilityOffIcon from "@/assets/auth/icon_visibility_off.svg";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "RegisterForm",

  props: ["showProgress"],

  components: { CustomRegisterForm, VisibilityIcon, VisibilityOffIcon },

  data() {
    return {
      submitted: false,
      email: "",
      password: "",
      repeatPassword: "",
      isPasswordVisible: false,
      isRepeatPasswordVisible: false,
      firstName: "",
      lastName: "",
      customFormData: null,
      customFormValues: null,
    };
  },

  validations() {
    let validations = {
      email: {
        required,
        email: value => email(value.toLowerCase().replace(/\s/g, "")),
      },
      password: { required, minLength: minLength(7) },
      firstName: { required },
      lastName: { required },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    };
    return validations;
  },

  methods: {
    ...mapActions("consents", ["fetchSystemConsents"]),

    async submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      // Validate custom form
      const customForm = this.$refs.customRegisterForm;
      if (customForm) {
        customForm.touch();
      }

      // Check both standard and custom form validation
      const isCustomFormValid = customForm ? customForm.isValid() : true;

      if (!this.$v.$invalid && isCustomFormValid) {
        const { email, password, firstName, lastName } = this.$data;
        const formData = {
          email: email.toLowerCase().replace(/\s/g, ""),
          password,
          firstName,
          lastName,
        };

        // Map custom form data to consents array if exists
        if (customForm) {
          const customFormValues = this.customFormValues;
          const consents = this.systemConsents.map(consent => ({
            consent_schema_id: consent.id,
            version_number: consent.version_number,
            accepted: customFormValues[consent.id] === true,
          }));
          formData.consents = consents;
        } else {
          formData.consents = [];
        }

        this.$emit("submit", formData);
      } else {
        this.scrollToFirstFieldWithError();
      }
    },

    scrollToFirstFieldWithError() {
      const scrollArgs = { behavior: "smooth", block: "start" };

      if (this.$v.firstName.$anyError || this.$v.lastName.$anyError) {
        this.$refs.firstNameAndLastNameFields.scrollIntoView(scrollArgs);
      } else if (this.$v.email.$anyError) {
        this.$refs.emailField.scrollIntoView(scrollArgs);
      } else if (this.$v.password.$anyError || this.$v.repeatPassword.$anyError) {
        this.$refs.passwordFields.scrollIntoView(scrollArgs);
      }

      // Check custom form validation
      const customForm = this.$refs.customRegisterForm;
      if (customForm && !customForm.isValid()) {
        customForm.$el.scrollIntoView(scrollArgs);
      }
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },

    toggleRepeatPasswordVisibility() {
      this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
    },

    async loadConsents() {
      await this.fetchSystemConsents();
    },

    handleCustomFormInput(formData) {
      this.customFormValues = formData?.metaData;
    },
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),
    ...mapGetters("consents", ["systemConsentsForm", "isLoadingSystemConsents"]),
    ...mapState("consents", ["systemConsents"]),
    ...mapState(["eventId"]),

    passwordInputType() {
      return this.isPasswordVisible ? "text" : "password";
    },

    repeatPasswordInputType() {
      return this.isRepeatPasswordVisible ? "text" : "password";
    },

    textColorClass() {
      return "has-text-primary";
    },
  },

  watch: {
    currentLocale: {
      immediate: true,
      handler: async function () {
        await this.loadConsents();
      },
    },

    systemConsentsForm: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$mergeCustomTranslations(newValue[FormBuilderConstants.TRANSLATIONS], FormBuilderConstants.TRANSLATIONS_NAMESPACE);
          this.customFormData = newValue;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
