<template>
  <div class="box">
    <div class="is-size-5 mr-1">{{ $t("admin_panel.translations_configuration_title") }}</div>
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <b-button type="is-primary" class="is-small mr-1" @click="finishWithSelection" v-if="selectable && !editMode">
        {{ $t("admin_panel.select") }}
      </b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="createNewKey" v-if="!editMode">
        {{ $t("admin_panel.create") }}
      </b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="finishWithoutSelection" v-if="!editMode && selectable">
        {{ $t("admin_panel.cancel") }}
      </b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="save" :disabled="isSaveButtonDisabled" v-if="editMode">
        {{ $t("admin_panel.save") }}
      </b-button>
      <b-button type="is-primary" class="is-small mr-1" @click="exitEditMode" v-if="editMode">
        {{ $t("admin_panel.return_to_list") }}
      </b-button>
    </div>

    <template v-if="editMode">
      <div class="field">
        <label class="label has-text-primary"> {{ $t("admin_panel.translations_translation_key") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('admin_panel.translations_translation_key')"
            v-model="translationKeyToEdit"
          />
        </div>
      </div>

      <field-label-editor
        title="Values"
        :current-label-translations="getKeyTranslations(translationKeyToEdit)"
        @input="updateCurrentKeyTranslations"
      ></field-label-editor>
    </template>

    <template v-else>
      <div v-for="translationKey in translationKeys" :key="translationKey">
        <div class="columns is-mobile is-vcentered is-variable is-1">
          <div class="column is-narrow" v-if="selectable">
            <radio-button
              :is-primary="true"
              :value="selectedTranslationKey === translationKey"
              @input="selectTranslationKey(translationKey)"
            ></radio-button>
          </div>
          <div class="column is-narrow">
            <div class="edit-button" @click="editKey(translationKey)">
              <edit-icon class="svg-icon has-fill-primary"></edit-icon>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="edit-button" @click="removeTranslationKey(translationKey)">
              <trash-bin-icon class="svg-icon"></trash-bin-icon>
            </div>
          </div>
          <div class="column is-size-7 translation-key-text">
            {{ translationKey }}
          </div>
        </div>
      </div>
      <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mt-2 mb-1">
        <b-button type="is-primary" class="is-small mr-1" @click="finishWithSelection" v-if="selectable && !editMode">
          {{ $t("admin_panel.select") }}
        </b-button>
        <b-button type="is-primary" class="is-small mr-1" @click="createNewKey" v-if="!editMode">
          {{ $t("admin_panel.create") }}
        </b-button>
        <b-button type="is-primary" class="is-small mr-1" @click="finishWithoutSelection" v-if="!editMode && selectable">
          {{ $t("admin_panel.cancel") }}
        </b-button>
        <b-button type="is-primary" class="is-small mr-1" @click="save" :disabled="isSaveButtonDisabled" v-if="editMode">
          {{ $t("admin_panel.save") }}
        </b-button>
        <b-button type="is-primary" class="is-small mr-1" @click="exitEditMode" v-if="editMode">
          {{ $t("admin_panel.return_to_list") }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import RadioButton from "@/shared/components/form/RadioButton";
import EditIcon from "@/assets/icons/edit.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import FieldLabelEditor from "@/web/views/dev/formbuilder/FieldLabelEditor";

export default {
  name: "AdminTranslationsConfiguration",

  components: { FieldLabelEditor, RadioButton, EditIcon, TrashBinIcon },

  props: {
    selectable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedTranslationKey: null,
      //EDIT MODE
      editMode: false,
      translationKeyToEdit: null,
      editModeInitialTranslationKey: null,
      editModeUpdatedTranslations: null,
      editModeTranslationsUpdateCount: 0,
    };
  },

  computed: {
    ...mapState("adminPanel", ["translations"]),

    translationKeys() {
      return Object.keys(Object.assign({}, ...Object.values(this.translations)));
    },

    isSaveButtonDisabled() {
      return !(this.translationKeyToEdit !== this.editModeInitialTranslationKey || this.editModeTranslationsUpdateCount > 1);
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["updateTranslations", "removeTranslationKey"]),

    save() {
      if (this.editMode) {
        if (this.translationKeyToEdit !== this.editModeInitialTranslationKey) {
          this.removeTranslationKey(this.editModeInitialTranslationKey);
        }

        const newTranslations = Object.entries(this.editModeUpdatedTranslations).reduce((acc, keyValue) => {
          if (keyValue[1]) {
            return {
              ...acc,
              [keyValue[0]]: { [this.translationKeyToEdit]: keyValue[1] },
            };
          } else {
            return {
              ...acc,
              [keyValue[0]]: { [this.translationKeyToEdit]: null },
            };
          }
        }, {});
        this.updateTranslations(newTranslations);
        this.exitEditMode();
      }
    },

    createNewKey() {
      this.editMode = true;
    },

    editKey(translationKey) {
      this.translationKeyToEdit = translationKey;
      this.editModeInitialTranslationKey = translationKey;
      this.editMode = true;
    },

    getKeyTranslations(translationKey) {
      return Object.entries(this.translations).reduce((acc, keyValue) => ({ ...acc, [keyValue[0]]: keyValue[1][translationKey] }), {});
    },

    updateCurrentKeyTranslations(translations) {
      this.editModeUpdatedTranslations = translations;
      this.editModeTranslationsUpdateCount = this.editModeTranslationsUpdateCount + 1;
    },

    selectTranslationKey(translationKey) {
      if (this.selectedTranslationKey === translationKey) {
        this.selectedTranslationKey = null;
      } else {
        this.selectedTranslationKey = translationKey;
      }
    },

    exitEditMode() {
      this.editMode = false;
      this.translationKeyToEdit = null;
      this.editModeTranslationsUpdateCount = 0;
      this.editModeInitialTranslationKey = null;
      this.editModeUpdatedTranslations = null;
    },

    finishWithSelection() {
      this.$emit("close");
      this.$root.$emit("translation-key-selected", this.selectedTranslationKey);
    },

    finishWithoutSelection() {
      this.$emit("close");
    },
  },

  watch: {},
};
</script>

<style scoped lang="scss">
.edit-button {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
}

.translation-key-text {
  word-break: break-all;
}
</style>
