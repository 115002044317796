import { Model } from "@vuex-orm/core";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";
import BusinessMatchingSessionModel from "@/web/store/models/BusinessMatchingSessionModel";
import UserModel from "@/web/store/models/UserModel";

export default class BusinessMatchingMeetingModel extends Model {
  static entity = "bm_meetings";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      tag_agenda_place_id: this.attr(null),
      tag_agenda_place: this.belongsTo(AgendaSessionPathModel, "tag_agenda_place_id"),
      status: this.string(""),
      message: this.string("").nullable(),
      custom_place_name: this.string(null).nullable(),
      time_start: this.string(null).nullable(),
      time_end: this.string(null).nullable(),
      business_matching_meeting_session_id: this.attr(null),
      business_matching_meeting_session: this.belongsTo(BusinessMatchingSessionModel, "business_matching_meeting_session_id"),
      from_user_id: this.attr(null),
      from_user: this.belongsTo(UserModel, "from_user_id"),
      to_user_id: this.attr(null),
      to_user: this.belongsTo(UserModel, "to_user_id"),
    };
  }
}
