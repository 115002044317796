import axios from "@/web/services/axios";

export async function check(eventId, code) {
  const url = `events/${eventId}/check_access_code`;
  const headers = { AccessCode: code };

  await axios.post(url, null, { headers });
  return true;
}

export default {
  check,
};
