<template>
  <div class="modal-card is-relative">
    <b-loading :is-full-page="false" :active="isRequesting" />
    <header class="modal-card-head has-text-centered">
      <p class="modal-card-title">
        {{ title }}
      </p>
      <p class="modal-card-subtitle">
        {{ subtitle }}
      </p>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <custom-register-form
        v-if="customFormData"
        :form-data="customFormData"
        :include-register-form="false"
        :current-locale="currentLocale"
        @submit="onFormSubmit"
      ></custom-register-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "MissingSystemConsentsModal",
  components: { CustomRegisterForm },
  mixins: [RequestMixin, RedirectMixin],

  data() {
    return {
      customFormData: null,
    };
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),
    ...mapGetters("consents", ["missingSystemConsentsForm"]),
    ...mapState("consents", ["missingSystemConsents"]),

    title() {
      return this.$t("consents.missing_system_consents_title");
    },

    subtitle() {
      return this.$t("consents.missing_system_consents_subtitle");
    },
  },

  methods: {
    ...mapActions(["submitMissingSystemConsents"]),
    ...mapMutations("auth", ["setAuthLoadingStatus"]),
    ...mapActions("consents", ["fetchMissingSystemConsents"]),

    onFormSubmit({ metaData }) {
      const acceptedConsents = this.missingSystemConsents.map(consent => ({
        consent_schema_id: consent.id,
        version_number: consent.version_number,
        accepted: metaData[consent.id] === true,
      }));

      this.submit(acceptedConsents);
    },

    openAuthLoadingModal() {
      this.$root.openAuthLoadingModal();
    },

    onSuccess() {
      const defaultRoute = { name: Constants.ROUTE_MAIN_INTRODUCTION };
      this.nextRedirect(defaultRoute);
      this.$parent.close();
    },
  },

  requests: {
    async loadConsents() {
      await this.fetchMissingSystemConsents();
    },

    async submit(acceptedConsents) {
      this.openAuthLoadingModal();
      await this.submitMissingSystemConsents({ acceptedConsents });
      this.onSuccess();
    },
  },

  watch: {
    currentLocale: {
      immediate: true,
      handler: async function () {
        await this.loadConsents();
      },
    },

    missingSystemConsentsForm: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$mergeCustomTranslations(newValue[FormBuilderConstants.TRANSLATIONS], FormBuilderConstants.TRANSLATIONS_NAMESPACE);
          this.customFormData = newValue;
        }
      },
    },

    error(newVal) {
      if (newVal) {
        this.setAuthLoadingStatus(Constants.AUTH_LOADING_STATUSES.ERROR);
        const data = newVal.response.data;
        if (data && data.errors && Array.isArray(data.errors)) {
          this.$root.openErrorModal(newVal);
        }
        this.error = null;
      }
    },
  },
};
</script>

<style scoped></style>
