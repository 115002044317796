<template>
  <div :class="{ 'modal-card': isModal, 'mb-2': !isModal, 'pt-1': !isModal }">
    <header class="has-text-centered" :class="{ 'modal-card-head': isModal }">
      <h2 class="modal-card-title" v-if="isModal">{{ $t("auth.password_reminder_title") }}</h2>
      <p class="modal-card-subtitle">
        {{ $t("auth.password_reminder_info") }}<br />
        {{ $t("auth.password_reminder_info2") }}
      </p>
      <button class="delete" type="button" @click="$parent.close" v-if="isModal"></button>
    </header>

    <section :class="{ 'modal-card-body': isModal }">
      <forgot-password-form :showProgress="isRequesting" @submit="resetPassword"></forgot-password-form>
      <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>

    <footer class="has-text-centered" :class="{ 'modal-card-foot': isModal, 'pt-3': !isModal }" v-if="eventId">
      <p>
        <i18n path="auth.dont_have_an_account" tag="span" class="auth-footer-text">
          <a @click.prevent="openRegister">
            {{ $t("auth.dont_have_an_account_substring") }}
          </a>
        </i18n>
      </p>
      <p>
        <i18n path="auth.already_have_an_account" tag="span" class="auth-footer-text">
          <a @click.prevent="openLogin">
            {{ $t("auth.already_have_an_account_substring") }}
          </a>
        </i18n>
      </p>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import ForgotPasswordForm from "@/web/components/forms/ForgotPasswordForm";
import Constants from "@/web/constants";

export default {
  mixins: [RequestMixin, RedirectMixin],

  name: "ForgotPasswordModal",

  components: {
    ForgotPasswordForm,
  },

  props: {
    isModal: {
      type: Boolean,
      required: true,
    },
  },

  requests: {
    async resetPassword(formData) {
      try {
        await this.reset({ ...formData, event_id: this.eventId });
        this.openSuccessModal();
        if (this.isModal) {
          this.$parent.close();
        } else {
          this.$router.push({ name: Constants.ROUTE_LOGIN });
        }
      } catch (err) {
        this.$root.openErrorModal(err);
      }
    },
  },

  methods: {
    ...mapActions("auth", ["reset"]),

    openRegister() {
      if (this.isModal) {
        this.$root.openRegisterModal(this.$props);
        this.$parent.close();
      } else {
        this.$router.push({ name: Constants.ROUTE_REGISTER });
      }
    },

    openLogin() {
      if (this.isModal) {
        this.$root.openLoginModal(this.$props);
        this.$parent.close();
      } else {
        this.$router.push({ name: Constants.ROUTE_LOGIN });
      }
    },

    openSuccessModal() {
      this.$root.openInfoModal({ message: this.$t("auth.password_reminder_success") });
    },
  },

  computed: {
    ...mapState(["eventId"]),
  },
};
</script>
