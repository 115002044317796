export async function login(appId) {
  const FB = await loadFacebookSingleton(appId);

  return new Promise((resolve, reject) => {
    const handleLogin = response => {
      if (response.authResponse) {
        resolve(response.authResponse);
      } else {
        const err = new Error("User cancelled login or did not fully authorize");
        reject(err);
      }
    };

    FB.login(handleLogin, {
      scope: "email",
    });
  });
}

export async function logout() {
  return new Promise(resolve => {
    if (window.FB && window.FB.logout) {
      window.FB.logout(() => resolve());
    } else {
      resolve();
    }
  });
}

function loadFacebookSingleton(appId) {
  if (!loadFacebookSingleton.promise) {
    loadFacebookSingleton.promise = loadFacebookScript();
  }

  return loadFacebookSingleton.promise.then(() => {
    initFacebook(appId);
    return window.FB;
  });
}

function loadFacebookScript() {
  const script = document.createElement("script");
  script.src = "https://connect.facebook.net/en_US/sdk.js";
  script.crossOrigin = "anonymous";
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load facebook script`));
  });
}

function initFacebook(appId) {
  window.FB.init({
    appId,
    autoLogAppEvents: true,
    xfbml: true,
    version: "v8.0",
  });
}

export default {
  login,
  logout,
};
