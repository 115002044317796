<template>
  <div class="survey-progress-bar" :style="progressStyle">
    <span v-for="index in steps" :key="index" :class="{ active: isActive(index) }" @click="$emit('input', index)">{{ index }}</span>
  </div>
</template>

<script>
export default {
  props: ["steps", "value"],

  methods: {
    isActive(index) {
      return index <= this.value;
    },
  },

  computed: {
    progressStyle() {
      const index = Math.max(this.value - 1, 0);
      const last = Math.max(this.steps - 1, 1);

      const progress = Math.floor((index / last) * 100.0);

      return {
        backgroundSize: `${progress}% 100%, 100% 100%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-progress-bar {
  margin-bottom: 60px;
  background-image: url("~@/assets/external/progress-bar-full.png"), url("~@/assets/external/progress-bar.png");
  background-repeat: no-repeat;
  background-size: 0% 100%, 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-size 400ms ease;

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}

span {
  background-color: #e6eaff;
  border: solid 1px #ffffff;
  border-radius: 100%;
  font-size: 0;
  color: #ffffff;
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  transition: background-color 400ms ease;
  user-select: none;
  cursor: pointer;

  &.active {
    background-color: #6784ff;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    font-size: 0 !important;
    width: 14px;
    height: 14px;
  }
}
</style>
