<template>
  <div class="empty-placeholder-container">
    <div class="empty-placeholder-icon">
      <info-icon class="svg-icon has-fill-danger"></info-icon>
    </div>
    <div class="empty-placeholder-text">
      {{ text }}
    </div>
    <b-button type="is-primary" class="button mt-2" @click="$emit('reload')">
      {{ $t("moderator.error_try_again_button") }}
    </b-button>
  </div>
</template>

<script>
import InfoIcon from "@/assets/icons/info.svg";
import { pluckError } from "@/shared/utils";

export default {
  name: "FetchingErrorPlaceholder",
  components: { InfoIcon },

  props: ["error"],

  computed: {
    text() {
      if (this.error) {
        let errorMessage = pluckError(this.error);
        if (errorMessage) {
          return errorMessage;
        }
      }
      return this.$t("moderator.error_placeholder_text");
    },
  },
};
</script>

<style scoped></style>
