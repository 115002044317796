<template>
  <article class="module">
    <div class="container">
      <div class="rzecommerce-faq columns is-mobile is-multiline">
        <div class="rzecommerce-faq__content is-flex-grow-1">
          <h1 class="faq-title mb-2">
            {{ $t("custom.rzecommerce_faq_title_p1") }} <br /><span class="smaller">{{ $t("custom.rzecommerce_faq_title_p2") }}</span>
          </h1>

          <div class="rzecommerce-faq__list">
            <div
              v-for="(item, index) in faqItems"
              :key="index"
              class="rzecommerce-faq__item"
            >
              <div
                class="rzecommerce-faq__item-header"
                @click="toggleItem(index)"
              >
                <span class="question">{{ item.question }}</span>
                <span class="toggle-icon">{{ activeIndex === index ? '-' : '+' }}</span>
              </div>
              <transition name="faq-toggle" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div
                  v-if="activeIndex === index"
                  class="rzecommerce-faq__item-body"
                  ref="faqBody"
                >
                  <span v-if="item.isHtml" v-html="item.answer"></span>
                  <span v-else>{{ item.answer }}</span>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="rzecommerce-faq__image is-flex-grow-1">
          <img class="rzecommerce-faq-img" src="@/assets/custom/rzecommerce/faq.png" alt="FAQ Image">
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "RzecommerceFaqComponent",
  mixins: [LpConfigMixin],
  data() {
    return {
      faqItems: [
        { question: this.$t("custom.rzecommerce_faq_1_head"), answer: this.$t("custom.rzecommerce_faq_1_body"), isHtml: false },
        { question: this.$t("custom.rzecommerce_faq_2_head"), answer: this.$t("custom.rzecommerce_faq_2_body"), isHtml: false },
        { question: this.$t("custom.rzecommerce_faq_3_head"), answer: this.$t("custom.rzecommerce_faq_3_body"), isHtml: true },
        { question: this.$t("custom.rzecommerce_faq_4_head"), answer: this.$t("custom.rzecommerce_faq_4_body"), isHtml: false },
        { question: this.$t("custom.rzecommerce_faq_5_head"), answer: this.$t("custom.rzecommerce_faq_5_body"), isHtml: false },
        { question: this.$t("custom.rzecommerce_faq_6_head"), answer: this.$t("custom.rzecommerce_faq_6_body"), isHtml: false },
      ],
      activeIndex: null,
    };
  },
  methods: {
    toggleItem(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.addEventListener('transitionend', () => {
        el.style.height = 'auto';
      }, { once: true });
    },
    leave(el) {
      el.style.height = `${el.scrollHeight}px`;
      // Trigger reflow to force the animation
      void el.offsetHeight;
      el.style.height = '0';
    },
  }
};
</script>

<style scoped lang="scss">
.module {
  margin-bottom: 140px;
}

::v-deep .link-primary-color {
  color: var(--primary-color) !important;
}

.rzecommerce-faq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  box-sizing: border-box;
  align-items: stretch;

  @media(max-width: 1389px) {
    align-items: center;
  }

  & .faq-title {
    color: #fff;
    font-size: 42px;
    line-height: 100%;
    font-weight: 900;

    & .smaller {
      font-size: 32px;
    }
  }

  &__content {
    height: auto;
    flex: 1;
    background-color: #5F23F0;
    border-radius: 14px;
    padding: 36px;
  }

  &__image {
    flex: 1;

    //@media(max-width: 926px) {
    //  display: none;
    //}

    & img {
      width: 100%;
      min-width: 300px;
    }
  }

  &__list {
    .rzecommerce-faq__item {
      border-bottom: 1px solid #ccc;
      padding: 12px 0;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        width: 100%;

        & .question {
          color: #fff;
          font-weight: 400;
          font-size: 22px;
          flex: 1;
        }

        .toggle-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background-color: #fff;
          border-radius: 50%;
          font-size: 24px;
          color: #5F23F0;
          margin-left: 12px;
        }
      }

      &-body {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        overflow: hidden;
      }
    }
  }
}

.faq-toggle-enter-active,
.faq-toggle-leave-active {
  transition: height 0.3s ease;
}

.faq-toggle-enter-from,
.faq-toggle-leave-to {
  height: 0;
  overflow: hidden;
}

.faq-toggle-enter-to,
.faq-toggle-leave-from {
  height: auto;
  overflow: hidden;
}
</style>
