<template>
  <div>
    <div class="columns is-mobile">
      <div class="column is-half">
        <b-button type="is-warning" class="is-small has-fullwidth" @click="$emit('navigate-back')">
          {{ $t("admin_panel.return") }}
        </b-button>
      </div>
      <div class="column is-half">
        <b-button type="is-primary" class="is-small has-fullwidth" @click="save">{{ $t("admin_panel.save") }}</b-button>
      </div>
    </div>

    <!--MODULE COMMON FIELDS-->
    <admin-module-config-common v-model="commonFieldsObject" ref="adminModuleConfigCommon"></admin-module-config-common>

    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-2" v-if="specificModuleConfigComponent">
      <div class="title has-text-centered">{{ $t("admin_panel.module_specific_fields") }}</div>
      <component :is="specificModuleConfigComponent" v-model="specificFieldsObject"></component>
    </div>

    <div class="columns is-mobile mt-2">
      <div class="column is-half">
        <b-button type="is-warning" class="is-small has-fullwidth" @click="$emit('navigate-back')">
          {{ $t("admin_panel.return") }}
        </b-button>
      </div>
      <div class="column is-half">
        <b-button type="is-primary" class="is-small has-fullwidth" @click="save">{{ $t("admin_panel.save") }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import AdminModuleConfigCommon from "@/web/components/admin/subpanels/modules/AdminModuleConfigCommon";
import AdminModuleConfigMobileAppInfo from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigMobileAppInfo";
import AdminModuleConfigTwitter from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigTwitter";
import AdminModuleConfigSocialLinks from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigSocialLinks";
import AdminModuleConfigEventTickets from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigEventTickets";
import AdminModuleConfigAgenda from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigAgenda";
import AdminModuleConfigSpeakers from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigSpeakers";
import AdminModuleConfigAttendees from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigAttendees";
import AdminModuleConfigExhibitors from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigExhibitors";
import AdminModuleConfigPartners from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigPartners";
import AdminModuleConfigLiveWebinar from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigLiveWebinar";
import AdminModuleConfigIFrame from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigIFrame";
import AdminModuleConfigIFrameNative from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigIFrameNative";
import AdminModuleConfigQuizzes from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigQuizzes";
import AdminModuleConfigFaq from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigFaq";
import AdminModuleConfigContactInformation from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigContactInformation";
import AdminModuleConfigEventDescription from "@/web/components/admin/subpanels/modules/specific/AdminModuleConfigEventDescription";

export default {
  name: "AdminModuleConfigMain",
  components: { AdminModuleConfigCommon },

  props: {
    currentModule: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      commonFieldsObject: mapToCommonFields(this.currentModule),
      specificFieldsObject: mapToSpecificFields(this.currentModule),
    };
  },

  beforeDestroy() {
    this.save();
  },

  computed: {
    moduleFields: () => LpConfigConstants.TWITTER_MODULE_FIELDS,

    specificModuleConfigComponent() {
      const moduleType = this.commonFieldsObject[LpConfigConstants.COMMON_MODULE_FIELDS.TYPE];
      const moduleTypes = LpConfigConstants.MODULE_TYPES;
      if (moduleType === moduleTypes.TWITTER) {
        return AdminModuleConfigTwitter;
      } else if (moduleType === moduleTypes.APP_INFO) {
        return AdminModuleConfigMobileAppInfo;
      } else if (moduleType === moduleTypes.SOCIAL_LINKS) {
        return AdminModuleConfigSocialLinks;
      } else if (moduleType === moduleTypes.EVENT_TICKETS) {
        return AdminModuleConfigEventTickets;
      } else if (moduleType === moduleTypes.COMPONENT_AGENDA) {
        return AdminModuleConfigAgenda;
      } else if (moduleType === moduleTypes.COMPONENT_SPEAKERS) {
        return AdminModuleConfigSpeakers;
      } else if (moduleType === moduleTypes.COMPONENT_ATTENDEES) {
        return AdminModuleConfigAttendees;
      } else if (moduleType === moduleTypes.COMPONENT_EXHIBITORS) {
        return AdminModuleConfigExhibitors;
      } else if (moduleType === moduleTypes.COMPONENT_PARTNERS) {
        return AdminModuleConfigPartners;
      } else if (moduleType === moduleTypes.COMPONENT_QUIZZES) {
        return AdminModuleConfigQuizzes;
      } else if (moduleType === moduleTypes.COMPONENT_LIVE_WEBINAR) {
        return AdminModuleConfigLiveWebinar;
      } else if (moduleType === moduleTypes.IFRAME) {
        return AdminModuleConfigIFrame;
      } else if (moduleType === moduleTypes.IFRAME_NATIVE) {
        return AdminModuleConfigIFrameNative;
      } else if (moduleType === moduleTypes.COMPONENT_FAQ) {
        return AdminModuleConfigFaq;
      } else if (moduleType === moduleTypes.COMPONENT_EVENT_CONTACT_INFO) {
        return AdminModuleConfigContactInformation;
      } else if (moduleType === moduleTypes.EVENT_DESCRIPTION) {
        return AdminModuleConfigEventDescription;
      } else if (moduleType === moduleTypes.COMPONENT_FEED_WALL) {
        // module has no specific fields
        // return AdminModuleConfigComponentFeedWall;
        return null;
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["upsertModule"]),

    getModule() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },

    save() {
      const module = { ...this.commonFieldsObject, ...this.specificFieldsObject };
      this.upsertModule(module);
      this.$emit("navigate-back");
    },
  },
};

function mapToCommonFields(moduleObject) {
  const commonFieldKeys = Object.values(LpConfigConstants.COMMON_MODULE_FIELDS);
  return Object.entries(moduleObject)
    .filter(([key, value]) => {
      return commonFieldKeys.includes(key);
    })
    .reduce((acc, [key, value]) => {
      return { ...acc, [key]: value };
    }, {});
}

function mapToSpecificFields(moduleObject) {
  const commonFieldKeys = Object.values(LpConfigConstants.COMMON_MODULE_FIELDS);
  return Object.entries(moduleObject)
    .filter(([key, value]) => {
      return !commonFieldKeys.includes(key);
    })
    .reduce((acc, [key, value]) => {
      return { ...acc, [key]: value };
    }, {});
}
</script>

<style scoped></style>
