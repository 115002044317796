<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ modalTitle }}
      </h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <template v-if="calendarEvents && !calendarEvents.length">
        <div class="subtitle">
          {{ $t("ical.ical_empty_events_description") }}
        </div>
        <div class="columns is-mobile is-gapless mt-1">
          <div class="column is-half">
            <button class="button is-primary has-fullwidth mr-1" @click="$parent.close()">
              {{ $t("common.ok") }}
            </button>
          </div>
          <div class="column is-half">
            <button class="button has-fullwidth ml-1" @click="tryAgain">
              {{ $t("common.try_again_capital_letters") }}
            </button>
          </div>
        </div>
      </template>
      <template v-else-if="calendarEvents && calendarEvents.length">
        <div class="subtitle">
          {{ $t("google_calendar.import_options_description") }}
        </div>
        <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
          <button class="button is-fullwidth is-primary mx-1" style="margin-top: 1rem" @click="merge">
            {{ $t("google_calendar.import_merge") }}
          </button>
          <button class="button is-fullwidth mx-1" style="margin-top: 1rem" @click="replace">
            {{ $t("google_calendar.import_replace") }}
          </button>
        </div>
        <div v-if="error">
          {{ error | pluckError }}
        </div>
      </template>
      <template v-else>
        <label class="upload-button-container is-relative">
          <add-icon class="upload-button-add-icon mr-1" v-if="!fileUploading"></add-icon>
          <div class="upload-button-add-title has-text-secondary" v-if="!fileUploading">
            {{ $t("ical.ical_upload_button") }}
          </div>
          <b-loading v-if="fileUploading" :is-full-page="false" :active="fileUploading"></b-loading>
          <input v-else type="file" accept="ics" @change="onNewFile" style="visibility: hidden; width: 0; height: 0" />
        </label>
      </template>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import AddIcon from "@/assets/icons/add_2.svg";
import { getEventsFromICalString, transformUnavailabilityToAvailability, mergeCollidingAvailabilities } from "@/shared/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";

export default {
  name: "ICalSyncModal",

  props: ["errorMessage"],

  components: { CloseIcon, AddIcon },

  mixins: [RequestMixin],

  data() {
    return {
      rawData: null,
      calendarEvents: null,
      fileUploading: false,
    };
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters("businessMatchingAvailability", ["availabilityDays", "allAvailabilities"]),

    modalTitle() {
      if (this.calendarEvents && this.calendarEvents.length) {
        return this.$t("google_calendar.import_options_title");
      } else if (this.calendarEvents) {
        return this.$t("ical.ical_empty_events_title");
      } else {
        return this.$t("ical.ical_upload_file");
      }
    },
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["sendAvailability"]),
    ...mapMutations("businessMatchingAvailability", ["setAvailability"]),

    onNewFile(event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => {
          const rawDataString = evt.target.result;
          this.parseRawDataString(rawDataString);
        };
      }
    },

    parseRawDataString(iCalString) {
      this.calendarEvents = getEventsFromICalString(iCalString, this.event, this.$ical);
      // console.log(this.calendarEvents);
    },

    merge() {
      const newAvailabilities = this.transformEventsToAvailability();
      const oldAvailabilities = this.allAvailabilities;
      this.setAvailability(mergeCollidingAvailabilities(newAvailabilities, oldAvailabilities));
      this.send();
    },

    replace() {
      this.setAvailability(this.transformEventsToAvailability());
      this.send();
    },

    transformEventsToAvailability() {
      const unavailability = this.calendarEvents.map(calendarEvent => {
        return {
          unavailability_from: calendarEvent.startDate,
          unavailability_to: calendarEvent.endDate,
        };
      });
      const eventDays = this.availabilityDays;
      return transformUnavailabilityToAvailability(unavailability, eventDays);
    },

    tryAgain() {
      this.calendarEvents = null;
    },
  },

  requests: {
    async send() {
      await this.sendAvailability().then(result => {
        this.$parent.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
  }
}

.upload-button-container {
  height: 56px;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.1;
  }
}

.upload-button-add-icon {
  width: 22px;
  height: 22px;
}

.upload-button-add-title {
  font-size: 18px;
  line-height: 130%;
}

.subtitle {
  white-space: pre-line;
  text-align: center;
}
</style>
