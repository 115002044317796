// import Vue from 'vue';
// import VueGtag from 'vue-gtag';
import { mapMutations, mapGetters } from "vuex";

const ANALYTICS_IDS = {
  1488: "G-EBR0RYHXXR",
  1486: "G-S2QF2C3C04", // Optymalizacja LP event (inside testing)
};

export default {
  methods: {
    ...mapMutations("utm", ["setUTMParameters"]),
    captureUTCParameters() {
      const utmParameters = {
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
        utm_term: this.$route.query.utm_term,
        utm_content: this.$route.query.utm_content,
      };
      this.setUTMParameters(utmParameters);
    },
  },

  watch: {
    eventId: {
      handler: function (eventId) {
        if (eventId && ANALYTICS_IDS[eventId] !== undefined) {
          this.captureUTCParameters();
          // Vue.use(VueGtag, {
          //   pageTrackerEnabled: false,
          //   pageTrackerScreenviewEnabled: false,
          //   config: {
          //     id: `${ANALYTICS_IDS[eventId]}`,
          //     params: {
          //       send_page_view: false,
          //       ...this.utmParameters,
          //     },
          //   },
          // });

          // this.$gtag.event('ma_page_visit', {
          //   event_category: 'engagement',
          //   event_label: 'ma_page_visit',
          //   value: 1,
          // });
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("utm", ["utmParameters"]),
  },
};
