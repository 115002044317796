<template>
  <div class="language-picker columns is-multiline is-fullwidth">
    <div
      v-for="locale in availableLanguages"
      :key="locale.countryCode"
      @click="$emit('input', locale)"
      class="is-flag-button"
      :style="{ background: locale.localeCode === value.localeCode ? '#BDBDBD' : '' }"
    >
      <country-flag :code="locale.countryCode"></country-flag>
    </div>
  </div>
</template>

<script>
import CountryFlag from "@/web/components/shared/CountryFlag";

export default {
  name: "LanguagePicker",
  components: { CountryFlag },
  props: {
    availableLanguages: {
      type: Array,
      required: true,
    },

    value: {
      type: Object,
      required: true,
    },
  },

  computed: {},
};
</script>

<style scoped></style>
