<template>
  <div class="session-speaker">
    <avatar :firstName="firstName" :lastName="lastName" :picture="picture" class="avatar-speaker"></avatar>

    <div class="session-speaker-details">
      <h3 v-if="fullName">{{ fullName }}</h3>
      <h4 v-if="subName">{{ subName }}</h4>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Avatar from "@/web/components/external/Avatar";

export default {
  props: ["speaker"],

  components: {
    Avatar,
  },

  computed: {
    subName() {
      const position = this.speaker.position || "";
      const company = this.speaker.company || "";

      const segments = [position, company].map(x => x.trim()).filter(x => !!x);

      return segments.join(", ");
    },

    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },

    firstName() {
      return this.speaker.first_name;
    },

    lastName() {
      return this.speaker.last_name;
    },

    picture() {
      return this.speaker.picture;
    },
  },
};
</script>

<style lang="scss" scoped>
.session-speaker {
  margin: 0 30px 40px 30px;
  display: flex;
  align-items: center;
  font-size: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

.avatar.avatar-speaker {
  margin: 0 40px 0 0;
  flex-basis: 200px;
  width: 200px;
  height: 200px;

  @media (max-width: 767px) {
    margin: 0 0 20px 0;
  }
}

.session-speaker-details {
  h3 {
    font-size: 2.5em;
    font-weight: 600;
    color: #6784ff;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 1.5em;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 1em;
    max-width: 350px;
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1em;
    }
  }
}
</style>
