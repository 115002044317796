<template>
  <div class="social-link-list">
    <social-link v-for="(social, index) in socials" :key="index" v-bind="social"></social-link>
    <slot></slot>
  </div>
</template>

<script>
import SocialLink from "@/web/components/shared/SocialLink";

export default {
  name: "SocialLinkList",

  components: {
    SocialLink,
  },

  props: ["socials"],
};
</script>

<style lang="scss" scoped>
.social-link-list {
  margin: -8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .social-link {
    margin: 8px;
  }
}
</style>
