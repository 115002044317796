<template>
  <div>
    <b-m-meeting-list-item
      v-for="meeting in getAcceptedMeetings"
      :key="`bm-meeting-${meeting.id}`"
      :meeting="meeting"
      :current-user-uuid="currentUserUuid"
    ></b-m-meeting-list-item>
    <div v-if="!getAcceptedMeetings.length" class="placeholder-container">
      {{ $t("business_matching.business_matching_no_meetings_yet") }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BMMeetingListItem from "./BMMeetingListItem";

export default {
  name: "BMAcceptedMeetings",

  components: { BMMeetingListItem },

  computed: {
    ...mapState("currentUser", { currentUserUuid: "userUuid" }),
    ...mapGetters("businessMatchingMeetings", ["getAcceptedMeetings"]),
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}
</style>
