<template>
  <div class="list-item-content p-1 pl-2">
    <div class="media">
      <div class="media-left">
        <profile-picture class="profile-picture" :class="smallClass" :picture="picture" :initials="initials"></profile-picture>
      </div>

      <div class="media-content">
        <p v-if="name" class="title" :class="smallClass">{{ name }}</p>
        <p v-if="description" class="subtitle" :class="smallClass">{{ description }}</p>
        <slot name="title"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { fullName, initials, positionAndCompany } from "@/shared/utils";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";

export default {
  name: "InboxUserListItem",

  components: { ProfilePicture },

  props: {
    user: {
      type: Object,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    picture() {
      return this.user.picture;
    },

    name() {
      return fullName(this.user);
    },

    initials() {
      return initials(this.user);
    },

    description() {
      return positionAndCompany(this.user);
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.list-item-content {
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background: var(--side-menu-background-secondary-color);
  }

  .media {
    align-items: center;
  }

  .title {
    font-size: 18px;

    &.is-small {
      font-size: 14px;
    }
  }

  .subtitle {
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &.is-small {
      font-size: 12px;
    }
  }

  .profile-picture {
    height: 50px;
    width: 50px;
    font-size: 17px;

    &.is-small {
      width: 40px;
      height: 40px;
      font-size: 13px;
    }
  }
}
</style>
