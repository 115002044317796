<template>
  <article class="module module-iframe-native">
    <div ref="iframeTarget">
      <separator v-if="isSeparatorVisible" class="separator is-primary"></separator>
      <h2 v-if="title" class="title primary-text-color is-2 has-text-centered mb-4">{{ title }}</h2>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "IFrameNativeComponent",
  mixins: [LpConfigMixin],
  components: {
    Separator,
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this._iframeRef = null;
    this.mountIFrameElement();
  },

  beforeDestroy() {
    this.disposeIFrameElement();
  },

  methods: {
    mountIFrameElement() {
      const target = this.$refs.iframeTarget;

      this.disposeIFrameElement();
      this._iframeRef = document.createElement("iframe");
      this._iframeRef.width = "100%";
      this._iframeRef.height = 0;
      this._iframeRef.frameBorder = "0";
      this._iframeRef.style.width = "100%";
      target.appendChild(this._iframeRef);

      this.mountContent();

      // Fix async race conditioning
      this._iframeRef.onload = () => this.mountContent();
    },

    mountContent() {
      /** @type {HTMLIFrameElement} */
      const iframe = this._iframeRef;

      const html = this.html;
      iframe.contentDocument.body.innerHTML = html;

      const styles = iframe.contentDocument.createElement("style");
      styles.innerHTML = "html, body { margin: 0; }";
      iframe.contentDocument.head.appendChild(styles);

      const script = iframe.contentDocument.createElement("script");
      script.innerHTML = this.script;
      iframe.contentDocument.head.appendChild(script);

      clearInterval(this._resizeRef);
      this._resizeRef = setInterval(() => {
        const innerHeight = iframe.contentDocument && iframe.contentDocument.body && iframe.contentDocument.body.offsetHeight;
        if (innerHeight) {
          iframe.height = innerHeight;
          iframe.style.height = `${innerHeight}px`;
        }
      }, 500);
    },

    disposeIFrameElement() {
      if (this._iframeRef) {
        this._iframeRef.remove();
        this._iframeRef = null;
      }
    },
  },

  computed: {
    iFrameNativeModuleFields: () => LpConfigConstants.IFRAME_NATIVE_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    script() {
      return this.getCustomTranslation(this.moduleData, this.iFrameNativeModuleFields.SCRIPT);
    },

    html() {
      return this.getCustomTranslation(this.moduleData, this.iFrameNativeModuleFields.HTML);
    },

    isSeparatorVisible() {
      return !this.moduleData[this.commonModuleFields.SEPARATOR_DISABLED];
    },
  },

  watch: {
    script() {
      this.mountIFrameElement();
    },
    html() {
      this.mountIFrameElement();
    },
  },
};
</script>
