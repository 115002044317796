export const CHECKOUT_STEPS = {
  CART: 1,
  DETAILS: 2,
  PAYMENT: 3,
  CONFIRMATION: 4,
};

export const ECOMMERCE_ACTIONS = {
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  PURCHASE: "purchase",
};

export const CUSTOM_ACTIONS = {
  CHECKOUT_PROGRESS: "checkout_progress"
};

// const TAX_RATE = 0.23;
//
// function calculatePriceWithoutTax(priceWithTax) {
//   return Math.round(priceWithTax / (1 + TAX_RATE));
// }
//
// function formatPrice(priceInCents) {
//   return (priceInCents / 100).toFixed(2);
// }

function initializeDataLayer() {
  window.dataLayer = window.dataLayer || [];
}

function pushToDataLayer(data) {
  initializeDataLayer();
  window.dataLayer.push(data);
}

// Custom checkout step tracking
export function pushCheckoutStep(stepNumber, stepName = '') {
  pushToDataLayer({
    event: CUSTOM_ACTIONS.CHECKOUT_PROGRESS,
    checkout_step: stepNumber,
    checkout_option: stepName,
    ecommerce: null,
  });
}

export function pushAddToCart(item) {
  pushToDataLayer({
    event: ECOMMERCE_ACTIONS.ADD_TO_CART,
    ecommerce: {
      currency: "PLN",
      value: item.price * item.quantity,
      items: [item]
    },
  });
}

export function pushRemoveFromCart(item) {
  pushToDataLayer({
    event: ECOMMERCE_ACTIONS.REMOVE_FROM_CART,
    ecommerce: {
      currency: "PLN",
      value: item.price * item.quantity,
      items: [item]
    },
  });
}

export function pushBeginCheckout(purchaseData, transactionId) {
  if (!purchaseData.items?.length) {
    console.error('items array is required for begin_checkout event');
    return;
  }

  // Calculate total value without tax
  const value = purchaseData.items.reduce((total, item) => {
    const price = parseFloat(item.price) || 0;
    const quantity = item.quantity || 1;
    return total + (price * quantity);
  }, 0);

  pushToDataLayer({
    event: ECOMMERCE_ACTIONS.BEGIN_CHECKOUT,
    ecommerce: {
      currency: "PLN",
      payment_type: "AutoPay",
      transaction_id: transactionId,
      value: value,
      tax: Number((value * TAX_RATE).toFixed(2)),
      items: purchaseData.items,
      ...(purchaseData.coupon && { coupon: purchaseData.coupon })
    }
  });
}

export function pushPurchase(transactionId) {
  if (!transactionId) {
    console.error('transaction_id is required for purchase event');
    return;
  }

  pushToDataLayer({
    event: ECOMMERCE_ACTIONS.PURCHASE,
    ecommerce: {
      currency: "PLN",
      transaction_id: transactionId,
    }
  });
}



// Diff calculator
function calculateCartDiff(oldData, newData) {
  if (!oldData?.items || !newData?.items) return {added: [], removed: []};

  const oldItemsMap = new Map(oldData.items.map(item => [item.item_id, item]));
  const newItemsMap = new Map(newData.items.map(item => [item.item_id, item]));

  // Items added or increased quantity
  const added = newData.items.reduce((acc, newItem) => {
    const oldItem = oldItemsMap.get(newItem.item_id);
    const oldQuantity = oldItem ? oldItem.quantity || ((oldItem.quantity_with_discount || 0) + (oldItem.quantity_without_discount || 0)) : 0;
    const newQuantity = newItem.quantity || ((newItem.quantity_with_discount || 0) + (newItem.quantity_without_discount || 0));

    if (!oldItem || newQuantity > oldQuantity) {
      acc.push({
        item: newItem,
        quantityDiff: newQuantity - oldQuantity,
      });
    }
    return acc;
  }, []);

  // Items removed or decreased quantity
  const removed = oldData.items.reduce((acc, oldItem) => {
    const newItem = newItemsMap.get(oldItem.item_id);
    const oldQuantity = (oldItem.quantity_with_discount || 0) + (oldItem.quantity_without_discount || 0);
    const newQuantity = newItem ? (newItem.quantity_with_discount || 0) + (newItem.quantity_without_discount || 0) : 0;

    if (!newItem || oldQuantity > newQuantity) {
      acc.push({
        item: oldItem,
        quantityDiff: oldQuantity - newQuantity,
      });
    }
    return acc;
  }, []);

  return {added, removed};
}

// Mapper for analytics format
function mapToAnalyticsFormat(item, quantityDiff) {
  return {
    item_id: item.item_id,
    item_name: item.item_name,
    item_category: item.item_category,
    price: formatPrice(calculatePriceWithoutTax(item.final_price_with_discount)),
    quantity: quantityDiff,
    ...(item.discount?.discount_code && { coupon: item.discount.discount_code }),
    ...(item.final_price_with_discount && {
      discount: formatPrice(calculatePriceWithoutTax(item.final_price_without_discount - item.final_price_with_discount)),
    }),
  };
}

function mapToAnalyticsFormat2(item) {
  if (item.item_category === "Ticket") {
    const items = [];

    // Add discounted tickets if any exist
    if (item.quantity_with_discount > 0) {
      items.push({
        item_id: item.item_id,
        item_name: item.item_name,
        item_category: item.item_category,
        price: formatPrice(calculatePriceWithoutTax(item.final_price_with_discount)),
        quantity: item.quantity_with_discount,
        ...(item.discount_code && { coupon: item.discount_code }),
        discount: formatPrice(calculatePriceWithoutTax(item.discount_amount)),
      });
    }

    // Add non-discounted tickets if any exist
    if (item.quantity_without_discount > 0) {
      items.push({
        item_id: item.item_id,
        item_name: item.item_name,
        item_category: item.item_category,
        price: formatPrice(calculatePriceWithoutTax(item.final_price_without_discount)),
        quantity: item.quantity_without_discount,
        discount: 0,
      });
    }

    return items;
  }

  // Handle addon items (single item returned)
  return [{
    item_id: item.item_id,
    item_name: item.item_name,
    item_category: item.item_category,
    price: formatPrice(calculatePriceWithoutTax(item.price)),
    quantity: item.quantity,
    ...(item.force_zero_price && { price: 0 }),
  }];
}

const TAX_RATE = 0.23;

// Helper functions for price calculations
function calculatePriceWithoutTax(priceWithTax) {
  return Math.round(priceWithTax / (1 + TAX_RATE));
}

function formatPrice(priceInCents) {
  return (priceInCents / 100);
}

export { calculatePriceWithoutTax, formatPrice, mapToAnalyticsFormat, mapToAnalyticsFormat2, calculateCartDiff };
