<template>
  <div class="mb-6">
    <div class="container">
      <div class="mt-5">
        <b-steps :has-navigation="false" :animated="false" mobile-mode="compact" :size="'is-xsmall'" v-model="currentStep">
          <b-step-item :label="$t('payment.personal_data')" :clickable="clickable">
            <purchase-first-step :visible="currentStep === 0" ref="firstStep" @next="next"></purchase-first-step>
          </b-step-item>

          <b-step-item :label="$t('payment.organizational_data')" :clickable="clickable" v-if="!secondStepInvisible">
            <purchase-second-step @next="next" @previous="previous"></purchase-second-step>
          </b-step-item>

          <b-step-item :label="paymentStepTitle" :clickable="clickable">
            <purchase-payment-step
              @next="next"
              @previous="previous"
              @show-confirmation="showConfirmation"
              @email-errors="showEmailErrors"
            ></purchase-payment-step>
          </b-step-item>

          <b-step-item :label="$t('payment.summary')" :clickable="clickable">
            <purchase-final-step :visible="currentStep === lastStep" @restart="restart"></purchase-final-step>
          </b-step-item>
        </b-steps>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import PurchaseFirstStep from "@/web/components/purchase/PurchaseFirstStep";
import PurchaseSecondStep from "@/web/components/purchase/PurchaseSecondStep";
import PurchasePaymentStep from "@/web/components/purchase/PurchasePaymentStep";
import PurchaseFinalStep from "@/web/components/purchase/PurchaseFinalStep";
import { Constants } from "@/web/constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import {
  calculateCartDiff,
  CHECKOUT_STEPS,
  mapToAnalyticsFormat,
  pushAddToCart,
  pushCheckoutStep,
  pushRemoveFromCart,
} from "@/web/services/analytics";

export default {
  name: "BuyTicket",

  mixins: [LpConfigMixin],

  components: {
    PurchaseFirstStep,
    PurchaseSecondStep,
    PurchasePaymentStep,
    PurchaseFinalStep,
  },

  props: ["ticketId", "confirmation"],

  data() {
    return {
      clickable: this.ticketId === "confirmation" ? false : void 0,
      currentStep: 0,
    };
  },

  mounted() {
    this.preparePayment();
    if (this.isReallyConfirmation) {
      this.currentStep = this.lastStep;
    } else {
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.DISPLAY_SCREEN, {
      //   [Constants.ANALYTICS_PARAMETERS.VIEW_TAG]: ViewTags.PURCHASE_TICKET_STEP_FIRST,
      //   [Constants.ANALYTICS_PARAMETERS.TICKET_ID]: this.ticketId,
      // });
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.PURCHASE_EVENT_TICKET_INIT, {
      //   [Constants.ANALYTICS_PARAMETERS.TICKET_ID]: this.ticketId,
      // });
    }
  },

  methods: {
    ...mapActions(["preparePayment"]),
    ...mapActions("eventTickets", ["fetch"]),

    next() {
      this.currentStep++;
    },

    previous() {
      this.currentStep--;
    },

    restart() {
      this.currentStep = 0;
      this.$router.push({ name: Constants.ROUTE_BUY_TICKET, params: { ticketId: this.$route.params.ticketId } });
    },

    showConfirmation() {
      this.currentStep = this.lastStep;
      this.clickable = false;
    },

    showEmailErrors(errors) {
      this.currentStep = 0;
      this.$refs.firstStep.showEmailErrors(errors);
    },
  },

  computed: {
    ...mapGetters("auth", ["isJoined"]),
    ...mapState(["eventId"]),
    ...mapGetters(["paymentConfig"]),
    ...mapGetters("userTickets", ["ticket"]),
    ...mapGetters("eventTickets", ["getById", "areAllFree"]),
    ...mapGetters("payment", ["dataLayerPurchaseData"]),

    isPismEvent() {
      return this.eventId === 1312;
    },

    isMebleEvent() {
      return this.eventId === 1343 || this.eventId === 245;
    },

    isReallyConfirmation() {
      return this.confirmation === true || this.ticketId === "confirmation" || this.currentStep === this.lastStep;
    },

    lastStep() {
      if (this.secondStepInvisible) {
        return 2;
      } else {
        return 3;
      }
    },

    secondStepInvisible() {
      return this.getFieldValueOrDefault(this.paymentConfig, LpConfigConstants.PAYMENT_FIELDS.DISABLE_ORGANIZATIONAL_DATA_STEP, false);
    },

    paymentStepTitle() {
      if (this.isMebleEvent) {
        return this.$t("custom.meble_invoice_data");
      } else if (this.isPismEvent) {
        return this.$t("custom.pism_2023_consents");
      } else if (this.areAllFree) {
        return this.$t("ticket.free_ticket_consents");
      } else {
        return this.$t("payment.payment_details");
      }
    },
  },

  watch: {
    isJoined: {
      handler(newValue, oldValue) {
        if (oldValue && !newValue) {
          this.$router.replace({ name: Constants.ROUTE_MAIN_INTRODUCTION });
        }
      },
    },
    ticket: {
      handler(value) {
        if (value && !this.isReallyConfirmation) {
          this.$router.push({ name: Constants.ROUTE_MAIN_INTRODUCTION });
        } else if (value && this.isReallyConfirmation) {
          // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.PURCHASE_EVENT_TICKET_FINISH, {
          //   [Constants.ANALYTICS_PARAMETERS.TICKET_ID]: this.ticketId,
          // });
        }
      },
      immediate: true,
    },

    dataLayerPurchaseData: {
      immediate: true,
      handler: function (newData, oldData) {
        if (!newData) return;

        // First item added to cart
        if (!oldData && newData.items?.length) {
          newData.items.forEach(item => {
            const quantity = (item.quantity_with_discount || 0) + (item.quantity_without_discount || 0);
            pushAddToCart(mapToAnalyticsFormat(item, quantity));
          });
          return;
        }

        if (JSON.stringify(newData) === JSON.stringify(oldData)) return;

        const { added, removed } = calculateCartDiff(oldData, newData);
        // Handle added items
        added.forEach(({ item, quantityDiff }) => {
          pushAddToCart(mapToAnalyticsFormat(item, quantityDiff));
        });

        // Handle removed items
        removed.forEach(({ item, quantityDiff }) => {
          pushRemoveFromCart(mapToAnalyticsFormat(item, quantityDiff));
        });
      },
    },

    currentStep: {
      immediate: false,
      handler: function (value) {
        if (value === undefined) return;

        let viewTag;

        if (value === 0) {
          viewTag = `Step ${CHECKOUT_STEPS.CART}: Cart`;
        } else if (value === 1) {
          if (this.secondStepInvisible) {
            viewTag = `Step ${CHECKOUT_STEPS.PAYMENT}: Payment`;
          } else {
            viewTag = `Step ${CHECKOUT_STEPS.DETAILS}: Details`;
          }
        } else if (value === 2) {
          if (this.secondStepInvisible) {
            viewTag = `Step ${CHECKOUT_STEPS.CONFIRMATION}: Confirmation`;
          } else {
            viewTag = `Step ${CHECKOUT_STEPS.PAYMENT}: Payment`;
          }
        } else {
          viewTag = `Step ${CHECKOUT_STEPS.CONFIRMATION}: Confirmation`;
        }

        pushCheckoutStep(value + 1, viewTag);
      },
    },
  },
};
</script>
