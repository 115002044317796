<template>
  <div :class="{ 'modal-card': isModal }">
    <header :class="{ 'modal-card-head': isModal }" v-if="isModal">
      <h2 class="modal-card-title" v-if="!magicLinkSent">{{ $t("auth.login_title") }}</h2>
      <button class="delete" type="button" @click="$parent.close"></button>
    </header>

    <section :class="{ 'modal-card-body': isModal, 'mt-2': !isModal }">
      <template v-if="magicLinkSent">
        <magic-link-confirmation class="mt-4"></magic-link-confirmation>
        <button class="button is-primary has-fullwidth mt-4" @click="showPasswordLogin">
          {{ $t("auth.magic_link_return_button") }}
        </button>
      </template>
      <magic-link-form v-else-if="showMagicLinkForm" class="mt-3" :show-progress="isRequesting" @submit="magicLinkLogin"></magic-link-form>
      <login-form v-else :showProgress="isRequesting" @submit="userLogin"></login-form>
      <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>

    <footer :class="{ 'modal-card-foot': isModal, 'pt-3': !isModal }">
      <p class="has-text-centered mb-3" v-if="!magicLinkSent">
        <i18n path="auth.dont_have_an_account" tag="span" class="auth-footer-text">
          <a @click.prevent="openRegister">
            {{ $t("auth.dont_have_an_account_substring") }}
          </a>
        </i18n>
        <br />
        <i18n path="auth.forgot_password" tag="span" class="auth-footer-text">
          <a @click.prevent="openForgotPassword">{{ $t("auth.reset_password") }}</a>
        </i18n>
      </p>

      <div class="has-text-centered mb-3" v-if="!magicLinkSent">
        <button class="button is-link is-rounded" style="height: 50px" @click="toggleForms">
          <password-icon
            v-if="showMagicLinkForm"
            class="svg-icon has-fill-primary-inverted mr-1"
            style="padding-bottom: 4px; width: 24px; height: 24px"
          ></password-icon>
          <magic-stars-icon
            v-else
            class="svg-icon has-fill-primary-inverted mr-1"
            style="padding-bottom: 4px; width: 24px; height: 24px"
          ></magic-stars-icon>
          <span>{{ toggleButtonText }}</span>
        </button>
      </div>
      <!-- Social login -->
<!--      <social-login-footer v-if="socialLoginVisible && !magicLinkSent" @facebook="facebookLogin" @apple="appleLogin">-->
<!--        <i18n path="auth.sign_using_these_services" tag="span" class="auth-footer-text">-->
<!--          <strong class="has-text-primary">{{ $t("auth.already_have_an_account_substring") }}</strong>-->
<!--        </i18n>-->
<!--      </social-login-footer>-->
    </footer>
  </div>
</template>

<script>
import Constants from "@/web/constants";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import PasswordIcon from "@/assets/auth/icon_password.svg";
import MagicStarsIcon from "@/assets/auth/icon_magic_stars.svg";
import LoginForm from "@/web/components/forms/LoginForm";
import SocialLoginFooter from "@/web/components/shared/SocialLoginFooter";
import MagicLinkForm from "@/web/components/forms/MagicLinkForm";
import MagicLinkConfirmation from "@/web/components/magiclink/MagicLinkConfirmation";
import RequestMixin from "@/shared/mixins/RequestMixin";
import EmailConfirmationModal from "@/web/views/modals/EmailConfirmationModal";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import MissingSystemConsentsModal from "@/web/views/modals/MissingSystemConsentsModal";

export default {
  name: "LoginModal",
  mixins: [RequestMixin, RedirectMixin],

  components: {
    LoginForm,
    // SocialLoginFooter,
    MagicLinkForm,
    MagicLinkConfirmation,
    PasswordIcon,
    MagicStarsIcon,
  },

  props: {
    isModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      lastEmail: "",
      showMagicLinkForm: false,
      magicLinkSent: false,
    };
  },

  mounted() {
    if (this.socialLoginVisible) {
      this.initGoogleSignIn();
    }
    if(this.isAuthorized && this.isJoined) {
      this.close();
    }
  },

  requests: {
    async userLogin(credentials) {
      this.lastEmail = credentials["email"];
      this.openAuthLoadingModal();
      await this.login({ credentials });
      this.afterLogin();
    },

    async googleLogin() {
      this.openAuthLoadingModal();
      await this.loginViaGoogle(false);
      this.afterLogin();
    },

    async appleLogin() {
      await this.requestAppleToken();
      this.openAuthLoadingModal();
      await this.loginViaApple({ gdprConsent: false });
      this.afterLogin();
    },

    async facebookLogin() {
      await this.requestFacebookToken();
      this.openAuthLoadingModal();
      await this.loginViaFacebook(false);
      this.afterLogin();
    },

    async magicLinkLogin(credentials) {
      await this.sendMagicLink(credentials.email);
      this.magicLinkSent = true;
    },
  },

  computed: {
    ...mapGetters(["socialLoginVisible"]),
    ...mapState("google", ["googleToken"]),
    ...mapGetters("auth", ["isAuthorized", "isJoined"]),

    toggleButtonText() {
      if (this.showMagicLinkForm) {
        return this.$t("auth.login_with_password_button");
      } else {
        return this.$t("auth.magic_link_button");
      }
    },
  },

  methods: {
    ...mapActions([
      "login",
      "requestFacebookToken",
      "requestAppleToken",
      "loginViaFacebook",
      "loginViaGoogle",
      "loginViaApple",
      "sendMagicLink",
    ]),
    ...mapMutations("auth", ["setAuthLoadingStatus"]),
    ...mapActions("currentUser", ["checkIsUserMetaSubmitted"]),
    ...mapActions("google", ["initGoogleSignIn"]),

    toggleForms() {
      this.showMagicLinkForm = !this.showMagicLinkForm;
    },

    showPasswordLogin() {
      this.showMagicLinkForm = false;
      this.magicLinkSent = false;
    },

    openGdprConsentModal() {
      this.$root.openGdprConsentModal(this.$props);
    },

    openAuthLoadingModal() {
      this.$root.openAuthLoadingModal();
    },

    openMissingSystemConsentsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: MissingSystemConsentsModal,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
      });
      // this.$root.openMissingSystemConsentsModal();
    },

    openEmailConfirmationModal() {
      this.$buefy.modal.open({
        parent: this,
        component: EmailConfirmationModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          email: this.lastEmail,
        },
      });
    },

    async afterLogin() {
      if (this.isJoined) {
        const isMetaDataSubmitted = await this.checkIsUserMetaSubmitted();
        if (isMetaDataSubmitted) {
          this.close();
        } else {
          this.$root.openUserSpecificFieldsModal();
          if (this.isModal) {
            this.$parent.close();
          }
        }
      } else {
        this.openWhitelist();
      }
    },

    openRegister() {
      if (this.isModal) {
        this.$root.openRegisterModal(this.$props);
        this.close();
      } else {
        const params = {
          query: this.$route.query,
        };
        this.$router.push({ name: Constants.ROUTE_REGISTER, params });
      }
    },

    openForgotPassword() {
      if (this.isModal) {
        this.$root.openForgotPasswordModal(this.$props);
        this.close();
      } else {
        const params = {
          query: this.$route.query,
        };
        this.$router.push({ name: Constants.ROUTE_FORGOT_PASSWORD, params });
      }
    },

    openWhitelist() {
      this.$root.openWhitelistModal();
    },

    close() {
      if (this.isModal) {
        this.$parent.close();
      } else {
        const defaultRoute = { name: Constants.ROUTE_MAIN_INTRODUCTION };
        this.nextRedirect(defaultRoute);
      }
    },
  },

  watch: {
    googleToken: {
      immediate: false,
      handler: function (newValue) {
        if (newValue) {
          this.googleLogin();
        }
      },
    },

    // isAuthorized: {
    //   immediate: true,
    //   handler: function (newValue) {
    //     if (newValue) {
    //       this.close();
    //     }
    //   },
    // },

    error(newVal) {
      if (newVal) {
        this.setAuthLoadingStatus(Constants.AUTH_LOADING_STATUSES.ERROR);
        if (newVal.response.data.errors && newVal.response.data.errors.length) {
          const errorCode = newVal.response.data.errors[0].code;
          if (errorCode === Constants.REST_NO_GDPR_ERROR) {
            this.openGdprConsentModal();
          } else if (errorCode === Constants.REST_SIGN_IN_EMAIL_NEEDS_TO_BE_CONFIRMED) {
            this.openEmailConfirmationModal();
            this.error = null;
          } else if (errorCode === Constants.REST_MISSING_SYSTEM_CONSENTS_ERROR) {
            this.openMissingSystemConsentsModal();
            this.error = null;
          } else if(errorCode === Constants.REST_WHITE_LIST_ERROR) {
            this.openWhitelist();
            this.error = null;
          } else {
            this.$root.openErrorModal(newVal);
            this.error = null;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-card-body {
  overflow: visible !important;
}
</style>
