import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";
import ExhibitorUpdateContactPersonTranslationModel from "@/web/store/models/ExhibitorUpdateContactPersonTranslationModel";

export default class ExhibitorUpdateContactPersonModel extends Model {
  static entity = "exhibitor_update_contact_persons";

  static fields() {
    return {
      id: this.attr(null),
      order: this.number(-1),
      first_name: this.string(""),
      last_name: this.string(""),
      description: this.string("").nullable(),
      phone: this.string("").nullable(),
      phone_country_code: this.string("").nullable(),
      email: this.string("").nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      translation_ids: this.attr([]),
      translations: this.hasManyBy(ExhibitorUpdateContactPersonTranslationModel, "translation_ids"),
    };
  }
}
