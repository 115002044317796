<template>
  <div class="fw-thread-list-item" :class="isSmallClass">
    <div class="columns is-mobile is-gapless mb-0 is-vcentered">
      <div class="column is-narrow">
        <profile-picture
          :picture="avatar"
          :initials="userInitials"
          class="fw-thread-avatar"
          :class="isSmallClass"
          @click.native.stop="openUserProfile"
        ></profile-picture>
      </div>
      <div class="column">
        <div class="fw-thread-title-container" :class="isSmallClass">
          <div class="is-flex">
            <div class="fw-thread-title" :class="isSmallClass">
              {{ title }}
            </div>
          </div>
          <div class="fw-thread-subtitle" :class="isSmallClass">
            {{ userPosition }}
          </div>
        </div>
      </div>
      <div class="column is-narrow is-flex">
        <div class="fw-thread-date" :class="isSmallClass">
          {{ date }}
        </div>
      </div>
    </div>
    <div class="fw-thread-message" :class="isSmallClass">
      <read-more-text :text="message"></read-more-text>
    </div>
    <div class="fw-thread-buttons-container" :class="isSmallClass">
      <heart-icon-filled
        class="fw-thread-like-button"
        v-if="isLikedByMe"
        :class="isSmallClass"
        @click.stop="onLikeButtonClick"
      ></heart-icon-filled>
      <heart-icon class="fw-thread-like-button" v-else :class="isSmallClass" @click.stop="onLikeButtonClick"></heart-icon>
      <div class="fw-thread-likes" :class="isSmallClass" @click.stop="onLikeButtonClick">{{ likesCounter }}</div>
      <template v-if="isAdmin && isModerated">
        <button class="button is-primary is-outlined moderator-button mr-1 ml-auto" @click="acceptQuestion">
          {{ $t("session_questions.moderator_accept") }}
        </button>
        <button class="button is-danger is-outlined moderator-button mr-auto" @click="removeQuestion">
          {{ $t("session_questions.moderator_reject") }}
        </button>
      </template>
      <b-dropdown aria-role="menu" class="ml-auto" position="is-top-left" trap-focus v-if="isFromCurrentUser || isAdmin">
        <template #trigger>
          <b-button class="is-borderless fw-thread-dots-button" size="is-small">
            <div class="is-flex is-align-items-center is-justify-content-center">
              <dots-icon class="fw-thread-dots-icon"></dots-icon>
            </div>
          </b-button>
        </template>
        <b-dropdown-item aria-role="listitem" @click="removeQuestion">{{ $t("feedwall.feedwall_delete_post") }} </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { initials, positionAndCompany, fullName, composeElapsedTime } from "@/shared/utils";
import { pluckErrorCode } from "@/shared/utils";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import HeartIcon from "@/assets/feedwall/icon_heart.svg";
import HeartIconFilled from "@/assets/feedwall/icon_heart_filled.svg";
import DotsIcon from "@/assets/icons/3_dots.svg";
import ReadMoreText from "@/web/components/shared/ReadMoreText";
import Constants from "@/web/constants";

export default {
  name: "AgendaBubbleQuestion",

  components: { ProfilePicture, HeartIcon, HeartIconFilled, DotsIcon, ReadMoreText },

  props: {
    question: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    componentId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters("currentUser", {
      currentUserUuid: "uuid",
    }),
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters(["currentTime"]),

    agendaSessionId() {
      return this.question.agenda_session_id;
    },

    questionId() {
      return this.question.id;
    },

    message() {
      return this.question.question;
    },

    date() {
      if (this.currentTime) {
        return composeElapsedTime(this.question.created_at, this.$i18n);
      } else {
        return "";
      }
    },

    avatar() {
      if (this.isAnonymous) {
        return null;
      } else {
        return this.question.user.picture;
      }
    },

    title() {
      if (this.isAnonymous) {
        return this.$t("session_questions.session_question_anonymous_post_title");
      } else {
        return fullName(this.question.user);
      }
    },

    userInitials() {
      if (this.isAnonymous) {
        return "";
      } else {
        return initials(this.question.user);
      }
    },

    userPosition() {
      if (this.isAnonymous) {
        return "";
      } else {
        return positionAndCompany(this.question.user);
      }
    },

    userUuid() {
      return this.question.user && this.question.user.user_identity_token;
    },

    isFromCurrentUser() {
      return this.userUuid === this.currentUserUuid;
    },

    isAnonymous() {
      return !this.question.user;
    },

    isLikedByMe() {
      return this.question.liked_by_me;
    },

    likesCounter() {
      return this.question.likes;
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },

    isModerated() {
      return !this.question.accepted_by_moderator;
    },
  },

  methods: {
    ...mapActions("agendaSessionQuestions", ["deleteQuestion", "approveQuestion", "likeQuestion", "unlikeThread"]),

    removeQuestion() {
      this.deleteQuestion({
        componentId: this.componentId,
        agendaSessionId: this.agendaSessionId,
        questionId: this.questionId,
        isFromCurrentUser: this.isFromCurrentUser,
        isAdmin: this.isAdmin,
      }).catch(error => {
        const errorCode = pluckErrorCode(error);
        if (errorCode === Constants.NOT_AUTHORIZED) {
          const title = this.$t("roles.not_allowed_dialog_title");
          const message = this.$t("roles.not_allowed_dialog_desc");
          this.$root.openInfoModal({ title, message });
        }
      });
    },

    acceptQuestion() {
      this.approveQuestion({
        componentId: this.componentId,
        agendaSessionId: this.agendaSessionId,
        questionId: this.questionId,
      }).catch(error => {
        const errorCode = pluckErrorCode(error);
        if (errorCode === Constants.NOT_AUTHORIZED) {
          const title = this.$t("roles.not_allowed_dialog_title");
          const message = this.$t("roles.not_allowed_dialog_desc");
          this.$root.openInfoModal({ title, message });
        }
      });
    },

    onLikeButtonClick() {
      if (this.isLikedByMe) {
        this.unlikeThread({
          componentId: this.componentId,
          agendaSessionId: this.agendaSessionId,
          questionId: this.questionId,
        });
      } else {
        this.likeQuestion({
          componentId: this.componentId,
          agendaSessionId: this.agendaSessionId,
          questionId: this.questionId,
        });
      }
    },

    openUserProfile() {
      if (!this.isAnonymous) {
        this.$root.openUserModal(this.userUuid);
      }
    },
  },
};
</script>

<style scoped>
.moderator-button {
  height: 30px;
  font-size: 12px;
}
</style>
