import dayjs from "@/shared/utils/day";

export const state = () => ({
  childrenCount: 0,
  childForms: [],
});

export const mutations = {
  setChildrenCount(state, childrenCount) {
    state.childrenCount = childrenCount;
    state.childForms = state.childForms.slice(0, childrenCount);
  },

  setChildrenForm(state, childForm) {
    state.childForms[childForm.id] = childForm;
    state.childForms = [...state.childForms];
  },
};

export const getters = {
  getChildForm: state => childIndex => {
    return state.childForms[childIndex] || { id: childIndex };
  },

  getAvailableSlots(state, getters, rootState, rootGetters) {
    const eventTickets = rootGetters["eventTickets/getAllTicketsFromModule"];
    const eventTimezone = rootState.timezone;
    // format("dddd DD.MM")
    return eventTickets.reduce((acc, ticket, index) => {
      if (ticket.tickets_left > 0 || ticket.tickets_left === null) {
        const categoryWithDate = ticket.name.split(".");
        const category = categoryWithDate[0];
        const dateString = dayjs(categoryWithDate[1]).tz(eventTimezone).startOf("day").toISOString();

        if (category in acc) {
          if (dateString in acc[category]) {
            acc[category][dateString] = [...acc[category][dateString], ticket];
          } else {
            acc[category][dateString] = [ticket];
          }
        } else {
          acc[category] = {
            [dateString]: [ticket],
          };
        }
      }
      return acc;
    }, {});
  },
};

export const actions = {
  async register({ dispatch, rootState, rootGetters, state, getters }) {
    let childrenForms = state.childForms;
    const eventId = rootState["eventId"];
    const currentUserEmail = rootGetters["currentUser/email"];
    const firstChildForm = childrenForms.slice(0, 1)[0];
    const currentUserSpecificFields = mapFormToSpecificFields(firstChildForm, currentUserEmail);
    currentUserSpecificFields.srk_children_json = mapChildrenFormsToJsonString(childrenForms);
    childrenForms = childrenForms.slice(1, childrenForms.length);
    const guestsForms = mapChildrenFormsToGuestTicket(childrenForms, currentUserEmail);
    await dispatch(
      "payment/processFreePayment",
      { eventId, ticketId: firstChildForm.ticket.id, discountCode: null, guestsTickets: guestsForms, currentUserSpecificFields },
      { root: true }
    );
  },
};

function mapChildrenFormsToGuestTicket(childrenForms, currentUserEmail) {
  return childrenForms.map(form => {
    return {
      email: form.childName.replace(/\s+/g, "_") + currentUserEmail,
      first_name: form.childName,
      last_name: form.childName,
      ticket_id: form.ticket.id,
      specific_fields: mapFormToSpecificFields(form, currentUserEmail),
    };
  });
}

function mapChildrenFormsToJsonString(childrenForms) {
  const jsonObjectsArray = childrenForms.map(form => {
    return {
      childName: form.childName,
      ticketName: form.ticket.name,
    };
  });
  return JSON.stringify(jsonObjectsArray);
}

function mapFormToSpecificFields(form, currentUserEmail) {
  return {
    srk_parent_email: currentUserEmail,
    srk_child_name: form.childName,
    srk_child_category: form.category,
    srk_child_slot: form.ticket.name.split(".")[1],
  };
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
