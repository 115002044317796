<template>
  <div class="placeholder-container">
    {{ $t("ticket.empty_tickets") }}
  </div>
</template>

<script>
export default {
  name: "EmptyTicketListView",
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
