import axios from "@/web/services/axios";
import { Constants } from "@/web/constants";

export const types = {
  LOAD_DATA: "loadThLeaderboard",
  LOAD_DATA_SUCCESS: "loadThLeaderboardSuccess",
  LOAD_DATA_ERROR: "loadThLeaderboardError",
};

export const state = {
  status: null,
  error: null,
  leaderboard: [],
  componentName: "",
};

export const getters = {
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  hasLoadingError(state) {
    return state.status === Constants.STATUS_ERROR;
  },
};

export const mutations = {
  [types.LOAD_DATA](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_DATA_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_DATA_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
  setLeaderboard(state, treasureHunt) {
    let scores = treasureHunt.scores;
    state.leaderboard = scores.sort((a, b) => b.points - a.points || new Date(a.last_response_date) - new Date(b.last_response_date));
    state.componentName = treasureHunt.name;
  },
};

export const actions = {
  async loadLeaderboard({ commit }, uuid) {
    const url = `/treasure_hunt_leaderboard_external/${uuid}`;

    return axios.get(url).then(
      response => {
        const leaderboardRows = response.data;
        commit("setLeaderboard", leaderboardRows);
        commit(types.LOAD_DATA_SUCCESS);
        return Promise.resolve(leaderboardRows);
      },
      err => {
        commit(types.LOAD_DATA_ERROR, err);
        return Promise.reject(err);
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
