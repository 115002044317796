<template>
  <div class="exhibitor-update-list-item">
    <div class="is-flex is-flex-direction-column is-flex-grow-1">
      <i18n path="moderator.exhibitor_booth_update" tag="div" class="exhibitor-update-list-item-title">
        <span :class="statusClass">{{ title }}</span>
      </i18n>
      <i18n path="moderator.update_status_created_time" tag="div" class="exhibitor-update-list-item-time">
        <span>{{ createdAt }}</span>
      </i18n>
      <i18n
        v-if="extraNote && (status === Constants.MODERATION_STATUS_REJECTED || status === Constants.MODERATION_STATUS_ACCEPTED)"
        tag="div"
        path="moderator.update_status_rejected_reason"
        class="exhibitor-update-list-item-note"
      >
        <span>
          {{ extraNote }}
        </span>
      </i18n>
    </div>

    <b-dropdown position="is-bottom-left" trap-focus can-close close-on-click v-if="buttonVisible">
      <b-button type="is-outlined" class="pl-1 pr-1" slot="trigger">
        <div class="exhibitor-update-list-item-button-icon">
          <dots-icon class="has-fill-primary"></dots-icon>
        </div>
      </b-button>
      <b-dropdown-item :has-link="true">
        <router-link
          :to="{
            name: Constants.ROUTE_MODERATOR_EXHIBITOR_UPDATE,
            params: {
              exhibitorId: exhibitorId,
              exhibitorUpdateId: exhibitorUpdateId,
            },
          }"
        >
          {{ $t("moderator.update_dropdown_show_and_edit") }}
        </router-link>
      </b-dropdown-item>
      <!--      <b-dropdown-item @click="onRemoveClick">-->
      <!--        <span class="has-text-danger" >-->
      <!--          {{ $t("moderator.update_dropdown_remove") }}-->
      <!--        </span>-->
      <!--      </b-dropdown-item>-->
    </b-dropdown>
  </div>
</template>

<script>
import { composeElapsedTimeForModeration } from "@/shared/utils";
import Constants from "@/web/constants";
import DotsIcon from "@/assets/icons/3_dots.svg";
import { mapActions } from "vuex";

export default {
  name: "ExhibitorUpdateListItem",
  components: { DotsIcon },

  props: {
    exhibitorUpdate: {
      type: Object,
      required: true,
    },
    buttonVisible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    Constants: () => Constants,

    title() {
      switch (this.status) {
        case Constants.MODERATION_STATUS_ACCEPTED:
          return this.$t("moderator.update_status_accepted");
        case Constants.MODERATION_STATUS_PENDING:
          return this.$t("moderator.update_status_pending");
        case Constants.MODERATION_STATUS_REJECTED:
          return this.$t("moderator.update_status_rejected");
        default:
          return "Unknown status";
      }
    },

    exhibitorId() {
      return this.exhibitorUpdate.exhibitor_id;
    },

    exhibitorUpdateId() {
      return this.exhibitorUpdate.id;
    },

    status() {
      return this.exhibitorUpdate.status;
    },

    statusClass() {
      switch (this.status) {
        case Constants.MODERATION_STATUS_ACCEPTED:
          return "has-text-success";
        case Constants.MODERATION_STATUS_PENDING:
          return "has-text-warning";
        case Constants.MODERATION_STATUS_REJECTED:
          return "has-text-danger";
        default:
          return "has-text-strong";
      }
    },

    extraNote() {
      return this.exhibitorUpdate.extra_note;
    },

    createdAt() {
      return composeElapsedTimeForModeration(this.exhibitorUpdate.created_at, this.$i18n);
    },
  },

  methods: {
    ...mapActions("exhibitorModerator", ["removeExhibitorUpdate"]),

    onRemoveClick() {
      this.removeExhibitorUpdate({
        componentId: this.exhibitorUpdate.exhibitor.event_component_id,
        exhibitorId: this.exhibitorUpdate.exhibitor_id,
        exhibitorUpdateId: this.exhibitorUpdate.id,
      });
    },

    navigateToExhibitorUpdate() {
      this.$router.push({
        name: Constants.ROUTE_MODERATOR_EXHIBITOR_UPDATE,
        params: {
          exhibitorId: this.exhibitorId,
          exhibitorUpdateId: this.exhibitorUpdateId,
        },
      });
    },
  },
};
</script>

<style scoped></style>
