import VueRouter from "vue-router";
import routes from "./routes";

export function createRouter() {
  return new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior,
    routes,
  });
}

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition === null) {
    if (to.hash) {
      return null;
    } else {
      return { x: 0, y: 0 };
    }
  }

  return savedPosition;
}
