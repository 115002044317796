<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div class="ped-stage-grid">
        <div class="ped-stage-card">
          <div class="ped-stage-card-header">
            <h3 class="ped-stage-card-title">
              {{ $t("custom.ped_stage_card_1_title_p1") }}<br />{{ $t("custom.ped_stage_card_1_title_p2") }}
            </h3>
          </div>
          <div class="ped-stage-img-container">
            <img class="ped-stage-img" src="@/assets/custom/ped/stage-section/ped_technology.png" alt="" />
          </div>
          <p class="ped-stage-desc">{{ $t("custom.ped_stage_card_1_desc") }}</p>
        </div>

        <div class="ped-stage-card">
          <div class="ped-stage-card-header">
            <h3 class="ped-stage-card-title">
              {{ $t("custom.ped_stage_card_2_title_p1") }}<br />{{ $t("custom.ped_stage_card_2_title_p2") }}
            </h3>
          </div>
          <div class="ped-stage-img-container">
            <img class="ped-stage-img" src="@/assets/custom/ped/stage-section/ped_brand.png" alt="" />
          </div>
          <p class="ped-stage-desc">{{ $t("custom.ped_stage_card_2_desc") }}</p>
        </div>

        <div class="ped-stage-card">
          <div class="ped-stage-card-header">
            <h3 class="ped-stage-card-title">
              {{ $t("custom.ped_stage_card_3_title_p1") }}<br />{{ $t("custom.ped_stage_card_3_title_p2") }}
            </h3>
          </div>
          <div class="ped-stage-img-container">
            <img class="ped-stage-img" src="@/assets/custom/ped/stage-section/ped_workshops.png" alt="" />
          </div>
          <p class="ped-stage-desc">{{ $t("custom.ped_stage_card_3_desc") }}</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "PedStageComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    ticketViewType() {
      return LpConfigConstants.EVENT_TICKET_VIEW_TYPES.CINEMA;
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ped-stage-grid {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ped-stage-card {
  width: calc(33.33% - 20px);
  /* min-width: 320px; */
  padding: 8px;
}

.ped-stage-card-header {
  height: 140px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ped-stage-card-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #f2f2f2;
  text-align: center;
}

.ped-stage-img-container {
  width: 100%;
}

.ped-stage-img {
  width: 100%;
  height: 100%;
}

.ped-stage-desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  color: #f2f2f2;
  text-align: center;
  margin-top: 42px;
  display: block;
  padding: 0 40px;
}

@media (max-width: 1390px) {
  .ped-stage-desc {
    padding: 0 10px;
  }
}

@media (max-width: 846px) {
  .ped-stage-card {
    width: 100%;
    padding: 20px;
  }

  .ped-stage-img-container {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
