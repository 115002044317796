<template>
  <div @click="onClick">
    <div class="thread-container">
      <div v-if="selected" class="item-selected"></div>
      <div class="columns is-mobile is-gapless is-vcentered list-item-container my-0" :class="smallClass">
        <div class="column is-narrow">
          <profile-picture :picture="picture" :initials="initials" class="user-picture" :class="smallClass"></profile-picture>
        </div>
        <div class="column is-paddingless mx-1 py-0">
          <div class="columns is-mobile is-vcentered is-gapless title-container">
            <div class="column is-paddingless title" :class="smallClass">
              {{ title }}
            </div>
            <div class="column is-paddingless is-narrow date has-text-secondary" :class="smallClass">
              {{ date }}
            </div>
          </div>
          <div class="subtitle-container">
            <div class="subtitle" :style="subtitleStyle" :class="smallClass">
              {{ subtitle }}
            </div>
            <div v-if="unreadCount" class="unread-messages-circle">
              {{ unreadCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { mapGetters } from "vuex";
import { fullName, initials } from "@/shared/utils";
import { composeElapsedTime } from "@/shared/utils/date-utils";
import Constants from "../../constants";

export default {
  name: "InboxThreadListItem",

  props: {
    thread: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    currentUserUuid: {
      type: String,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  components: { ProfilePicture },

  computed: {
    ...mapGetters("inboxMessages", ["unreadMessagesFromThread"]),

    threadUser() {
      return this.thread.users.find(user => user.user_identity_token !== this.currentUserUuid) || this.deletedUserPlaceholder;
    },

    isDeletedAccount() {
      return !this.threadUser.user_identity_token;
    },

    deletedUserPlaceholder() {
      return {
        first_name: " ",
        last_name: " ",
        picture: null,
      };
    },

    picture() {
      return this.threadUser.picture;
    },

    initials() {
      return initials(this.threadUser);
    },

    title() {
      if (this.isDeletedAccount) {
        return this.$t("inbox.inbox_deleted_user");
      } else {
        return fullName(this.threadUser);
      }
    },

    subtitle() {
      return this.thread.message.message;
    },

    subtitleStyle() {
      if (this.isLastMessageRead) {
        return {
          fontWeight: "normal",
        };
      } else {
        return {
          fontWeight: "bold",
        };
      }
    },

    unreadCount() {
      const unreadCount = this.unreadMessagesFromThread(this.thread.id, this.currentUserUuid);
      if (unreadCount > 99) {
        return "+";
      } else {
        return unreadCount;
      }
    },

    isLastMessageRead() {
      return new Date(this.thread.read_at) >= new Date(this.thread.updated_at);
    },

    date() {
      return composeElapsedTime(this.thread.message.created_at, this.$i18n);
    },

    routerLink() {
      const threadId = this.thread.id;
      return { name: Constants.ROUTE_INBOX_THREAD, params: { threadId } };
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    onClick() {
      if (this.isSmall) {
        this.$emit("thread-selected", this.thread.id);
      } else {
        this.$router.replace(this.routerLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.thread-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  background: var(--inbox-item-bg-color);

  &:hover {
    cursor: pointer;
    background: var(--inbox-item-bg-hover-color);
  }
}

.list-item-container {
  padding: 8px 10px 8px 20px;
  flex: 1 0;

  &.is-small {
    padding: 8px 10px 8px 20px;
  }
}

.item-selected {
  background: var(--primary-color);
  flex: 0 0 3px;
  width: 3px;
  margin-right: -3px;
}

.user-picture {
  width: 60px;
  height: 60px;
  font-size: 18px;

  &.is-small {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  &.is-small {
    font-size: 11px;
    line-height: 14px;
  }
}

.title-container {
  margin-bottom: 2px !important;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-small {
    font-size: 14px;
    line-height: 20px;
  }
}

.subtitle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0;

  &.is-small {
    font-size: 12px;
    line-height: 16px;
  }
}

.unread-messages-circle {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 12px;
  color: white;
}
</style>
