<template>
  <div class="mt-1">
    <div class="quiz-answer-container" :class="classes" @click.self="onClick">
      <radio-button @click.self="onClick" v-model="isChecked" :size="radioButtonSize" class="mr-1"></radio-button>
      <p @click.self="onClick" class="answer-text unselectable" :class="classes">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import RadioButton from "@/shared/components/form/RadioButton";

export default {
  name: "QuizRadio2",

  components: { RadioButton },

  props: {
    answer: {
      type: Object,
      default: null,
    },
    currentResponse: {
      type: Object,
      default: null,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isChecked: false,
    };
  },

  mounted() {
    if (this.currentResponse) {
      this.isChecked = true;
    }
  },

  computed: {
    text() {
      return this.answer.answer;
    },

    classes() {
      return {
        selected: this.isChecked,
        "is-small": this.isSmall,
      };
    },

    radioButtonSize() {
      if (this.isSmall) {
        return 20;
      } else {
        return 30;
      }
    },
  },

  methods: {
    onClick() {
      this.isChecked = !this.isChecked;
      this.$emit("uncheckOtherRadios", this.answer.id);
    },

    getResponse() {
      if (this.isChecked) {
        return { questionId: this.answer.survey_quiz_question_id, answerId: this.answer.id };
      } else {
        return null;
      }
    },

    uncheck() {
      this.isChecked = false;
    },
  },
};
</script>
