<template>
  <bubble-box
    :title="bubbleTitle"
    :is-full-height="isQuizSelected"
    :back-button-visible="isQuizSelected"
    :bubble-id="bubble.id"
    :is-active="isActive"
    :is-panel="isPanel"
    @navigate-back="navigateBack"
    @minimize="$emit('minimize', getBubbleToMinimize())"
    @close="$emit('close', bubble)"
    @active="setAsActiveBubble"
    @inactive="setAsInActiveBubble"
  >
    <template v-if="isQuizSelected">
      <quiz-in-progress :quiz-id="selectedQuizId" :is-small="true" @return="stopQuiz"></quiz-in-progress>
    </template>
    <template v-else-if="isQuizInProgress">
      <quiz-in-progress-prompt @result="onInProgressPromptResult"></quiz-in-progress-prompt>
    </template>
    <template v-else-if="isAnyLoading && !quizzes.length">
      <div class="has-fullheight is-flex is-align-items-center is-justify-content-center">
        <list-loading-indicator :active="true"></list-loading-indicator>
      </div>
    </template>
    <template v-else>
      <div class="px-1 has-fullheight">
        <div v-if="quizzes.length">
          <quizzes-list :quizzes="quizzes" @start-quiz="startQuiz"></quizzes-list>
        </div>
        <div class="placeholder-container has-text-centered has-text-secondary" v-else>
          <div class="my-auto">
            {{ placeholderText }}
          </div>
        </div>
      </div>
    </template>
  </bubble-box>
</template>

<script>
import QuizzesList from "@/web/components/quizzes/QuizzesList";
import QuizInProgressPrompt from "@/web/components/quizzes/QuizInProgressPrompt";
import QuizInProgress from "@/web/components/quizzes/QuizInProgress";
import BubbleBox from "@/web/components/sidemenu/BubbleBox";
import { mapActions, mapGetters, mapState } from "vuex";
import ListLoadingIndicator from "../../../../shared/components/common/loading/ListLoadingIndicator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "QuizBubble",
  mixins: [LpConfigMixin],
  components: { ListLoadingIndicator, QuizzesList, BubbleBox, QuizInProgress, QuizInProgressPrompt },

  props: {
    bubble: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    quizModules: {
      type: Array,
    },

    isPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedQuizId: null,
    };
  },

  mounted() {
    this.setAsActiveBubble();
    this.quizModules.forEach(module => {
      this.loadQuizzes(module.component_id);
    });
  },

  watch: {
    bubble: {
      immediate: true,
      handler: function (newVal) {
        if (newVal.quizId) {
          this.selectedQuizId = newVal.quizId;
        }
      },
    },
  },

  computed: {
    ...mapGetters("quizzes", ["getAll", "isAnyLoading"]),
    ...mapGetters("quizAnswers", ["allResponses", "currentQuizId"]),
    ...mapState(["eventId"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTitles"]),

    bubbleId() {
      return this.bubble.id;
    },

    quizzes() {
      const quizzes = this.getAll;
      return quizzes.filter(quiz => !quiz.is_completed_by_me);
    },

    placeholderText() {
      return this.$t("quizzes.quiz_uncompleted_placeholder_text");
    },

    isQuizSelected() {
      return this.selectedQuizId !== null;
    },

    isQuizInProgress() {
      return !!Object.values(this.allResponses).find(response => response !== null);
    },

    bubbleTitle() {
      const bubbleType = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.QUIZZES_BUBBLE;
      return this.getCustomTranslation(this.sideMenuBubblesTitles, bubbleType, this.$t("quizzes.quizzes_and_surveys"));
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes"]),
    ...mapActions("quizAnswers", ["clearCurrentQuiz"]),

    startQuiz(quizId) {
      this.selectedQuizId = quizId;
    },

    stopQuiz() {
      this.selectedQuizId = null;
    },

    navigateBack() {
      let navigated = false;
      if (this.selectedQuizId) {
        navigated = true;
        this.selectedQuizId = null;
        this.clearCurrentQuiz();
      }
      return navigated;
    },

    onInProgressPromptResult(result) {
      if (result) {
        this.selectedQuizId = this.currentQuizId;
      } else {
        this.clearCurrentQuiz();
      }
    },

    getBubbleToMinimize() {
      if (this.selectedQuizId) {
        return {
          id: this.bubble.id,
          type: this.bubble.type,
          quizId: this.selectedQuizId,
        };
      } else {
        return { id: this.bubble.id, type: this.bubble.type };
      }
    },

    setAsActiveBubble() {
      this.$emit("active", this.bubbleId);
    },

    setAsInActiveBubble() {
      this.$emit("inactive", this.bubbleId);
    },
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}
</style>
