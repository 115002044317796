<template>
  <bubble-box
    ref="bubbleBox"
    :title="bubbleTitle"
    :is-full-height="false"
    :back-button-visible="isAgendaSelected"
    :add-button-visible="isAgendaSelected"
    :bubble-id="bubble.id"
    :is-active="isActive"
    :background-color="backgroundColor"
    :content-padding="contentPadding"
    :v-chat-enabled="false"
    :is-initialized="true"
    :init-scroll-enabled="false"
    :is-panel="isPanel"
    @navigate-back="navigateBack"
    @minimize="$emit('minimize', getBubbleToMinimize())"
    @close="$emit('close', bubble)"
    @active="setAsActiveBubble"
    @inactive="setAsInActiveBubble"
    @add="onAddButtonClick"
    :debug="true"
  >
    <template v-if="selectedAgendaSession">
      <agenda-bubble-questions :agenda-session="selectedAgendaSession" :is-small="true"></agenda-bubble-questions>
    </template>
    <template v-else>
      <agenda-bubble-session-list
        @select-agenda-session="selectAgendaSession"
        :agenda-sessions="visibleAgendaSessions"
      ></agenda-bubble-session-list>
    </template>

    <template #fixed-header v-if="isAgendaSelected">
      <questions-filter-type-select v-prevent-parent-scroll></questions-filter-type-select>
    </template>
    <template #fixed-header v-else>
      <agenda-session-list-select v-prevent-parent-scroll></agenda-session-list-select>
    </template>

    <template #fixed-footer v-if="isPanel && selectedAgendaSession">
      <div class="side-menu-panel-thread-add-button-container">
        <button class="button is-primary side-menu-panel-thread-add-button" @click="onAddButtonClick">
          <add-icon class="side-menu-panel-thread-add-button-icon"></add-icon>
          {{ $t("session_questions.add_question_title") }}
        </button>
      </div>
    </template>
  </bubble-box>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SideMenuBubbleMixin from "@/web/mixins/SideMenuBubbleMixin";
import BubbleBox from "@/web/components/sidemenu/BubbleBox";
import AgendaBubbleSessionList from "@/web/components/agenda/questions/AgendaBubbleSessionList";
import AgendaBubbleQuestions from "@/web/components/agenda/questions/AgendaBubbleQuestionsList";
import AgendaSessionCreateQuestionModal from "@/web/components/agenda/questions/AgendaSessionCreateQuestionModal";
import AgendaSessionListSelect from "@/web/components/agenda/questions/AgendaSessionListSelect";
import QuestionsFilterTypeSelect from "@/web/components/agenda/questions/QuestionsFilterTypeSelect";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import AddIcon from "@/assets/icons/add.svg";

export default {
  name: "AgendaSessionQuestionsBubble",
  mixins: [SideMenuBubbleMixin, LpConfigMixin],
  components: {
    AgendaSessionListSelect,
    BubbleBox,
    AgendaBubbleSessionList,
    AgendaBubbleQuestions,
    QuestionsFilterTypeSelect,
    AddIcon,
  },

  props: {
    isPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedAgendaSessionId: null,
    };
  },

  computed: {
    ...mapGetters("agendaSessionQuestions", ["getSessionById", "visibleAgendaSessions"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTitles"]),

    bubbleTitle() {
      if (this.selectedAgendaSession) {
        return this.selectedAgendaSession.name;
      } else {
        const bubbleType = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS_BUBBLE;
        return this.getCustomTranslation(this.sideMenuBubblesTitles, bubbleType, this.$t("session_questions.session_questions_title"));
      }
    },

    selectedAgendaSession() {
      return this.selectedAgendaSessionId && this.getSessionById(this.selectedAgendaSessionId);
    },

    isAgendaSelected() {
      return !!this.selectedAgendaSessionId;
    },

    backgroundColor() {
      if (this.isAgendaSelected) {
        return "var(--side-menu-feedwall-bg-color)";
      } else {
        return "var(--side-menu-feedwall-bg-secondary-color)";
      }
    },

    contentPadding() {
      if (this.isAgendaSelected) {
        return "0";
      } else {
        return "8px";
      }
    },
  },

  methods: {
    ...mapMutations("agendaSessionQuestions", ["setVisibleAgendaSessionId"]),

    navigateBack() {
      let navigated = false;
      if (this.selectedAgendaSessionId) {
        navigated = true;
        this.selectedAgendaSessionId = null;
        this.$nextTick(() => {
          this.$refs.bubbleBox.restoreScrollPosition();
          this.refreshLayout();
        });
      }
      return navigated;
    },

    getBubbleToMinimize() {
      return {
        id: this.bubble.id,
        type: this.bubble.type,
        agendaSessionId: this.selectedAgendaSessionId,
        savedScrollPosition: this.$refs.bubbleBox.getSavedScrollPosition(),
      };
    },

    selectAgendaSession(agendaSessionId) {
      this.$refs.bubbleBox.saveScrollPosition();
      this.selectedAgendaSessionId = agendaSessionId;
      this.$refs.bubbleBox.setInitScroll(true);
      this.refreshLayout();
    },

    onAddButtonClick() {
      if (this.selectedAgendaSession) {
        this.$buefy.modal.open({
          parent: this,
          component: AgendaSessionCreateQuestionModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            agendaSessionId: this.selectedAgendaSession.id,
            componentId: this.selectedAgendaSession.event_component_id,
          },
        });
      }
    },
  },

  watch: {
    bubble: {
      immediate: true,
      handler: function (newValue) {
        if (newValue.agendaSessionId) {
          this.selectedAgendaSessionId = newValue.agendaSessionId;
        }
        if (newValue.savedScrollPosition) {
          this.$nextTick(() => {
            this.$refs.bubbleBox.setSavedScrollPosition(newValue.savedScrollPosition);
            if (!newValue.agendaSessionId) {
              this.$refs.bubbleBox.setInitScroll(false);
              this.$refs.bubbleBox.restoreScrollPosition();
            }
          });
        }
      },
    },

    selectedAgendaSessionId: {
      immediate: true,
      handler: function (newValue) {
        this.setVisibleAgendaSessionId(newValue);
      },
    },
  },

  beforeDestroy() {
    this.setVisibleAgendaSessionId(null);
  },
};
</script>

<style scoped lang="scss"></style>
