<template>
  <div v-if="user">
    <profile-card v-bind="{ picture, firstName, lastName, description }"></profile-card>
    <b-m-day-select class="mt-1" :days="availabilityDays"></b-m-day-select>
    <div class="bm-create-meeting-title">
      {{ $t("business_matching.business_matching_time_and_place") }}
    </div>
    <div class="bm-create-meeting-subtitle mb-1">
      {{ $t("business_matching.business_matching_free_custom_place_info") }}
      {{ $t("business_matching.business_matching_propose_time") }}
    </div>

    <template v-if="sessions && sessions.length">
      <i18n path="business_matching.business_matching_free_info" tag="span" class="bm-create-meeting-subtitle">
        <div>{{ sessionsString }}</div>
      </i18n>
    </template>

    <div class="has-text-centered mt-2">
      <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center" v-if="selectedDate">
        <b-timepicker v-model="selectedDate" :incrementMinutes="5" :min-time="minDate" inline></b-timepicker>
        <div class="time-cancel-button-circle ml-1" @click="removeDate">
          <close-icon class="svg-icon cancel-icon"></close-icon>
        </div>
      </div>
      <b-button class="button is-primary" style="height: 75px" v-else @click="selectDate" outlined
        >{{ $t("business_matching.business_matching_choose_time_uppercase") }}
      </b-button>
      <div class="has-text-danger mt-1" v-if="timeError">
        {{ $t("business_matching.business_matching_free_time_error") }}
      </div>
    </div>

    <template v-if="selectedDate">
      <div class="bm-create-meeting-title mt-2">{{ $t("business_matching.business_matching_free_time_duration") }}</div>
      <b-slider
        class="px-2 mt-6"
        type="is-primary"
        v-model="selectedDuration"
        :min="minDuration"
        :max="maxDuration"
        :step="5"
        :custom-formatter="formatDuration"
        tooltip-always
        ticks
      >
        <template v-for="val in [60, 120, 180, 240]">
          <b-slider-tick :value="val" :key="val">{{ formatDuration(val).replace(/\s/g, "") }}</b-slider-tick>
        </template>
      </b-slider>
    </template>

    <template v-if="selectedDate && suggestedTimeSlot">
      <div class="bm-create-meeting-title mt-4">{{ $t("business_matching.business_matching_place_for_meeting") }}</div>
      <dropdown-select class="mt-2" :items="places" :value="selectedPlaceId" @input="selectPlace">
        <template #dropdown="{ item }">
          <span>{{ item.value }}</span>
        </template>

        <template #placeholder>
          <span class="has-text-secondary">{{ $t("business_matching.business_matching_place_for_meeting_empty") }}</span>
        </template>
      </dropdown-select>

      <template v-if="selectedPlace && selectedPlace.id === -1">
        <div class="control mx-1 mt-1">
          <input
            class="input is-simple"
            type="text"
            :placeholder="$t('business_matching.business_matching_place_for_meeting')"
            v-model="customPlace"
          />
        </div>
      </template>
      <div class="has-text-danger mt-1" v-if="placeError">
        {{ $t("business_matching.business_matching_select_place") }}
      </div>
    </template>

    <div class="bm-create-meeting-title mt-4">{{ $t("business_matching.business_matching_additional_details") }}</div>
    <div class="control my-1">
      <textarea class="textarea" v-model="additionalInfo"></textarea>
    </div>

    <button class="button is-primary has-fullwidth mt-2" @click="send">
      {{ $t("business_matching.business_matching_send_invitation_uppercase") }}
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { composeBMTimeSlotTime, positionAndCompany, debounce } from "@/shared/utils";
import Constants from "@/web/constants";
import ProfileCard from "@/web/components/shared/ProfileCard";
import BMDaySelect from "@/web/components/businessmatching/BMDaySelect";
import DropdownSelect from "@/shared/components/DropdownSelect";
import RequestMixin from "@/shared/mixins/RequestMixin";
import CloseIcon from "@/assets/icon_close.svg";
import dayjs from "@/shared/utils/day";

export default {
  name: "BMCreateFreeMeeting",

  components: { BMDaySelect, ProfileCard, DropdownSelect, CloseIcon },

  mixins: [RequestMixin],

  props: {
    component: {
      type: Object,
      required: true,
    },

    userUuid: {
      type: String,
      required: true,
    },

    meeting: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      selectedDate: null,
      selectedDuration: null,
      selectedPlaceId: null,
      suggestedTimeSlot: null,
      customPlace: "",
      additionalInfo: "",
      minDate: null,
      validated: false,
    };
  },

  computed: {
    ...mapState(["timezone"]),
    ...mapGetters("attendees", ["getUserById"]),
    ...mapGetters("businessMatchingAvailability", ["availabilityDays"]),
    ...mapGetters("businessMatchingSuggestions", ["currentlySelectedDay"]),
    ...mapGetters("businessMatching", ["getAllSessionFromEventDay"]),

    user() {
      return this.getUserById(this.userUuid);
    },

    firstName() {
      return this.user.first_name;
    },

    lastName() {
      return this.user.last_name;
    },

    picture() {
      return this.user.picture;
    },

    description() {
      return positionAndCompany(this.user);
    },

    minDuration() {
      return 5;
    },

    maxDuration() {
      return 4 * 60;
    },

    places() {
      const otherPlace = {
        id: -1,
        value: this.$t("business_matching.business_matching_free_custom_place"),
      };
      if (this.suggestedTimeSlot && this.suggestedTimeSlot.places) {
        return [...this.suggestedTimeSlot.places, otherPlace];
      } else {
        return [otherPlace];
      }
    },

    selectedPlace() {
      const index = this.selectedPlaceId;
      if (index <= this.places.length + 1) {
        return this.places[index];
      } else {
        return null;
      }
    },

    isPlaceSelected() {
      return (this.selectedPlace && this.selectedPlace.id !== -1) || this.customPlace;
    },

    sessions() {
      return this.getAllSessionFromEventDay(this.currentlySelectedDay);
    },

    sessionsString() {
      if (this.sessions && this.sessions.length) {
        return this.sessions
          .map(
            session =>
              `${composeBMTimeSlotTime(session.time_start, this.timezone)} - ${composeBMTimeSlotTime(session.time_end, this.timezone)}`
          )
          .join(",\n");
      } else {
        return null;
      }
    },

    timeError() {
      if (this.validated) {
        if (this.meeting) {
          return !this.selectedDate;
        }
      }
      return false;
    },

    placeError() {
      if (this.validated) {
        return !this.isPlaceSelected;
      }
      return false;
    },

    hasAnyError() {
      return this.timeError || this.placeError;
    },
  },

  methods: {
    ...mapActions("businessMatchingSuggestions", ["fetchFreeRideSuggestions", "createInvitation"]),
    ...mapActions("businessMatchingMeetings", ["rescheduleMeeting"]),

    formatDuration(value) {
      const hours = (value / 60) | 0;
      const minutes = (value | 0) % 60;
      if (hours > 0) {
        if (minutes > 0) {
          return this.$t("time.time_duration_hours_and_minutes", [hours, minutes]);
        } else {
          return this.$t("time.time_duration_hours", [hours]);
        }
      } else {
        return this.$t("time.time_duration_minutes", [minutes]);
      }
    },

    selectDate() {
      const currentDay = this.currentlySelectedDay;
      const currentDate = new Date();
      const date = new Date(currentDay.only_date);
      if (sameDay(currentDate, date)) {
        let minutes = currentDate.getMinutes();
        minutes = minutes + (5 - (minutes % 5));
        date.setHours(currentDate.getHours(), minutes);
        this.minDate = new Date(date);
        this.selectedDate = date;
      } else {
        this.minDate = null;
        this.selectedDate = date;
      }
    },

    removeDate() {
      this.selectedDate = null;
      this.minDate = null;
    },

    selectPlace(value) {
      this.selectedPlaceId = value;
    },

    send() {
      this.validated = true;
      if (!this.hasAnyError) {
        let startDateISOString;
        let endDateISOString;
        if (this.selectedDate) {
          const startDate = this.selectedDate;
          const hours = startDate.getHours();
          const minutes = startDate.getMinutes();

          const startMoment = dayjs(this.currentlySelectedDay.only_date).tz(this.timezone).hours(hours).minutes(minutes);

          startDateISOString = startMoment.toISOString();
          endDateISOString = startMoment.add(this.selectedDuration, "minute").toISOString();
        }
        const placeId = this.selectedPlace && this.selectedPlace.id !== -1 ? this.selectedPlace.id : null;
        const customPlaceName = this.selectedPlace && this.selectedPlace.id === -1 ? this.customPlace : null;
        const sessionId = this.selectedPlace && this.selectedPlace.id !== -1 ? this.suggestedTimeSlot.session_id : null;

        const data = {
          componentId: this.component.id,
          timeStart: startDateISOString,
          timeEnd: endDateISOString,
          userUuid: this.userUuid,
          message: this.additionalInfo,
          placeId: placeId,
          sessionId: sessionId,
          customPlaceName: customPlaceName,
        };
        if (this.meeting) {
          this.rescheduleMeeting({
            componentId: this.meeting.event_component_id,
            meetingId: this.meeting.id,
            data: data,
          }).then(result => {
            this.$emit("close");
          });
        } else {
          this.createInvitation(data).then(result => {
            this.$emit("close");
          });
        }
      }
    },

    validateDataToSend() {
      if (this.meeting) {
        return this.selectedDate && (this.selectedTimeSlot || this.customPlace);
      } else if (this.selectedDate) {
        return this.selectedTimeSlot || this.customPlace;
      }
      return true;
    },
  },

  requests: {
    async fetchSuggestions() {
      if (this.component.additional_info.business_matching_type === Constants.BUSINESS_MATCHING_TYPE_FREE) {
        const startDate = this.selectedDate;
        const hours = startDate.getHours();
        const minutes = startDate.getMinutes();

        const startMoment = dayjs(this.currentlySelectedDay.only_date).tz(this.timezone).hours(hours).minutes(minutes);

        const startDateISOString = startMoment.toISOString();
        const endDateISOString = startMoment.add(this.selectedDuration, "minute").toISOString();

        await this.fetchFreeRideSuggestions({
          componentId: this.component.id,
          userUuid: this.userUuid,
          timeStart: startDateISOString,
          timeEnd: endDateISOString,
        }).then(suggestedTimeSlot => {
          this.suggestedTimeSlot = suggestedTimeSlot;
        });
      }
    },
  },

  watch: {
    currentlySelectedDay: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.removeDate();
        }
      },
    },

    selectedDuration: {
      immediate: true,
      handler: debounce(function (newValue) {
        if (newValue && this.selectedDate) {
          this.fetchSuggestions();
        }
      }, 1000),
    },

    selectedDate: {
      immediate: false,
      handler: debounce(function (newValue) {
        if (newValue && this.selectedDuration) {
          this.fetchSuggestions();
        }
      }, 1000),
    },
  },
};

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}
</script>

<style scoped lang="scss">
.time-cancel-button-circle {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 2px solid #eb5757;
}

.cancel-icon {
  stroke: #eb5757;
}
</style>
