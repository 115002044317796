import Constants from "@/web/constants";
import UserUnavailabilityModel from "@/web/store/models/UserAvailabilityModel";
import dayjs from "@/shared/utils/day";
import {
  areTimestampRangesColliding,
  generateDaysFromDateRange,
  getTimestampRangeFromEventDay,
  mergeCollidingAvailabilities,
  removeAvailabilityFromCurrentData,
  transformAvailabilityToUnavailability,
  transformUnavailabilityToAvailability,
} from "@/shared/utils";

export const state = () => ({
  status: null,
  error: null,
  selectedDay: null,
  availability: [],
  lastFreeAvailabilityId: -1,
});

export const types = {
  LOAD_AVAILABILITY: "loadAvailability",
  LOAD_AVAILABILITY_SUCCESS: "loadAvailabilitySuccess",
  LOAD_AVAILABILITY_ERROR: "loadAvailabilityError",
  INCREMENT_ID: "incrementAvailabilityId",
};

export const mutations = {
  [types.LOAD_AVAILABILITY](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_AVAILABILITY_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_AVAILABILITY_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
  selectDay(state, day) {
    state.selectedDay = day;
  },

  setAvailability(state, availability) {
    state.availability = availability;
  },

  [types.INCREMENT_ID](state) {
    state.lastFreeAvailabilityId = state.lastFreeAvailabilityId - 1;
  },
};

export const actions = {
  async fetchAvailability({ rootState, commit, getters }) {
    if (!getters["isLoading"]) {
      const eventId = rootState.eventId;
      commit(types.LOAD_AVAILABILITY);
      return UserUnavailabilityModel.api()
        .get(`events/${eventId}/users/unavailability`)
        .then(result => {
          commit(
            "setAvailability",
            transformUnavailabilityToAvailability(
              result.response.data,
              generateDaysFromDateRange(rootState.event.start_date, rootState.event.end_date)
            )
          );
          commit(types.LOAD_AVAILABILITY_SUCCESS);
          return result;
        })
        .catch(error => {
          commit(types.LOAD_AVAILABILITY_ERROR, error);
          throw error;
        });
    } else {
      return Promise.resolve();
    }
  },

  async sendAvailability({ rootState, state, getters, commit }, newAvailability) {
    if (!getters["isLoading"]) {
      commit(types.LOAD_AVAILABILITY);
      const eventId = rootState.eventId;
      const availability = newAvailability ? mergeCollidingAvailabilities([...state.availability, newAvailability]) : state.availability;
      const requestBody = {
        unavailability: transformAvailabilityToUnavailability(availability, getters["availabilityDays"], rootState.timezone),
      };
      return UserUnavailabilityModel.api()
        .post(`events/${eventId}/users/unavailability`, requestBody)
        .then(result => {
          commit("setAvailability", transformUnavailabilityToAvailability(result.response.data, getters["availabilityDays"]));
          commit(types.LOAD_AVAILABILITY_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_AVAILABILITY_ERROR, err);
          throw err;
        });
    } else {
      return Promise.resolve();
    }
  },

  async getNewTempAvailability({ state, commit }, { startTimestamp, endTimestamp }) {
    const newAvailability = { id: state.lastFreeAvailabilityId, startTimestamp, endTimestamp };
    commit(types.INCREMENT_ID);
    return newAvailability;
  },

  async createNewAvailability({ state, commit, dispatch, getters }, { startTimestamp, endTimestamp }) {
    if (!getters["isLoading"]) {
      const newAvailability = { id: state.lastFreeAvailabilityId, startTimestamp, endTimestamp };
      commit(types.INCREMENT_ID);
      await dispatch("sendAvailability", newAvailability);
      return newAvailability;
    } else {
      return Promise.resolve();
    }
  },

  async removeAvailability({ state, commit, dispatch, getters }, availabilityToRemove) {
    if (!getters["isLoading"]) {
      commit("setAvailability", removeAvailabilityFromCurrentData(state.availability, availabilityToRemove));
    } else {
      return Promise.resolve();
    }
  },
};

export const getters = {
  isLoading: state => state.status === Constants.STATUS_LOADING,

  currentlySelectedDay: state => state.selectedDay,

  availabilityDays: (state, getters, rootState) => {
    const event = rootState.event;
    const startDate = getMaxDate(dayjs(event.start_date), getMinDate(dayjs(event.end_date), dayjs()))
      .tz(event.timezone)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    return generateDaysFromDateRange(startDate.toISOString(), event.end_date);
  },

  allAvailabilities: state => {
    return state.availability;
  },

  availabilityFromCurrentDay: (state, getters) => {
    const dayRange = getTimestampRangeFromEventDay(state.selectedDay);
    return state.availability
      .map(available => ({ ...available }))
      .filter(available => {
        return (
          available.endTimestamp !== dayRange.startTimestamp &&
          available.startTimestamp !== dayRange.endTimestamp &&
          areTimestampRangesColliding(dayRange.startTimestamp, dayRange.endTimestamp, available.startTimestamp, available.endTimestamp)
        );
      })
      .map(available => {
        if (available.startTimestamp < dayRange.startTimestamp) {
          available.startTimestamp = dayRange.startTimestamp;
        }
        if (available.endTimestamp > dayRange.endTimestamp) {
          available.endTimestamp = dayRange.endTimestamp;
        }
        return available;
      });
  },

  isOverlappingWithCurrentAvailabilities: state => newAvailable => {
    return (
      state.availability.filter(
        available =>
          available.id !== newAvailable.id &&
          areTimestampRangesColliding(
            newAvailable.startTimestamp,
            newAvailable.endTimestamp,
            available.startTimestamp,
            available.endTimestamp
          )
      ).length > 0
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function getMinDate(firstDate, secondDate) {
  return firstDate < secondDate ? firstDate : secondDate;
}

function getMaxDate(firstDate, secondDate) {
  return firstDate > secondDate ? firstDate : secondDate;
}
