<template>
  <div class="box is-radius-small py-0">
    <div class="columns is-mobile is-vcentered has-fullwidth is-multiline is-centered is-gapless">
      <div class="column is-flex is-justify-content-center">
        <div class="time-container my-2">
          <b-timepicker v-model="startDate" v-if="editMode" size="is-small" :incrementMinutes="5" inline></b-timepicker>
          <div v-else>{{ parsedTimeStart }}</div>
        </div>
      </div>
      <div class="column is-narrow">
        <div>{{ " - " }}</div>
      </div>
      <div class="column is-flex is-justify-content-center">
        <div class="time-container my-2">
          <b-timepicker v-model="endDate" v-if="editMode" size="is-small" :incrementMinutes="5" inline></b-timepicker>
          <div v-else>{{ parsedTimeEnd }}</div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="is-flex is-flex-direction-row py-1">
          <transition name="fade" mode="out-in">
            <figure class="edit-icon-circle is-rounded has-background-primary" v-if="editMode" @click="toggleEditMode" key="acceptIcon">
              <accept-icon class="svg-icon"></accept-icon>
            </figure>
            <figure class="edit-icon-circle is-rounded" v-else @click="toggleEditMode" key="editIcon">
              <edit-icon class="svg-icon edit-icon"></edit-icon>
            </figure>
          </transition>
          <figure class="edit-icon-circle is-rounded ml-1 has-background-primary" @click="onRemove" key="editIcon">
            <trash-bin-icon class="svg-icon" style="fill: white"></trash-bin-icon>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTimestampToAvailabilityTime } from "@/shared/utils";
import { mapActions, mapGetters } from "vuex";
import EditIcon from "@/assets/icons/edit.svg";
import AcceptIcon from "@/assets/icons/accept.svg";
import TrashBinIcon from "@/assets/icons/trash-bin.svg";
import dayjs from "@/shared/utils/day";

export default {
  name: "AvailabilityListItem",

  components: { AcceptIcon, EditIcon, TrashBinIcon },

  props: {
    availability: {
      type: Object,
      required: true,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      editMode: false,
      startDate: null,
      endDate: null,
    };
  },

  computed: {
    ...mapGetters("businessMatchingAvailability", ["isOverlappingWithCurrentAvailabilities"]),

    parsedTimeStart() {
      return parseTimestampToAvailabilityTime(this.availability.startTimestamp, this.timezone);
    },

    parsedTimeEnd() {
      return parseTimestampToAvailabilityTime(this.availability.endTimestamp, this.timezone);
    },
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["removeAvailability", "sendAvailability"]),

    toggleEditMode() {
      if (this.editMode) {
        this.save();
      } else {
        this.editMode = true;
      }
    },

    save() {
      let startMoment = dayjs(this.availability.startTimestamp)
        .tz(this.timezone)
        .hour(this.startDate.getHours())
        .minute(this.startDate.getMinutes());
      let endMoment = dayjs(this.availability.endTimestamp)
        .tz(this.timezone)
        .hour(this.endDate.getHours())
        .minute(this.endDate.getMinutes());
      let startTimestamp = startMoment.valueOf();
      let endTimestamp = endMoment.valueOf();

      const originStartMoment = dayjs(this.availability.startTimestamp).tz(this.timezone);
      const originEndMoment = dayjs(this.availability.endTimestamp).tz(this.timezone);

      if (originStartMoment.dayOfYear() !== originEndMoment.dayOfYear() && (endMoment.hour() > 0 || endMoment.minute() > 0)) {
        endTimestamp = endTimestamp - 24 * 60 * 60 * 1000;
      }

      const availability = {
        id: this.availability.id,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
      };

      if (startTimestamp >= endTimestamp) {
        this.$root.openErrorModal(this.$t("business_matching.availability_time_error"));
      } else {
        if (this.isOverlappingWithCurrentAvailabilities(availability)) {
          this.$root.openInfoModal(this.$t("common.information"), this.$t("business_matching.availability_overlapping_info"));
        }
        if (!this.isNew) {
          if (availability.startTimestamp > this.availability.startTimestamp) {
            this.removeAvailability({
              startTimestamp: this.availability.startTimestamp,
              endTimestamp: availability.startTimestamp,
            });
          }
          if (availability.endTimestamp < this.availability.endTimestamp) {
            this.removeAvailability({
              startTimestamp: availability.endTimestamp,
              endTimestamp: this.availability.endTimestamp,
            });
          }
        } else {
          this.$emit("reset");
        }
        this.sendAvailability(availability);
        this.editMode = false;
      }
    },

    onRemove() {
      if (this.isNew) {
        this.$emit("reset");
      } else {
        this.removeAvailability(this.availability);
        this.sendAvailability();
      }
    },
  },

  watch: {
    availability: {
      immediate: true,
      handler: function (newValue) {
        const startMoment = dayjs(newValue.startTimestamp).tz(this.timezone);
        const endMoment = dayjs(newValue.endTimestamp).tz(this.timezone);
        const startDate = new Date();
        startDate.setHours(startMoment.hour(), startMoment.minute());
        this.startDate = startDate;
        const endDate = new Date();
        endDate.setHours(endMoment.hour(), endMoment.minute());
        this.endDate = endDate;
      },
    },

    isNew: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.editMode = true;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  display: flex;
  flex-direction: row;
}

.edit-icon-circle {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 2px solid var(--primary-color);
}

.edit-icon {
  margin-left: 2px;
  margin-bottom: 2px;
  fill: var(--primary-color);
}

.time-container {
  width: 120px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
  white-space: pre-wrap;
}
</style>
