<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4" v-if="title">
        {{ $t(title) }}
      </h2>

      <div class="subtitle has-text-centered" v-if="description">
        {{ description }}
      </div>

      <div class="card mx-auto" @click="openChat">
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <profile-picture class="is-60x60" :picture="customerSupportAvatar" initials="CS"></profile-picture>
            </div>
            <div class="media-content has-text-centered my-auto">
              <p class="title">{{ buttonText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import Separator from "@/web/components/shared/Separator";

export default {
  name: "CustomerSupportComponent",

  props: ["moduleData"],

  components: { Separator, ProfilePicture },

  mixins: [RedirectMixin],

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("attendees", ["getUserById"]),

    separatorVisible() {
      return !this.moduleData.separator_disabled;
    },

    title() {
      return this.moduleData.title;
    },

    customerSupportUuid() {
      return this.moduleData["customer_support_uuid"];
    },

    description() {
      return this.$t(this.moduleData["description_text"]);
    },

    buttonText() {
      return this.$t(this.moduleData["button_text"]);
    },

    customerSupportAvatar() {
      return {
        file_thumbnail_200_url: this.$t(this.moduleData["avatar_image_url"]),
      };
    },
  },

  methods: {
    ...mapActions("attendees", ["loadUserProfile"]),
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),

    openChat() {
      if (this.isAuthorized) {
        this.checkIfThreadExists({ userId: this.customerSupportUuid }).then(result => {
          if (result.data) {
            const threadId = result.data.id;
            this.openInboxBubbleWithThread(threadId);
          } else {
            this.openInboxBubbleWithUser(this.customerSupportUuid);
          }
        });
      } else {
        const query = { returnUrl: this.$route.fullPath };
        this.nextRedirect({ name: Constants.ROUTE_LOGIN, query });
      }
    },
  },
};
</script>

<style scoped>
.card {
  max-width: 540px;
}

.card:hover {
  cursor: pointer;
  border-color: var(--primary-color-hover);
}
</style>
