import UserModel from "@/web/store/models/UserModel";
import axios from "@/web/services/axios";
import { buildSocialLinks } from "@/shared/utils";
import authService from "@/web/services/auth";

export const state = () => ({
  userUuid: null,
  userMeta: null,
});

export const mutations = {
  setUser(state, user) {
    state.userUuid = user.user_identity_token;
  },

  clearUser(state) {
    state.userUuid = null;
  },

  setUserMeta(state, userMeta) {
    state.userMeta = userMeta;
  },
};

export const actions = {
  async clearUser({ state, commit }) {
    const currentUserUuid = state.userUuid;
    await commit("clearUser");
    await UserModel.delete(currentUserUuid);
  },

  async loadUser({ commit }) {
    return await UserModel.api()
      .get("current_user")
      .then(response => commit("setUser", response.response.data));
  },

  async updateUser({ getters }, payload) {
    const currentUser = getters.currentUser;
    if (currentUser) {
      const updateModel = { ...currentUser, picture: currentUser.picture && currentUser.picture.id };
      return await UserModel.api().put("current_user", { ...updateModel, ...payload });
    }
  },

  async updateUserAvatar({ getters }, file) {
    const formData = new FormData();
    formData.append("file", file);
    const attachmentResponse = await axios.post("attachments", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    const newAttachmentId = attachmentResponse.data.id;
    const currentUser = getters.currentUser;
    const updateModel = { ...currentUser, attachment_id: newAttachmentId };

    return await UserModel.api().put("current_user", { ...updateModel });
  },

  async updateUserMeta(context, { eventId, metadata }) {
    const body = { specific_fields: metadata };
    await axios.post(`events/${eventId}/update_user_specific`, body);
    return true;
  },

  async checkIsUserMetaSubmitted({ dispatch, rootGetters }) {
    const userMetaData = await dispatch("fetchUserMeta");
    const requiredUserMetaFields = rootGetters.requiredUserMetaFields;
    const requiredResponses = userMetaData.filter(metaData => {
      return requiredUserMetaFields.includes(metaData.name);
    });
    return requiredResponses.length === 0 || requiredResponses.some(metaData => metaData.response !== null && metaData.response);
  },

  async fetchUserMeta({ commit, rootState }) {
    let eventId = rootState["eventId"];
    let userMeta = await axios.get(`events/${eventId}/specific_fields_for_user`).then(result => result.data.fields);
    commit("setUserMeta", userMeta);
    return userMeta;
  },

  async updateUserEmail({ getters, dispatch }, { currentPassword, newEmail }) {
    await authService.login(getters.email, currentPassword);
    await dispatch("updateUser", { email: newEmail });
    return true;
  },

  async joinVideoCallWithUser({ getters }, userId) {
    return await axios.post("joined_video", { user_identity_token: userId });
  },
};

export const getters = {
  currentUser(state) {
    return UserModel.query().whereId(state.userUuid).withAll().first();
  },

  uuid(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser && getters.currentUser.user_identity_token;
  },

  fullName(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser && `${currentUser.first_name} ${currentUser.last_name}`;
  },

  initials(state, getters) {
    const currentUser = getters.currentUser;
    const a = ((currentUser.first_name && currentUser.first_name[0]) || "").toUpperCase();
    const b = ((currentUser.last_name && currentUser.last_name[0]) || "").toUpperCase();
    return `${a}${b}`;
  },

  positionAndCompany(state, getters) {
    const currentUser = getters.currentUser;
    const company = currentUser.company;
    const position = currentUser.position;

    if (company && position) {
      return position + ", " + company;
    } else if (company) {
      return company;
    } else if (position) {
      return position;
    } else {
      return "";
    }
  },

  socialLinks(state, getters) {
    const currentUser = getters.currentUser;
    return buildSocialLinks(currentUser);
  },

  picture(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.picture;
  },

  biography(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.biography;
  },

  whatIOffer(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.what_i_offer;
  },

  whatINeed(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.what_i_need;
  },

  email(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser && currentUser.email;
  },

  phone(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.phone_number;
  },

  country(state, getters) {
    const currentUser = getters.currentUser;
    return currentUser.country;
  },
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
