<template>
  <div class="container mt-2">
    <div v-if="isLoaded">
      <quiz-in-progress :quiz-id="quizId" :is-small="false"></quiz-in-progress>
    </div>
    <div v-else>
      <b-loading :active="true" :is-full-page="true"></b-loading>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import QuizInProgress from "@/web/components/quizzes/QuizInProgress";

export default {
  name: "Quiz",

  components: { QuizInProgress },

  mixins: [RequestMixin, AuthorizedRouteMixin],

  requests: {
    async fetchData() {
      await this.loadQuizzes(this.componentId);
    },
  },

  mounted() {
    this.fetchData();
  },

  watch: {
    error(newVal) {
      if (newVal) {
        this.$root.openErrorModal(newVal);
        this.error = null;
      }
    },
  },

  computed: {
    ...mapGetters("quizzes", ["getById", "isComponentLoading"]),

    componentId() {
      return Number.parseInt(this.$route.params.componentId);
    },

    quizId() {
      return Number.parseInt(this.$route.params.quizId);
    },

    isLoaded() {
      return !this.isComponentLoading(this.componentId) && this.quiz;
    },

    quiz() {
      return this.getById(this.quizId);
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes"]),
  },
};
</script>
