<template>
  <form action="." method="POST" @submit.prevent="submit" @input="input">
    <template v-if="registerDisabledText">
      <div class="has-text-centered mb-2 mt-4">
        <i18n :path="registerDisabledText.textKey" tag="span" class="subtitle">
          <template v-if="registerDisabledText.substrings">
            <a
              target="_blank"
              @click.stop="0"
              v-for="keyValue in registerDisabledText.substrings"
              :href="keyValue.link"
              :key="keyValue.substring"
            >
              {{ keyValue.substring }}
            </a>
          </template>
        </i18n>
      </div>
    </template>
    <template v-else>
      <template v-for="field in negativeOrderFields">
        <component
          :is="fieldComponent(field.type)"
          v-bind="fieldBindings(field)"
          :ref="field.id"
          :key="`${field.id}-${reRenderKey}`"
          v-if="fieldVisible(field)"
          v-model="$data[field.id]"
          @form-input="input"
        ></component>
      </template>

      <template v-if="includeRegisterForm">
        <!-- First name & Last name fields -->
        <div class="columns is-vcentered mb-0" v-if="includeRegisterForm">
          <div class="column is-half">
            <!-- First name field -->
            <div class="field" :ref="registerFields.FIRST_NAME">
              <label class="label has-text-primary">{{ $t("auth.auth_firstname_placeholder") }}</label>
              <div class="control">
                <input
                  class="input is-simple"
                  type="text"
                  :placeholder="$t('auth.auth_firstname_placeholder')"
                  v-model="$data[registerFields.FIRST_NAME]"
                />
              </div>
              <template v-if="submitted">
                <p v-if="!$v[registerFields.FIRST_NAME].required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
              </template>
            </div>
          </div>
          <div class="column is-half">
            <!-- Last name field -->
            <div class="field" :ref="registerFields.LAST_NAME">
              <label class="label has-text-primary">{{ $t("auth.auth_lastname_placeholder") }}</label>
              <div class="control">
                <input
                  class="input is-simple"
                  type="text"
                  :placeholder="$t('auth.auth_lastname_placeholder')"
                  v-model="$data[registerFields.LAST_NAME]"
                />
              </div>
              <template v-if="submitted">
                <p v-if="!$v[registerFields.LAST_NAME].required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
              </template>
            </div>
          </div>
        </div>
        <!-- Email address field -->
        <div class="field" :ref="registerFields.EMAIL">
          <label class="label has-text-primary">{{ $t("auth.email") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.email')" v-model="$data[registerFields.EMAIL]" />
          </div>
          <template v-if="submitted">
            <p v-if="!$v[registerFields.EMAIL].required" class="help is-danger">{{ $t("auth.error_email") }}</p>
            <p v-if="!$v[registerFields.EMAIL].email" class="help is-danger">{{ $t("auth.error_email") }}</p>
          </template>
        </div>

        <!-- Password field -->
        <div class="columns mb-1" :ref="registerFields.PASSWORD">
          <div class="column is-half">
            <div class="field">
              <label class="label has-text-primary">{{ $t("auth.password") }}</label>
              <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                <input
                  class="input is-simple"
                  :type="$data[registerFields.IS_PASSWORD_VISIBLE] ? 'text' : 'password'"
                  :placeholder="$t('auth.password')"
                  v-model="$data[registerFields.PASSWORD]"
                />
                <div
                  class="password-visibility-button"
                  @click="$data[registerFields.IS_PASSWORD_VISIBLE] = !$data[registerFields.IS_PASSWORD_VISIBLE]"
                >
                  <visibility-off-icon
                    class="svg-icon has-fill-primary"
                    v-if="$data[registerFields.IS_PASSWORD_VISIBLE]"
                  ></visibility-off-icon>
                  <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
                </div>
              </div>
              <template v-if="submitted">
                <p v-if="!$v[registerFields.PASSWORD].required" class="help is-danger">{{ $t("auth.error_password") }}</p>
                <p v-if="!$v[registerFields.PASSWORD].minLength" class="help is-danger">{{ $t("common.field_too_short") }}</p>
              </template>
            </div>
          </div>

          <!-- Repeat Password field -->
          <div class="column is-half">
            <div class="field" :ref="registerFields.REPEAT_PASSWORD">
              <label class="label has-text-primary">{{ $t("auth.repeat_password") }}</label>
              <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                <input
                  class="input is-simple"
                  :type="$data[registerFields.IS_REPEAT_PASSWORD_VISIBLE] ? 'text' : 'password'"
                  :placeholder="$t('auth.repeat_password')"
                  v-model="$data[registerFields.REPEAT_PASSWORD]"
                />
                <div
                  class="password-visibility-button"
                  @click="$data[registerFields.IS_REPEAT_PASSWORD_VISIBLE] = !$data[registerFields.IS_REPEAT_PASSWORD_VISIBLE]"
                >
                  <visibility-off-icon
                    class="svg-icon has-fill-primary"
                    v-if="$data[registerFields.IS_REPEAT_PASSWORD_VISIBLE]"
                  ></visibility-off-icon>
                  <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
                </div>
              </div>
              <template v-if="submitted">
                <p v-if="!$v[registerFields.REPEAT_PASSWORD].sameAsPassword" class="help is-danger">
                  {{ $t("auth.error_repeat_password") }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-for="field in positiveOrderFields">
        <component
          :is="fieldComponent(field.type)"
          v-bind="fieldBindings(field)"
          :ref="field.id"
          :key="`${field.id}-${reRenderKey}`"
          v-if="fieldVisible(field)"
          v-model="$data[field.id]"
          @form-input="input"
        ></component>
      </template>

      <template v-if="includeTermsAndGdpr">
        <!-- Privacy policy and terms of service consent -->
        <div class="field" :ref="registerFields.TERMS">
          <div class="control">
            <label class="checkbox">
              <checkbox class="checkbox-input" v-model="$data[registerFields.TERMS]"></checkbox>
              <i18n
                path="auth.terms_and_conditions"
                tag="span"
                class="consent-text"
                :class="$v[registerFields.TERMS].$error ? 'has-text-danger' : ''"
                @click="$data[registerFields.TERMS] = !$data[registerFields.TERMS]"
              >
                <a target="_blank" @click.stop="0" :href="$t('url.privacy')">{{ $t("auth.privacy_policy_substring") }}</a>
                <a target="_blank" @click.stop="0" :href="$t('url.terms')">{{ $t("auth.terms_of_service_substring") }}</a>
              </i18n>
            </label>
          </div>
        </div>

        <!-- GDPR consent -->
        <div class="field mb-3" :ref="registerFields.GDPR">
          <div class="control">
            <label class="checkbox consents">
              <checkbox class="checkbox-input" v-model="$data[registerFields.GDPR]"></checkbox>
              <i18n
                path="auth.gdpr_terms"
                tag="span"
                class="consent-text"
                :class="$v[registerFields.GDPR].$error ? 'has-text-danger' : ''"
                @click="$data[registerFields.GDPR] = !$data[registerFields.GDPR]"
              >
                <a target="_blank" @click.stop="0" :href="$t('url.gdpr')">
                  {{ $t("auth.read_more") }}
                </a>
              </i18n>
            </label>
          </div>
        </div>
      </template>

      <!-- Submit button -->
      <div class="field mt-2" v-if="updateType === 'button'">
        <div class="control">
          <button class="button is-primary is-fullwidth" type="submit">
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { accepted, optionalPhoneNumber, requiredPhoneNumber, nipFormat } from "@/shared/utils/form-validations";
import { mapGetters, mapState } from "vuex";

import RegisterTextInput from "@/web/components/forms/inputs/RegisterTextInput";
import RegisterTextAreaInput from "@/web/components/forms/inputs/RegisterTextAreaInput";
import RegisterCheckboxInput from "@/web/components/forms/inputs/RegisterCheckboxInput";
import RegisterCountryInput from "@/web/components/forms/inputs/RegisterCountryInput";
import RegisterPhoneNumberInput from "@/web/components/forms/inputs/RegisterPhoneNumberInput";
import RegisterDropdownInput from "@/web/components/forms/inputs/RegisterDropdownInput";
import RegisterDateInput from "@/web/components/forms/inputs/RegisterDateInput";
import RegisterEmailInput from "@/web/components/forms/inputs/RegisterEmailInput";
import VisibilityIcon from "@/assets/auth/icon_visibility.svg";
import VisibilityOffIcon from "@/assets/auth/icon_visibility_off.svg";
import Checkbox from "@/shared/components/form/Checkbox";
import RegisterRadioInput from "@/web/components/forms/inputs/RegisterRadioInput";
import RegisterInformationInput from "@/web/components/forms/inputs/RegisterInformationInput";
import RegisterNipInput from "@/web/components/forms/inputs/RegisterNipInput";

export default {
  name: "CustomRegisterForm",

  components: { VisibilityIcon, VisibilityOffIcon, Checkbox },

  props: {
    currentLocale: {
      type: Object,
      required: true,
    },
    includeRegisterForm: {
      type: Boolean,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },
    //ALLOWED VALUES: 'input', 'button'
    updateType: {
      type: String,
      default: "button",
    },

    extraParams: {
      type: Object,
      required: false,
    },
  },

  data() {
    const registerFields = FormBuilderConstants.REGISTER_FIELDS;
    const fieldTypes = FormBuilderConstants.FIELD_TYPES;

    let data = {
      submitted: false,
      reRenderKey: 0,
    };
    this.formData.fields.forEach(field => {
      if (field.type === fieldTypes.CHECKBOX) {
        data[field.id] = false;
      } else {
        data[field.id] = null;
      }
    });
    if (this.includeRegisterForm) {
      data[registerFields.FIRST_NAME] = "";
      data[registerFields.LAST_NAME] = "";
      data[registerFields.EMAIL] = "";
      data[registerFields.PASSWORD] = "";
      data[registerFields.REPEAT_PASSWORD] = "";
      data[registerFields.IS_REPEAT_PASSWORD_VISIBLE] = false;
      data[registerFields.IS_PASSWORD_VISIBLE] = false;
      data[registerFields.TERMS] = false;
      data[registerFields.GDPR] = false;
    }
    return data;
  },

  validations() {
    let validations = {};

    const fieldTypes = FormBuilderConstants.FIELD_TYPES;
    const validationTypes = FormBuilderConstants.VALIDATIONS;
    const registerFields = FormBuilderConstants.REGISTER_FIELDS;

    const currentLocaleCode = this.currentLocale.localeCode;

    if (this.includeRegisterForm) {
      validations = {
        [registerFields.EMAIL]: {
          required,
          email: value => email(value.toLowerCase().replace(/\s/g, "")),
        },
        [registerFields.PASSWORD]: { required, minLength: minLength(7) },
        [registerFields.FIRST_NAME]: { required },
        [registerFields.LAST_NAME]: { required },
        [registerFields.REPEAT_PASSWORD]: {
          sameAsPassword: sameAs(registerFields.PASSWORD),
        },
      };
    }

    if (this.includeTermsAndGdpr) {
      validations[registerFields.TERMS] = { accepted };
      validations[registerFields.GDPR] = { accepted };
    }

    this.fields.forEach(field => {
      const fieldValidation = {};
      let fieldRulesFunction = this.fieldRules(field);
      if (fieldRulesFunction.every(rule => rule(currentLocaleCode, this.$data))) {
        if (field.type === fieldTypes.TEXT) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.TEXT_AREA) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.CHECKBOX) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = accepted;
          }
        } else if (field.type === fieldTypes.COUNTRY) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.PHONE_NUMBER) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
            fieldValidation[validationTypes.PHONE_NUMBER_REQUIRED] = requiredPhoneNumber;
          } else {
            fieldValidation[validationTypes.PHONE_NUMBER_OPTIONAL] = optionalPhoneNumber;
          }
        } else if (field.type === fieldTypes.DROPDOWN) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.DATE) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.EMAIL) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
          fieldValidation[validationTypes.EMAIL] = email;
        } else if (field.type === fieldTypes.RADIO) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
        } else if (field.type === fieldTypes.NIP) {
          if (field.required) {
            fieldValidation[validationTypes.REQUIRED] = required;
          }
          fieldValidation[validationTypes.NIP_FORMAT] = nipFormat;
        }
        validations[field.id] = fieldValidation;
      }
    });
    return validations;
  },

  computed: {
    // ...mapGetters("locales", ["currentLocale"]),
    ...mapState(["eventId"]),

    fields() {
      return this.formData.fields;
    },

    negativeOrderFields() {
      return this.fields.filter(field => field.order < 0).sort((a, b) => a.order - b.order);
    },

    positiveOrderFields() {
      return this.fields.filter(field => field.order >= 0).sort((a, b) => a.order - b.order);
    },

    translationsNamespace() {
      return this.formData.translations_namespace || FormBuilderConstants.TRANSLATIONS_NAMESPACE;
    },

    submitButtonText() {
      if (this.includeRegisterForm) {
        return this.$t("auth.dont_have_an_account_substring");
      } else {
        return this.$t("common.send");
      }
    },

    registerFields() {
      return FormBuilderConstants.REGISTER_FIELDS;
    },

    localeField() {
      return this.formData.locale_field;
    },

    includeTermsAndGdpr() {
      if (this.includeRegisterForm) {
        return !!this.formData[FormBuilderConstants.INCLUDE_TERMS_AND_GDPR];
      } else {
        return false;
      }
    },

    registerDisabledBy() {
      const data = this.formData[FormBuilderConstants.DISABLED_WHEN];
      if (data) {
        return data.find(it => {
          let fieldId = it[FormBuilderConstants.DISABLED_WHEN_PROPERTIES.FIELD_ID];
          let value = it[FormBuilderConstants.DISABLED_WHEN_PROPERTIES.VALUE];
          return this.$data[fieldId] === value;
        });
      } else {
        return null;
      }
    },

    registerDisabledText() {
      if (this.registerDisabledBy) {
        let fieldId = this.registerDisabledBy[FormBuilderConstants.DISABLED_WHEN_PROPERTIES.FIELD_ID];
        let value = this.registerDisabledBy[FormBuilderConstants.DISABLED_WHEN_PROPERTIES.VALUE];

        let textKey = `${this.translationsNamespace}.${fieldId}_${value}_disabled_register_text`;
        let substrings = this.$t(`${this.translationsNamespace}.${fieldId}_${value}_disabled_register_text_substrings`);
        let links = this.$t(`${this.translationsNamespace}.${fieldId}_${value}_disabled_register_text_links`);
        if (typeof substrings === "object" && substrings !== null && typeof links === "object" && links !== null) {
          const substringsArray = Object.values(substrings);
          const linksArray = Object.values(links);
          let merged = substringsArray.map((it, index) => {
            return { substring: it, link: linksArray[index] };
          });
          return { textKey, substrings: merged };
        } else {
          return { textKey };
        }
      }
      return null;
    },
  },

  mounted() {
    if (this.extraParams) {
      Object.entries(this.extraParams).forEach(([key, value]) => {
        if (key in this.$data) {
          let field = this.fields.find(field => field.id === key);
          if (field) {
            if (field[FormBuilderConstants.FIELD_PROPERTIES.TYPE] === FormBuilderConstants.FIELD_TYPES.DROPDOWN) {
              let refs = this.$refs[key];
              if (refs && refs.length) {
                refs[0].setConstantSelection(value);
              }
            } else {
              this.$data[key] = value;
            }
          }
        }
      });
    }
  },

  methods: {
    input() {
      if (this.updateType === "input") {
        let formData = {};
        if (this.includeRegisterForm) {
          formData.credentials = this.getCredentials();
        }
        formData.metaData = this.getMetaData();
        if (this.localeField) {
          formData[this.localeField] = this.currentLocale.localeCode;
        }
        this.$emit("input", formData);
      }
    },

    fieldComponent(fieldType) {
      const fieldTypes = FormBuilderConstants.FIELD_TYPES;
      if (fieldType === fieldTypes.TEXT) {
        return RegisterTextInput;
      } else if (fieldType === fieldTypes.TEXT_AREA) {
        return RegisterTextAreaInput;
      } else if (fieldType === fieldTypes.CHECKBOX) {
        return RegisterCheckboxInput;
      } else if (fieldType === fieldTypes.DROPDOWN) {
        return RegisterDropdownInput;
      } else if (fieldType === fieldTypes.PHONE_NUMBER) {
        return RegisterPhoneNumberInput;
      } else if (fieldType === fieldTypes.COUNTRY) {
        return RegisterCountryInput;
      } else if (fieldType === fieldTypes.DATE) {
        return RegisterDateInput;
      } else if (fieldType === fieldTypes.EMAIL) {
        return RegisterEmailInput;
      } else if (fieldType === fieldTypes.RADIO) {
        return RegisterRadioInput;
      } else if (fieldType === fieldTypes.INFORMATION) {
        return RegisterInformationInput;
      } else if (fieldType === fieldTypes.NIP) {
        return RegisterNipInput;
      } else {
        throw Error("This register form contains unhandled field type.");
      }
    },

    fieldBindings(field) {
      return {
        fieldData: field,
        errorsVisible: this.submitted,
        validation: this.$v[field.id],
        translationsNamespace: this.translationsNamespace,
      };
    },

    fieldVisible(field) {
      return this.fieldRules(field).every(rule => rule(this.currentLocale.localeCode, this.$data, this.$v));
    },

    fieldRules(field) {
      const ruleTypes = FormBuilderConstants.RULE_TYPES;
      let rulesValidation = [];
      let fieldRules = field.rules;

      if (fieldRules && fieldRules.length) {
        fieldRules.forEach(rule => {
          if (rule.type === ruleTypes.LOCALE) {
            if (rule.include && rule.include.length) {
              rulesValidation.push((currentLocale, data, validations) =>
                rule.include.some(includeLocale => includeLocale === currentLocale)
              );
            } else if (rule.exclude && rule.exclude.length) {
              rulesValidation.push(
                (currentLocale, data, validations) => !rule.exclude.some(excludeLocale => excludeLocale === currentLocale)
              );
            }
          } else if (rule.type === ruleTypes.FIELD_VALUE) {
            if (rule.include && rule.include.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return rule.include.some(value => (data[rule.field_id] || "").toLowerCase().includes(value.toLowerCase()));
              });
            } else if (rule.exclude && rule.exclude.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return !rule.exclude.some(value => (data[rule.field_id] || "").toLowerCase().includes(value.toLowerCase()));
              });
            }
          } else if (rule.type === ruleTypes.FIELD_NOT_EMPTY) {
            rulesValidation.push((currentLocale, data, validations) => {
              return data[rule.field_id] !== null && data[rule.field_id] !== "";
            });
          } else if (rule.type === ruleTypes.FIELD_BOOLEAN_VALUE) {
            rulesValidation.push((currentLocale, data, validations) => {
              if (rule.include[0] === true) {
                return !!data[rule.field_id];
              } else {
                return !data[rule.field_id];
              }
            });
          } else if (rule.type === ruleTypes.FIELD_COUNTRY) {
            if (rule.include && rule.include.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return data[rule.field_id] && rule.include.some(countryCode => data[rule.field_id].includes(countryCode));
              });
            } else if (rule.exclude && rule.exclude.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return data[rule.field_id] && !rule.exclude.some(countryCode => data[rule.field_id].includes(countryCode));
              });
            }
          } else if (rule.type === ruleTypes.FIELD_VALUE_INDEX) {
            if (rule.include && rule.include.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                const values = this.$t(`${this.translationsNamespace}.${rule.field_id}_values`);
                return rule.include.some(includeIndex => values[includeIndex] === data[rule.field_id]);
              });
            } else if (rule.exclude && rule.exclude.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                const values = this.$t(`${this.translationsNamespace}.${rule.field_id}_values`);
                return data[rule.field_id] && !rule.exclude.some(excludeIndex => values[excludeIndex] === data[rule.field_id]);
              });
            }
          } else if (rule.type === ruleTypes.FIELD_DATE) {
            if (rule.include && rule.include.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return data[rule.field_id] && new Date(data[rule.field_id]) > new Date(rule.include);
              });
            } else if (rule.exclude && rule.exclude.length) {
              rulesValidation.push((currentLocale, data, validations) => {
                return data[rule.field_id] && new Date(data[rule.field_id]) < new Date(rule.exclude);
              });
            }
          }
        });
      }
      return rulesValidation;
    },

    submit() {
      this.$v.$touch();
      this.submitted = true;

      if (!this.$v.$invalid) {
        let credentials = null;
        if (this.includeRegisterForm) {
          credentials = this.getCredentials();
        }

        const metaData = this.getMetaData();

        //Insert browser locale to metadata if needed
        if (this.localeField) {
          metaData[this.localeField] = this.currentLocale.localeCode;
        }

        const profileData = this.getProfileData();

        this.$emit("submit", { credentials, metaData, profileData });
      } else {
        this.scrollToFirstFieldWithError();
      }
    },

    getCredentials() {
      return {
        email: this.$data[this.registerFields.EMAIL].toLowerCase().replace(/\s/g, ""),
        password: this.$data[this.registerFields.PASSWORD],
        firstName: this.$data[this.registerFields.FIRST_NAME],
        lastName: this.$data[this.registerFields.LAST_NAME],
      };
    },

    getMetaData() {
      return this.formData.fields.reduce((object, field) => {
        if (this.fieldVisible(field)) {
          if (field.type === FormBuilderConstants.FIELD_TYPES.PHONE_NUMBER) {
            const phoneNumberWithPrefix = this.$data[field.id];
            if (phoneNumberWithPrefix && phoneNumberWithPrefix.phonePrefix && phoneNumberWithPrefix.phoneNumber) {
              object[field.id] = `${phoneNumberWithPrefix.phonePrefix.phonePrefix}${phoneNumberWithPrefix.phoneNumber}`;
            }
          } else if (field.type === FormBuilderConstants.FIELD_TYPES.INFORMATION) {
            object[field.id] = true;
          } else {
            object[field.id] = this.$data[field.id];
          }
        }
        return object;
      }, {});
    },

    getProfileData() {
      return this.formData.fields.reduce((object, field) => {
        if (field.related_profile_field && this.fieldVisible(field)) {
          object[field.related_profile_field] = this.$data[field.id];
        }
        return object;
      }, {});
    },

    scrollToFirstFieldWithError() {
      const scrollArgs = { behavior: "smooth", block: "start" };

      const registerFieldError = Object.values(this.registerFields).find(registerField => {
        return (
          registerField !== this.registerFields.TERMS &&
          registerField !== this.registerFields.GDPR &&
          this.$v[registerField] &&
          this.$v[registerField].$anyError
        );
      });

      if (registerFieldError) {
        this.$refs[registerFieldError].scrollIntoView(scrollArgs);
      } else {
        const fieldWithError = this.formData.fields.find(field => {
          return this.$v[field.id] && this.$v[field.id].$anyError;
        });
        if (fieldWithError) {
          let fieldVueComponent = this.$refs[fieldWithError.id];
          if (fieldVueComponent && fieldVueComponent.length) {
            fieldVueComponent[0].$el.scrollIntoView(scrollArgs);
          }
        }
      }
    },

    reRender() {
      this.clearCustomFields();
      this.reRenderKey = this.reRenderKey + 1;
    },

    clearCustomFields() {
      this.formData.fields.forEach(field => {
        this.$data[field.id] = null;
      });
    },

    reset() {
      this.reRender();
      // this.$v.$reset();
      this.submitted = false;
    },

    touch() {
      this.$v.$touch();
      this.submitted = true;
    },

    isValid() {
      return !this.$v.$anyError;
    },
  },

  watch: {
    currentLocale: {
      immediate: false,
      handler: function (newValue) {
        this.reRender();
      },
    },
  },
};
</script>

<style lang="scss">
.subtitle {
  white-space: pre-wrap;
}
</style>
