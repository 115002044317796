<template>
  <div class="pism-banner-module">
    <img src="@/assets/custom/pism_president_banner.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "Pism2024PresidentInfoBanner",
};
</script>

<style lang="scss" scoped>
.pism-banner-module {
  padding-top: 120px;
  padding-bottom: 60px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 946px) {
    padding-top: 0px;
    margin-top: -40px;
  }

  & img {
    width: 100%;
    max-width: 1320px;
  }
}
</style>
