<template>
  <div class="button is-link mr-1" @click="syncWithICal">
    <span class="icon">
      <repeat-icon class="svg-icon has-fill-primary-inverted"></repeat-icon>
    </span>
    <div>
      {{ $t("ical.ical_button") }}
    </div>
  </div>
</template>

<script>
import RepeatIcon from "@/assets/icons/repeat.svg";
import ICalSyncModal from "@/web/components/availability/ical/ICalSyncModal";

export default {
  name: "ICalSyncButton",

  components: { RepeatIcon },

  methods: {
    syncWithICal() {
      this.$buefy.modal.open({
        parent: this,
        component: ICalSyncModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<style scoped></style>
