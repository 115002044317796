import dayjs from "@/shared/utils/day";
import EventModel from "./models/EventModel";
import Constants from "@/web/constants";

export async function initEvent({ dispatch, commit, state }, { eventSlug, magicLinkToken }) {
  await dispatch("loadEvent", eventSlug);

  // Every event should have own authorization
  // Retrieve the saved access code and auth tokens
  await dispatch("retrieveAuthorization", magicLinkToken).catch(err => {
    // rethrow error related to missing system consents
    if (err.response.data.errors && err.response.data.errors.length) {
      const errorCode = err.response.data.errors[0].code;
      if (errorCode === Constants.REST_MISSING_SYSTEM_CONSENTS_ERROR) {
        throw err;
      }
    }
    commit("auth/clearToken");
  });

  await dispatch("loadEventData");
}

export async function loadEvent({ dispatch, commit }, slug) {
  const eventData = await EventModel.fetchByUrlSuffix(slug);
  commit("setEvent", eventData);

  // Setup default timezone
  dayjs.tz.setDefault(eventData.timezone);

  // Setup tab title
  document.title = eventData.title;

  if (eventData.logo) {
    const favicon = document.getElementById("favicon");
    //Custom change for PISM
    if (eventData.id === 1312) {
      favicon.href = require("@/assets/custom/pism/pism_favicon.png");
    } else {
      favicon.href = eventData.logo.file_thumbnail_200_url;
    }
  }
}

export async function loadEventData({ dispatch, getters }) {
  const hasAccess = getters["hasAccess"];

  // Load event data only when accessible
  if (hasAccess) {
    await dispatch("loadComponents");
    await dispatch("loadModules");
    await dispatch("pusher/startPusherListening");
  }
}

export async function retrieveAuthorization({ dispatch, state, getters, commit }, magicLinkToken) {
  const eventId = state.eventId;
  const hasDisabledAuth = getters["hasDisabledAuth"];

  await dispatch("accessCode/retrieveAccessCode", eventId);

  if (!hasDisabledAuth) {
    await dispatch("auth/retrieveToken", eventId);
  }

  if (magicLinkToken) {
    await dispatch("auth/loginWithMagicToken", { magicLinkToken, eventId }).catch(err => {
      console.error(err);
    });
  }

  if (state.auth.token) {
    await dispatch("loadUserData");

    const isMetaDataSubmitted = await dispatch("currentUser/checkIsUserMetaSubmitted");
    if (!isMetaDataSubmitted) {
      commit("setAskUserToSubmitMetadata", true);
    }
  }
}

export async function loadComponents({ dispatch, state }) {
  const eventId = state.eventId;
  await dispatch("components/fetchComponents", { eventId });
}

export async function loadModules({ dispatch, state, getters }) {
  const eventId = state.eventId;
  const modules = getters["visibleModules"];

  // eslint-disable-next-line prettier/prettier
  // await Promise.all([
  dispatch("loadEventTicketModules", { eventId, modules });
  dispatch("loadExhibitorModules", { eventId, modules });
  dispatch("loadSpeakersModules", { eventId, modules });
  dispatch("loadQuizModules", { eventId, modules });
  dispatch("loadPartnerComponents", { eventId, modules });
  dispatch("loadBusinessMatchingComponents", { eventId, modules });
  // ]);
}

export async function loadEventTicketModules({ dispatch }, { eventId, modules }) {
  // const eventTicketModules = filterModulesByType(modules, Constants.MODULE_EVENT_TICKETS);
  // if (eventTicketModules && eventTicketModules.length > 0) {
  await dispatch("eventTickets/fetch", { eventId });
  // }
}

export async function loadQuizModules({ dispatch }, { eventId, modules }) {
  const quizModules = filterModulesByType(modules, Constants.MODULE_QUIZZES);
  const componentIds = quizModules.map(module => module.component_id);
  const uniqueIds = filterUnique(componentIds);

  // eslint-disable-next-line prettier/prettier
  const loadRequests = uniqueIds.map(componentId => dispatch("quizzes/loadQuizzes", componentId));

  await Promise.all(loadRequests);
}

export async function loadExhibitorModules({ dispatch }, { eventId, modules }) {
  const exhibitorModules = filterModulesByType(modules, Constants.MODULE_COMPONENT_EXHIBITORS);
  const componentIds = exhibitorModules.map(module => module.component_id);
  const uniqueIds = filterUnique(componentIds);

  // eslint-disable-next-line prettier/prettier
  const loadRequests = uniqueIds.map(componentId => dispatch("exhibitors/loadExhibitors", { eventId, componentId }));

  await Promise.all(loadRequests);
}

export async function loadSpeakersModules({ dispatch }, { eventId, modules }) {
  const speakerModules = filterModulesByType(modules, Constants.MODULE_COMPONENT_SPEAKERS);
  const componentIds = speakerModules.map(module => module.component_id);
  const uniqueIds = filterUnique(componentIds);

  // eslint-disable-next-line prettier/prettier
  const loadRequests = uniqueIds.map(componentId => dispatch("speakers/loadSpeakers", { eventId, componentId }));

  await Promise.all(loadRequests);
  // if (uniqueIds.length) {
  await dispatch("loadAgendaComponents", { eventId });
  // }
}

export async function loadAgendaComponents({ dispatch, getters }, { eventId }) {
  const agendaComponents = getters["components/agendaComponents"];
  const componentIds = agendaComponents.map(component => component.id);
  const uniqueIds = filterUnique(componentIds);

  // eslint-disable-next-line prettier/prettier
  const loadRequests = uniqueIds.map(componentId =>
    dispatch("speakers/fetchSpeakerAgendaSessions", {
      eventId,
      componentId,
    })
  );

  await Promise.all(loadRequests);
}

export async function loadBusinessMatchingComponents({ dispatch }, { eventId, modules }) {
  const businessMatchingModules = filterModulesByType(modules, Constants.MODULE_BUSINESS_MATCHING);
  const componentIds = businessMatchingModules.map(module => module.component_id);
  await dispatch("businessMatchingMeetings/fetchMeetingsFromComponents", componentIds);
  await dispatch("businessMatchingFilters/fetchFiltersFromEvent", eventId);
}

export async function loadPartnerComponents({ dispatch, getters }, { eventId, modules }) {
  const partnerModules = filterModulesByType(modules, Constants.MODULE_COMPONENT_PARTNERS);
  const componentIds = partnerModules.map(module => module.component_id);
  const uniqueIds = filterUnique(componentIds);

  if (uniqueIds.length) {
    const loadRequests = uniqueIds.map(componentId => dispatch("partners/fetch", { eventId, componentId }));

    await Promise.all(loadRequests);
  }
}

function filterModulesByType(modules, typeName) {
  return modules.filter(module => module.type === typeName);
}

function filterUnique(ids) {
  return ids.filter((val, index, self) => self.indexOf(val) === index);
}

export async function checkAccessCode({ dispatch, state }, accessCode) {
  const eventId = state.eventId;
  await dispatch("accessCode/checkAccessCode", { eventId, accessCode });
}

export async function login({ dispatch, commit, state }, { credentials }) {
  const eventId = state.eventId;
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.LOGGING_IN);
  await dispatch("auth/login", { ...credentials, eventId });
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function sendMagicLink({ dispatch, commit, state }, email) {
  const eventId = state.eventId;
  await dispatch("auth/sendMagicLink", { email, eventId });
}

export async function requestFacebookToken({ dispatch, getters }) {
  await dispatch("facebook/requestFacebookToken", getters["facebookAppId"]);
}

export async function loginViaFacebook({ dispatch, commit, state }, gdprConsent) {
  const eventId = state.eventId;
  const accessToken = state.facebook.accessToken;
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.LOGGING_IN);
  await dispatch("auth/loginViaFacebook", { eventId, accessToken, gdprConsent });
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function requestAppleToken({ dispatch, getters }) {
  await dispatch("apple/requestAppleToken", getters["appleClientId"]);
}

export async function loginViaApple({ dispatch, commit, state }, { gdprConsent, firstName, lastName }) {
  const eventId = state.eventId;
  const appleToken = state.apple.appleToken;
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.LOGGING_IN);
  await dispatch("auth/loginViaApple", { eventId, appleToken, gdprConsent, firstName, lastName });
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function loginViaGoogle({ dispatch, commit, state }, gdprConsent) {
  const eventId = state.eventId;
  const googleToken = state.google.googleToken;
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.LOGGING_IN);
  await dispatch("auth/loginViaGoogle", { eventId, googleToken, gdprConsent });
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function register({ dispatch, commit, state }, { credentials }) {
  const eventId = state.eventId;

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SIGNING_UP);
  await dispatch("auth/register", { ...credentials, eventId });

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function registerWithAdditionalData({ dispatch, commit, state }, { credentials, metaData, profileData }) {
  const eventId = state.eventId;

  if (credentials) {
    await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SIGNING_UP);
    await dispatch("auth/register", { ...credentials, eventId: eventId, metaData: metaData });
  }
}

export async function submitAdditionalData({ dispatch, commit, state }, { metaData, profileData }) {
  const eventId = state.eventId;

  // await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUBMITTING_REQUIRED_DATA);
  if (metaData) {
    await dispatch("currentUser/updateUserMeta", { eventId: eventId, metadata: metaData });
  }

  if (profileData) {
    await dispatch("currentUser/updateUser", profileData);
  }

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function submitMissingSystemConsents({ dispatch, commit, state }, { acceptedConsents }) {
  const eventId = state.eventId;

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUBMITTING_REQUIRED_DATA);
  await dispatch("consents/submitMissingSystemConsents", acceptedConsents);
  await dispatch("auth/refreshToken", eventId);

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_USER_DATA);
  await dispatch("loadUserData");

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
  await dispatch("auth/joinToEvent", eventId);

  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.DOWNLOADING_EVENT_DATA);
  await dispatch("loadEventData");
  await commit("auth/setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.SUCCESS);
}

export async function logout({ dispatch, commit, state }) {
  const eventId = state.eventId;

  await dispatch("pusher/stopPusherListening");
  await dispatch("auth/logout", eventId);
  await dispatch("facebook/logout");
  await dispatch("clearUserData");
  await commit("sideMenu/resetSideMenuPanel");
  await commit("payment/clearForms");

  // Reload event data on logout
  await dispatch("loadEventData");
}

export async function deleteAccount({ dispatch, state }) {
  const eventId = state.eventId;
  await dispatch("auth/deleteAccount", eventId);
  await dispatch("clearUserData");

  // Reload event data on account deletion
  await dispatch("loadEventData");
}

export async function loadUserData({ dispatch, state, getters }) {
  const eventId = state.eventId;
  await dispatch("currentUser/loadUser");
  await dispatch("userTickets/loadTickets", eventId);

  dispatch("moderatorAccesses/fetchAccesses");
  dispatch("friends/loadInvitations", { showNotification: false });
  dispatch("notifications/refreshNotifications", { showNotification: false });
  if (getters["inboxEnabled"]) {
    dispatch("inboxThreads/loadInboxThreads");
  }
}

export async function clearUserData({ commit, dispatch }) {
  await dispatch("currentUser/clearUser");
  await dispatch("userTickets/clearTickets");
  await dispatch("notifications/clearNotifications");
  await dispatch("inbox/clearInbox");
  await dispatch("attendees/clearAttendees");
  await dispatch("friends/clearFriends");
  await dispatch("quizzes/clearQuizzes");
  await dispatch("feedWall/clearFeedWall");
}

export async function preparePayment({ commit, getters }) {
  if (getters["currentUser/currentUser"]) {
    const { first_name, last_name, email, company, position } = getters["currentUser/currentUser"];
    commit("payment/setPersonalForm", {
      firstName: first_name,
      lastName: last_name,
      email,
      company,
      position,
      thisIsMe: true,
    });
  }
  commit("payment/setAdditionalForm", null);
  commit("payment/setInvoiceForm", null);
}

export async function buyTicket({ dispatch, state }, { gdprConsent }) {
  const eventId = state.eventId;
  await dispatch("payment/pay", { eventId, gdprConsent });
}

export async function deepReloadEventData({ dispatch, rootState }) {
  const eventSlug = rootState.slug;
  await dispatch("loadEvent", eventSlug);
  await dispatch("loadEventData");
}
