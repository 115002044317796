<template>
  <div class="admin-panel-container" v-if="isVisible">
    <div class="is-flex is-flex-shrink-1">
      <div class="is-flex is-flex-direction-row is-justify-content-center">
        <div class="admin-panel-box" v-prevent-parent-scroll v-if="isOpened">
          <!--EDIT MODE-->
          <div class="is-flex is-flex-direction-row mb-1 is-justify-content-center">
            <div class="title">{{ $t("admin_panel.main_title") }}</div>
            <b-button class="ml-1 is-small" type="is-primary" @click="updateLpConfig">{{ $t("admin_panel.main_deploy") }} </b-button>
          </div>
          <div class="is-flex is-flex-direction-row mb-1 is-justify-content-center">
            <b-field>
              <b-switch v-model="editModeEnabled">{{ $t("admin_panel.main_live_preview") }}</b-switch>
            </b-field>
            <b-button class="ml-1 is-small" type="is-warning" @click="goBack" v-if="currentSubpanel"
              >{{ $t("admin_panel.main_back_home") }}
            </b-button>
          </div>

          <div v-if="!currentSubpanel">
            <!--EDIT BUTTONS-->
            <b-button type="is-primary" class="is-fullwidth" @click="openSubpanel(SUBPANELS.MODULES)"
              >{{ $t("admin_panel.main_modules_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.HEADER)"
              >{{ $t("admin_panel.main_header_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.TIMER)"
              >{{ $t("admin_panel.main_timer_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.THEME)"
              >{{ $t("admin_panel.main_theme_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.INBOX)"
              >{{ $t("admin_panel.main_inbox_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.SIDE_MENU)"
              >{{ $t("admin_panel.main_side_menu_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.HELP)"
              >{{ $t("admin_panel.main_help_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.FEATURES)"
              >{{ $t("admin_panel.main_extra_features_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.NAVBAR)"
              >{{ $t("admin_panel.main_navbar_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.FOOTER)"
              >{{ $t("admin_panel.main_footer_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.PAYMENT)"
              >{{ $t("admin_panel.main_payment_button") }}
            </b-button>
            <b-button type="is-primary" class="is-fullwidth mt-1" @click="openSubpanel(SUBPANELS.TRANSLATIONS)"
              >{{ $t("admin_panel.main_translations_button") }}
            </b-button>
          </div>

          <component v-if="currentSubpanel" :is="subpanelComponent" class="mt-2" @navigate-back="goBack" ref="currentSubpanel"></component>
        </div>
        <template v-if="isOpened">
          <div v-if="visibleSidePanel === SIDE_PANELS.TRANSLATIONS" class="admin-panel-box" v-prevent-parent-scroll>
            <admin-translations-configuration :selectable="true" @close="closeSidePanel"></admin-translations-configuration>
          </div>
          <div v-else-if="visibleSidePanel === SIDE_PANELS.USERS" class="admin-panel-box" v-prevent-parent-scroll>
            <admin-panel-user-picker @user-uuid-selected="closeSidePanel"></admin-panel-user-picker>
          </div>
        </template>
        <div class="admin-open-button" @click="toggleMenu">
          <transition name="fade" mode="out-in">
            <left-arrow-icon class="svg-icon has-stroke-primary" key="closeIcon" v-if="isOpened"></left-arrow-icon>
            <right-arrow-icon class="svg-icon has-stroke-primary" key="menuIcon" v-else></right-arrow-icon>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RightArrowIcon from "@/assets/arrow-right.svg";
import LeftArrowIcon from "@/assets/arrow-left.svg";
import AdminHeaderConfiguration from "@/web/components/admin/subpanels/AdminHeaderConfiguration";
import AdminTimerConfiguration from "@/web/components/admin/subpanels/AdminTimerConfiguration";
import AdminThemeConfiguration from "@/web/components/admin/subpanels/AdminThemeConfiguration";
import AdminInboxConfiguration from "@/web/components/admin/subpanels/AdminInboxConfiguration";
import AdminSideMenuConfiguration from "@/web/components/admin/subpanels/AdminSideMenuConfiguration";
import AdminTranslationsConfiguration from "@/web/components/admin/subpanels/AdminTranslationsConfiguration";
import AdminFeaturesConfiguration from "@/web/components/admin/subpanels/AdminFeaturesConfiguration";
import AdminHelpConfiguration from "@/web/components/admin/subpanels/AdminHelpConfiguration";
import AdminNavbarConfiguration from "@/web/components/admin/subpanels/AdminNavbarConfiguration";
import AdminFooterConfiguration from "@/web/components/admin/subpanels/AdminFooterConfiguration";
import AdminPaymentConfiguration from "@/web/components/admin/subpanels/AdminPaymentConfiguration";
import AdminModulesConfiguration from "@/web/components/admin/subpanels/AdminModulesConfiguration";
import AdminPanelUserPicker from "@/web/components/admin/subpanels/modules/views/AdminPanelUserPicker";

const SUBPANELS = {
  HEADER: "header",
  TIMER: "timer",
  THEME: "theme",
  INBOX: "inbox",
  REGISTER: "register",
  SIDE_MENU: "side_menu",
  HELP: "help",
  NAVBAR: "navbar",
  FOOTER: "footer",
  TRANSLATIONS: "translations",
  MODULES: "modules",
  FEATURES: "features",
  PAYMENT: "payment",
};

const SIDE_PANELS = {
  TRANSLATIONS: "translations",
  USERS: "users",
};

export default {
  name: "AdminPanel",

  components: { AdminTranslationsConfiguration, AdminPanelUserPicker, RightArrowIcon, LeftArrowIcon },

  data() {
    return {
      isOpened: false,
      editModeEnabled: false,
      currentSubpanel: null,
      //side panel
      visibleSidePanel: null,
    };
  },

  created() {
    this.$root.$on("select-translation-key", this.openTranslationsSidePanel);
    this.$root.$on("select-user-uuid", this.openUserPickerSidePanel);
    this.$root.$on("close-side-panel", this.closeSidePanel);
  },

  beforeDestroy() {
    this.$root.$off("select-translation-key", this.openTranslationsSidePanel);
    this.$root.$off("select-user-uuid", this.openUserPickerSidePanel);
    this.$root.$off("close-side-panel", this.closeSidePanel);
  },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),

    SUBPANELS: () => SUBPANELS,
    SIDE_PANELS: () => SIDE_PANELS,

    isVisible() {
      return this.isAdmin;
    },

    subpanelComponent() {
      if (this.currentSubpanel === SUBPANELS.HEADER) {
        return AdminHeaderConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.TIMER) {
        return AdminTimerConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.THEME) {
        return AdminThemeConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.INBOX) {
        return AdminInboxConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.SIDE_MENU) {
        return AdminSideMenuConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.FEATURES) {
        return AdminFeaturesConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.HELP) {
        return AdminHelpConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.NAVBAR) {
        return AdminNavbarConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.FOOTER) {
        return AdminFooterConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.TRANSLATIONS) {
        return AdminTranslationsConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.PAYMENT) {
        return AdminPaymentConfiguration;
      } else if (this.currentSubpanel === SUBPANELS.MODULES) {
        return AdminModulesConfiguration;
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setLivePreview"]),
    ...mapActions("adminPanel", ["updateLpConfig"]),

    toggleMenu() {
      this.isOpened = !this.isOpened;
    },

    openSubpanel(subpanel) {
      this.currentSubpanel = subpanel;
    },

    goBack() {
      if (this.currentSubpanel) {
        this.currentSubpanel = null;
      }
      this.visibleSidePanel = null;
    },

    openTranslationsSidePanel() {
      this.visibleSidePanel = SIDE_PANELS.TRANSLATIONS;
    },

    openUserPickerSidePanel() {
      this.visibleSidePanel = SIDE_PANELS.USERS;
    },

    closeSidePanel() {
      this.visibleSidePanel = null;
    },
  },

  watch: {
    editModeEnabled: {
      immediate: false,
      handler: function (newValue) {
        this.setLivePreview(newValue);
      },
    },
  },
};
</script>

<style scoped>
.admin-panel-box::v-deep * {
  color: black !important;
}
</style>
