<template>
  <page-layout class="quiz-leaderboard">
    <h1>{{ $t("quiz_leaderboard.title") }}</h1>

    <div v-if="hasLoadingError && leaderboard.length === 0" class="loading-error">
      <p>{{ $t("quiz_leaderboard.not_loaded_error") }}</p>
    </div>

    <div v-else-if="isLoaded || leaderboard.length > 0" class="leaderboard-table">
      <h2>{{ componentName }}</h2>

      <leaderboard>
        <template v-if="leaderboard.length">
          <leaderboard-row
            v-for="(row, place) of leaderboard"
            :key="row.user.user_identity_token"
            :place="place + 1"
            :value="row"
          ></leaderboard-row>
        </template>

        <template v-else>
          <p>{{ $t("quiz_leaderboard.no_results") }}</p>
        </template>
      </leaderboard>
    </div>

    <div v-else class="page-loader">
      <spinner></spinner>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/web/components/external/ExternalPageLayout";
import Leaderboard from "@/web/components/external/Leaderboard";
import LeaderboardRow from "@/web/components/external/LeaderboardRow";
import Spinner from "@/web/components/external/Spinner";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    PageLayout,
    Leaderboard,
    LeaderboardRow,
    Spinner,
  },

  data() {
    return {
      leaderboardUUID: null,
    };
  },

  mounted() {
    const urlSegments = window.location.pathname.split("/").filter(s => !!s);
    this.leaderboardUUID = urlSegments[2];

    this.loadLeaderboard(this.leaderboardUUID).finally(() => this.continueLoading());
  },

  destroyed() {
    clearTimeout(this.timeoutId);
  },

  methods: {
    ...mapActions("quizLeaderboard", ["loadLeaderboard"]),

    continueLoading() {
      this.timeoutId = setTimeout(() => {
        this.loadLeaderboard(this.leaderboardUUID);
        this.continueLoading();
      }, 2500);
    },
  },

  computed: {
    ...mapState("quizLeaderboard", ["leaderboard", "componentName"]),
    ...mapGetters("quizLeaderboard", ["isLoaded", "hasLoadingError"]),
  },
};
</script>

<style lang="scss" scoped>
.quiz-leaderboard h1 {
  margin: 0 0 10px 0;
}

.quiz-leaderboard h2 {
  margin: 0 0 40px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.leaderboard-table {
  margin-bottom: 20px;
}

p {
  margin: 0;
  padding: 20px 20px;
}
</style>
