<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <meeting-app-widget
        ref="meetingAppWidget"
        @open-user-modal="openUserModal"
        @open-auth="openAuth"
        @open-agenda-session-attendees="openAgendaSessionAttendees"
        @open-agenda-session-questions="openAgendaSessionQuestions"
        @request-refresh-token="requestRefreshToken"
        @show-notification="showNotification"
        @open-auth-lw-stream="openAuthLwStream"
        :slug="slug"
        :component-id="componentId"
        :theme="theme"
        :token="token"
        :access-code="accessCode"
        :locale="currentLocaleCode"
        :lp-mode="lpMode"
        :lp-theme="lpTheme"
        :lp-font="lpFont"
        :page-size="pageSize"
        :custom-sponsors="customSessionSponsors"
        :component-ids-with-rating-always-enabled="componentIdsWithRatingAlwaysEnabled"
        :hide-search="hideSearch"
        :hide-days="hideDays"
        :hide-session-time="hideSessionTime"
        :hide-live-stream-buttons="hideLiveStreamButtons"
        :show-live-indicator="showLiveIndicator"
        :livewebinar-only-for-authorized="livewebinarOnlyForAuthorized"
        :questions-disabled="questionsDisabled"
        :speakers-count-on-preview="speakersCountOnPreview"
      ></meeting-app-widget>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import Constants from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "AgendaComponent",
  mixins: [RedirectMixin, LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isRefreshing: false,
    };
  },

  computed: {
    ...mapState(["settings"]),
    ...mapState("auth", ["token"]),
    ...mapState("accessCode", ["accessCode"]),
    ...mapState(["slug", "eventId"]),
    ...mapGetters("locales", ["currentLocale"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("currentUser", ["email", "fullName"]),
    ...mapGetters("userTickets", ["ticket"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,
    agendaModuleFields: () => LpConfigConstants.AGENDA_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.PAGE_SIZE, 8);
    },

    customSessionSponsors() {
      if (this.agendaModuleFields.CUSTOM_SPONSORS in this.moduleData) {
        return JSON.stringify(this.moduleData[this.agendaModuleFields.CUSTOM_SPONSORS]);
      }
      return null;
    },

    lpMode() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.LP_MODE, true);
    },

    lpTheme() {
      let theme = this.getFieldValueOrDefault(this.settings, LpConfigConstants.ROOT_FIELDS.THEME, {});
      return this.getFieldValueOrDefault(theme, LpConfigConstants.THEME_FIELDS.THEME_TYPE, LpConfigConstants.THEME_TYPES.SIMPLE_DAY);
    },

    lpFont() {
      let theme = this.getFieldValueOrDefault(this.settings, LpConfigConstants.ROOT_FIELDS.THEME, {});
      return this.getFieldValueOrDefault(theme, LpConfigConstants.THEME_FIELDS.FONT, LpConfigConstants.FONT_TYPES.NUNITO);
    },

    theme() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.THEME, "default");
    },

    componentId() {
      if (window.location.host.includes("kongres.lignumsoft")) {
        return 14924;
      } else if (window.location.host.includes("architekt.lignumsoft")) {
        return 15700;
      } else {
        return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
      }
    },

    componentIdsWithRatingAlwaysEnabled() {
      if (this.agendaModuleFields.COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED in this.moduleData) {
        return JSON.stringify(this.moduleData[this.agendaModuleFields.COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED]);
      }
      return null;
    },

    hideSearch() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.HIDE_SEARCH, false);
    },

    hideLiveStreamButtons() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.HIDE_LIVE_STREAM_BUTTONS, false);
    },

    showLiveIndicator() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.SHOW_LIVE_INDICATOR, false);
    },

    hideSessionTime() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.HIDE_SESSION_TIME, false);
    },

    hideDays() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.HIDE_DAYS, false);
    },

    livewebinarOnlyForAuthorized() {
      return this.getFieldValueOrDefault(this.moduleData, this.agendaModuleFields.LIVEWEBINAR_ONLY_FOR_AUTHORIZED, false);
    },

    currentLocaleCode() {
      return this.currentLocale.localeCode;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    questionsDisabled() {
      return this.isPismEvent && !this.ticket;
    },

    speakersCountOnPreview() {
      return this.isPismEvent ? 10000 : 3;
    },
  },

  methods: {
    ...mapActions("sideMenu", ["openAgendaSessionQuestionsBubble"]),
    ...mapActions("auth", ["refreshToken"]),
    ...mapMutations("notifications", ["setStandardNotification"]),

    openUserModal(event) {
      if (event && event.detail && event.detail.length) {
        const userId = event.detail[0];
        this.$root.openUserModal(userId);
      }
    },

    openAuth() {
      const query = { returnUrl: this.$route.fullPath };
      this.pushWithRedirect({ name: Constants.ROUTE_LOGIN, query });
    },

    openAgendaSessionAttendees(event) {
      if (event && event.detail && event.detail.length) {
        const params = { ...event.detail[0] };
        this.$router.push({ name: Constants.ROUTE_AGENDA_SESSION_ATTENDEES, params });
      }
    },

    openAgendaSessionQuestions(event) {
      if (event && event.detail && event.detail.length) {
        const params = { ...event.detail[0] };
        this.openAgendaSessionQuestionsBubble({
          agendaComponentId: params.componentId,
          agendaSessionId: params.agendaSessionId,
        });
      }
    },

    requestRefreshToken() {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshToken(this.eventId).finally(() => {
          this.isRefreshing = false;
        });
      }
    },

    showNotification(event) {
      if (event && event.detail && event.detail.length) {
        const params = { ...event.detail[0] };
        this.setStandardNotification(params);
      }
    },

    openAuthLwStream(event) {
      if (event && event.detail && event.detail.length) {
        const livestreamUrl = event.detail[0];
        if (this.isAuthorized || !this.livewebinarOnlyForAuthorized) {
          const url = this.addUserQueryParamsToLivewebinarUrl(livestreamUrl);
          window.open(url, "_blank");
        } else {
          this.$root.openLoginModal();
        }
      }
    },

    addUserQueryParamsToLivewebinarUrl(livestreamUrl) {
      const url = new URL(livestreamUrl);
      if (this.fullName) {
        url.searchParams.append("_nickname", this.fullName);
      }
      if (this.email) {
        url.searchParams.append("_email", this.email);
      }
      return url;
    },
  },
};
</script>
