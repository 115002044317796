import Constants from "@/web/constants";

export async function login(appId) {
  const APPLE = await loadAppleSingleton(appId);
  return window.AppleID.auth.signIn();
  // return new Promise(async (resolve, reject) => {
  //   const handleLogin = response => {
  //     console.log(response);
  //     if (response.authResponse) {
  //       resolve(response.authResponse);
  //     } else {
  //       const err = new Error("User cancelled login or did not fully authorize");
  //       reject(err);
  //     }
  //   };
  //   try {
  //     const data = await ;
  //     // Handle successful response.
  //   } catch ( error ) {
  //     // Handle error.
  //   }
  // });
}

export async function logout() {
  if (window.AppleID && window.AppleID.auth.signOut) {
    try {
      await window.AppleID.auth.signOut();
    } catch (error) {
      //no-op
    }
  }
}

function loadAppleSingleton(appId) {
  if (!loadAppleSingleton.promise) {
    loadAppleSingleton.promise = loadAppleScript();
  }

  return loadAppleSingleton.promise.then(() => {
    initApple(appId);
    return window.APPLE;
  });
}

function loadAppleScript() {
  const script = document.createElement("script");
  script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
  script.crossOrigin = "anonymous";
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load apple script`));
  });
}

function initApple(clientID) {
  window.AppleID.auth.init({
    clientId: clientID,
    scope: "name email",
    usePopup: true,
    redirectURI: Constants.APPLE_REDIRECT_URL,
  });
}

export default {
  login,
  logout,
};
