<template>
  <article class="module">
    <div class="container">
      <div class="rzecommerce-benefits columns is-mobile is-multiline">
        <div class="rzecommerce-benefits__image">
          <img class="rzecommerce-benefits-img" src="@/assets/custom/rzecommerce/rze_benefits.png" alt="Benefits Image" />
        </div>
        <div class="rzecommerce-benefits__content">
          <h1 class="benefits-title mb-2">
            {{ $t("custom.rzecommerce_benefits_title") }}
            <br /><span class="smaller">{{ $t("custom.rzecommerce_benefits_title_2") }}</span>

          </h1>

          <div class="rzecommerce-benefits__list">
            <div v-for="(item, index) in benefitItems" :key="index" class="rzecommerce-benefits__item">
              <div class="rzecommerce-benefits__item-header" @click="toggleItem(index)">
                <span class="benefit">{{ item.title }}</span>
                <span class="toggle-icon">{{ activeIndex === index ? "-" : "+" }}</span>
              </div>
              <transition name="benefit-toggle" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div v-if="activeIndex === index" class="rzecommerce-benefits__item-body" ref="benefitBody">
                  <span v-if="item.isHtml" v-html="item.description"></span>
                  <span v-else>{{ item.description }}</span>
                </div>
              </transition>
            </div>
          </div>

          <div class="rzecommerce-benefits__footer">
            <p class="footer-text has-text-weight-bold">{{ $t("custom.rzecommerce_benefits_footer_text") }}</p>
          </div>
        </div>


      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "RzecommerceBenefitsComponent",

  mixins: [LpConfigMixin],

  data() {
    return {
      benefitItems: [
        {
          title: this.$t("custom.rzecommerce_benefit_1_head"),
          description: this.$t("custom.rzecommerce_benefit_1_body"),
          isHtml: false,
        },
        {
          title: this.$t("custom.rzecommerce_benefit_2_head"),
          description: this.$t("custom.rzecommerce_benefit_2_body"),
          isHtml: false,
        },
        {
          title: this.$t("custom.rzecommerce_benefit_3_head"),
          description: this.$t("custom.rzecommerce_benefit_3_body"),
          isHtml: false,
        },
        {
          title: this.$t("custom.rzecommerce_benefit_4_head"),
          description: this.$t("custom.rzecommerce_benefit_4_body"),
          isHtml: false,
        },
        {
          title: this.$t("custom.rzecommerce_benefit_5_head"),
          description: this.$t("custom.rzecommerce_benefit_5_body"),
          isHtml: false,
        },
      ],
      activeIndex: null,
    };
  },

  methods: {
    toggleItem(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.addEventListener(
        "transitionend",
        () => {
          el.style.height = "auto";
        },
        { once: true }
      );
    },
    leave(el) {
      el.style.height = `${el.scrollHeight}px`;
      // Trigger reflow
      void el.offsetHeight;
      el.style.height = "0";
    },
  },
};
</script>

<style scoped lang="scss">
.module {
  margin-bottom: 140px;
}

.rzecommerce-benefits {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  flex-direction: row;
  box-sizing: border-box;

  @media (max-width: 920px) {
    justify-content: center;

    &__image {
      margin-bottom: 24px;

      & img {
        width: 100%;
        height: auto;
        max-height: 500px;
      }
    }

    &__content {
      width: 100%;
    }
  }

  & .benefits-title {
    color: #fff;
    font-size: 60px;
    line-height: 120%;
    font-weight: 900;

    & .smaller {
      font-size: 42px;
    }

    @media (max-width: 920px) {
      font-size: 42px;

      & .smaller {
        font-size: 32px;
      }
    }

    @media (max-width: 480px) {
      font-size: 36px;

      & .smaller {
        font-size: 28px;
      }
    }
  }

  &__image {
    height: 100%;
    flex: 1 1 36%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 600px;

    & img {
      height: 100%;
      width: auto;
      object-fit: contain;
      border-radius: 14px;
    }
  }

  &__content {
    flex: 2 1 60%;
    background-color: var(--primary-color);
    border-radius: 14px;
    padding: 36px;
    min-width: 0;
  }

  &__list {
    .rzecommerce-benefits__item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 12px 0;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;

        & .benefit {
          color: #fff;
          font-weight: 600;
          font-size: 22px;
          flex: 1;

          @media (max-width: 920px) {
            font-size: 20px;
          }

          @media (max-width: 480px) {
            font-size: 18px;
          }
        }

        .toggle-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background-color: #fff;
          border-radius: 50%;
          font-size: 24px;
          color: var(--primary-color);
          margin-left: 12px;

          @media (max-width: 920px) {
            width: 40px;
            height: 40px;
            font-size: 20px;
          }

          @media (max-width: 480px) {
            width: 36px;
            height: 36px;
            font-size: 18px;
          }
        }
      }

      &-body {
        font-size: 18px;
        line-height: 1.5;
        color: #fff;
        overflow: hidden;
        padding-top: 12px;

        @media (max-width: 920px) {
          font-size: 16px;
        }

        @media (max-width: 480px) {
          font-size: 15px;
        }
      }
    }
  }

  &__footer {
    margin-top: 24px;
    text-align: left;

    .footer-text {
      color: #fff;
      white-space: pre-line;
      font-size: 26px;
      font-weight: 700;

      @media (max-width: 920px) {
        font-size: 22px;
      }

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
}

.benefit-toggle-enter-active,
.benefit-toggle-leave-active {
  transition: height 0.3s ease;
}

.benefit-toggle-enter-from,
.benefit-toggle-leave-to {
  height: 0;
  overflow: hidden;
}

.benefit-toggle-enter-to,
.benefit-toggle-leave-from {
  height: auto;
  overflow: hidden;
}
</style>
