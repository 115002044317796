<template>
  <article class="module is-paddingless" v-if="isSliderView">
    <separator class="separator is-primary" v-if="separatorVisible"></separator>
    <h2 class="title primary-text-color is-2 has-text-centered mb-4" v-if="title">
      {{ title }}
    </h2>
    <partners-slider-view
      class="slider"
      :partners="allPartners"
      :component-id="componentId"
      :open-external-link="openExternalLink"
      :is-mobile="isMobile"
      :bg-card-color="cardBackgroundColor"
    ></partners-slider-view>
  </article>
  <article class="module" v-else>
    <separator class="separator is-primary" v-if="separatorVisible"></separator>
    <h2 class="title primary-text-color is-2 has-text-centered mb-4" v-if="title">
      {{ title }}
    </h2>
    <div v-if="isLoading">
      <list-loading-indicator :active="true"></list-loading-indicator>
    </div>
    <div v-else v-for="category in categories" :key="category.id" class="mb-6">
      <h2 class="title partner-category-title has-text-centered mb-4">
        {{ category.name }}
      </h2>
      <div class="carousel-category-container" v-if="carouselEnabled" :style="{ background: carouselBackgroundColor }">
        <div class="container">
          <partners-carousel-view
            :bg-card-color="cardBackgroundColor"
            :category="category"
            :component-id="componentId"
            :is-mobile="isMobile"
            :open-external-link="openExternalLink"
          ></partners-carousel-view>
        </div>
      </div>

      <div class="container" v-else>
        <partners-view
          :category="category"
          :component-id="componentId"
          :open-external-link="openExternalLink"
          :bg-card-color="cardBackgroundColor"
        ></partners-view>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import PartnersCarouselView from "@/web/components/partners/PartnersCarouselView";
import PartnersView from "@/web/components/partners/PartnersView";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import PartnersSliderView from "@/web/components/partners/PartnersSliderView";

export default {
  name: "PartnersComponent",
  mixins: [LpConfigMixin],

  components: {
    PartnersSliderView,
    Separator,
    PartnersCarouselView,
    ListLoadingIndicator,
    PartnersView,
  },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isMobile: false,
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
  },

  computed: {
    ...mapGetters("partners", ["allPartnersCategories", "isLoading"]),

    partnersModuleFields: () => LpConfigConstants.PARTNERS_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    componentId() {
      return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    categories() {
      const componentId = this.componentId;
      return this.allPartnersCategories(componentId);
    },

    allPartners() {
      return this.categories.reduce((acc, category) => {
        return [...category.partners, ...acc];
      }, []);
    },

    carouselEnabled() {
      return !this.getFieldValueOrDefault(this.moduleData, this.partnersModuleFields.CAROUSEL_DISABLED, false);
    },

    openExternalLink() {
      return this.getFieldValueOrDefault(this.moduleData, this.partnersModuleFields.OPEN_EXTERNAL_LINK, false);
    },

    isSliderView() {
      return (
        this.getFieldValueOrDefault(
          this.moduleData,
          this.partnersModuleFields.VIEW_TYPE,
          LpConfigConstants.PARTNERS_MODULE_VIEW_TYPES.CATEGORIES
        ) === LpConfigConstants.PARTNERS_MODULE_VIEW_TYPES.SLIDER
      );
    },

    carouselBackgroundColor() {
      return this.getFieldValueOrDefault(this.moduleData, this.partnersModuleFields.CAROUSEL_BACKGROUND_COLOR, "#ffffff");
    },

    cardBackgroundColor() {
      return this.getFieldValueOrDefault(
        this.moduleData,
        this.partnersModuleFields.CARD_BACKGROUND_COLOR,
        "var(--partner-item-background-color)"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  background-color: white;
}

.partner-category-title {
  font-size: var(--partners-category-title-font-size);
}
</style>
