<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("delete_account.delete_account_title") }}
      </h1>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered">
        {{ $t("delete_account.delete_account_description") }}
      </div>

      <div class="is-flex is-flex-direction-row mt-1">
        <button class="button is-light" @click="$parent.close">
          {{ $t("common.cancel") }}
        </button>
        <button class="button is-danger" @click="onDeleteAccountClick">
          {{ $t("edit_profile.delete_my_account") }}
        </button>
      </div>

      <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";

export default {
  mixins: [RequestMixin],

  name: "DeleteAccountDialog",

  requests: {
    ...mapActions(["deleteAccount"]),
  },

  methods: {
    onDeleteAccountClick() {
      this.deleteAccount().then(result => this.$parent.close());
    },
  },
};
</script>
