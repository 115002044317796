<template>
  <div class="field mb-1">
    <div class="content">
      <!-- HTML content -->
      <span v-if="hasHtml" v-html="$t(labelKey)" class="consent-text"></span>

      <!-- Regular content with links -->
      <template v-else>
        <div class="text-wrapper">
          <i18n :path="labelKey" tag="span" class="consent-text">
            <template v-if="hasSubstrings">
              <a target="_blank" @click.stop="0" v-for="keyValue in substringLinkMap" :href="keyValue.link" :key="keyValue.substring">
                {{ keyValue.substring }}
              </a>
            </template>
          </i18n>

          <!-- Read more button -->
          <span class="consent-text has-text-link" @click.stop="collapsed = !collapsed" v-if="hasReadMore && !collapsed">
            {{ $t(readMoreButtonKey) }}
          </span>

          <!-- Collapsed content -->
          <i18n v-if="collapsed" :path="collapsedTextLabelKey" tag="span" class="consent-text expanded-content">
            <template v-if="hasCollapsedTextSubstrings">
              <a
                target="_blank"
                @click.stop="0"
                v-for="keyValue in collapsedTextSubstringLinkMap"
                :href="keyValue.link"
                :key="keyValue.substring"
              >
                {{ keyValue.substring }}
              </a>
            </template>
          </i18n>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "RegisterInformationInput",

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    hasHtml() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.HAS_HTML];
    },

    labelKey() {
      return `${this.translationsNamespace}.${this.fieldId}_label`;
    },

    readMoreButtonKey() {
      return `${this.translationsNamespace}.${this.fieldId}_button`;
    },

    collapsedTextLabelKey() {
      return `${this.translationsNamespace}.${this.fieldId}_collapsed_text_labels`;
    },

    hasReadMore() {
      return !!this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED];
    },

    substringsKey() {
      return `${this.translationsNamespace}.${this.fieldId}_substrings`;
    },

    linksKey() {
      return `${this.translationsNamespace}.${this.fieldId}_links`;
    },

    substringLinkMap() {
      const links = Object.values(this.$t(this.linksKey));
      const substrings = Object.values(this.$t(this.substringsKey));

      if (substrings && substrings.length && this.$t(this.substringsKey) !== this.substringsKey) {
        return substrings.map((substring, index) => ({
          substring,
          link: links[index],
        }));
      }
      return [];
    },

    hasSubstrings() {
      return this.substringLinkMap.length > 0;
    },

    collapsedTextSubstringsKey() {
      return `${this.translationsNamespace}.${this.fieldId}_collapsed_substrings`;
    },

    collapsedTextLinksKey() {
      return `${this.translationsNamespace}.${this.fieldId}_collapsed_links`;
    },

    collapsedTextSubstringLinkMap() {
      const links = Object.values(this.$t(this.collapsedTextLinksKey));
      const substrings = Object.values(this.$t(this.collapsedTextSubstringsKey));

      if (substrings && substrings.length && this.$t(this.collapsedTextSubstringsKey) !== this.collapsedTextSubstringsKey) {
        return substrings.map((substring, index) => ({
          substring,
          link: links[index],
        }));
      }
      return [];
    },

    hasCollapsedTextSubstrings() {
      return this.collapsedTextSubstringLinkMap.length > 0;
    },
  },
};
</script>

<style scoped>
.has-text-link {
  cursor: pointer;
}

.expanded-content {
  margin-left: 0.25em; /* Adds space before expanded content */
}

.text-wrapper {
  line-height: 1.25; /* Match the line height from checkbox */
  display: inline-block;
}
</style>
