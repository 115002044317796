<template>
  <div class="modal-card is-relative">
    <b-loading :active="isRequesting" :is-full-page="false"></b-loading>
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ modalTitle }}
      </h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <template v-if="calendarEvents && !calendarEvents.length">
        <div class="subtitle">
          {{ $t("google_calendar.empty_events_description") }}
        </div>
        <div class="columns is-mobile is-gapless mt-1">
          <div class="column is-half">
            <button class="button is-primary has-fullwidth mr-1" @click="$parent.close()">
              {{ $t("common.ok") }}
            </button>
          </div>
          <div class="column is-half">
            <button class="button has-fullwidth ml-1" @click="tryAgain">
              {{ $t("common.try_again_capital_letters") }}
            </button>
          </div>
        </div>
      </template>
      <template v-else-if="calendarEvents && calendarEvents.length">
        <div class="subtitle">
          {{ $t("google_calendar.import_options_description") }}
        </div>
        <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
          <button class="button is-fullwidth is-primary mx-1" style="margin-top: 1rem" @click="merge">
            {{ $t("google_calendar.import_merge") }}
          </button>
          <button class="button is-fullwidth mx-1" style="margin-top: 1rem" @click="replace">
            {{ $t("google_calendar.import_replace") }}
          </button>
        </div>
        <div v-if="error">
          {{ error | pluckError }}
        </div>
      </template>
      <template v-else>
        <div class="columns is-multiline">
          <div v-for="calendar in calendars" :key="calendar.id" class="column is-half">
            <google-calendar :calendar="calendar" @input="toggleSelection(calendar, $event)"></google-calendar>
          </div>
        </div>
        <button class="button is-fullwidth is-primary" style="margin-top: 1rem" @click="syncSelectedCalendars">
          {{ $t("google_calendar.import") }}
        </button>
      </template>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import GoogleCalendar from "./GoogleCalendar";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { transformUnavailabilityToAvailability, mergeCollidingAvailabilities } from "@/shared/utils";
import RequestMixin from "@/shared/mixins/RequestMixin";

export default {
  name: "GoogleCalendarModal",

  components: { GoogleCalendar, CloseIcon },

  mixins: [RequestMixin],

  props: {
    calendars: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedCalendarIds: [],
      calendarEvents: null,
    };
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters("businessMatchingAvailability", ["availabilityDays", "allAvailabilities"]),

    modalTitle() {
      if (this.calendarEvents) {
        if (this.calendarEvents.length) {
          return this.$t("google_calendar.import_options_title");
        } else {
          return this.$t("google_calendar.empty_events_title");
        }
      } else {
        return this.$t("google_calendar.choose_calendars");
      }
    },
  },

  methods: {
    ...mapActions("businessMatchingAvailability", ["sendAvailability"]),
    ...mapMutations("businessMatchingAvailability", ["setAvailability"]),

    async syncSelectedCalendars() {
      const eventEndDate = this.event.end_date;
      this.$gapi
        .getGapiClient()
        .then(gapi => {
          return Promise.all(
            this.selectedCalendarIds.map(calendarId => {
              return gapi.client.request({
                method: "GET",
                path: `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events`,
                params: {
                  timeMin: new Date().toISOString(),
                  timeMax: eventEndDate,
                  maxResults: 2500,
                  orderBy: "startTime",
                  singleEvents: true,
                },
              });
            })
          );
        })
        .then(results => {
          this.calendarEvents = results.reduce((acc, item) => {
            return [...acc, ...item.result.items];
          }, []);
        });
    },

    toggleSelection(calendar, isSelected) {
      if (isSelected) {
        this.selectedCalendarIds = [...this.selectedCalendarIds, calendar.id];
      } else {
        this.selectedCalendarIds = this.selectedCalendarIds.filter(calendarId => calendarId !== calendar.id);
      }
    },

    tryAgain() {
      this.selectedCalendarIds = [];
      this.calendarEvents = null;
    },

    merge() {
      const newAvailabilities = this.transformEventsToAvailability();
      const oldAvailabilities = this.allAvailabilities;
      this.setAvailability(mergeCollidingAvailabilities(newAvailabilities, oldAvailabilities));
      this.send();
    },

    replace() {
      this.setAvailability(this.transformEventsToAvailability());
      this.send();
    },

    transformEventsToAvailability() {
      const unavailability = this.calendarEvents.map(calendarEvent => {
        return {
          unavailability_from: calendarEvent.start.dateTime,
          unavailability_to: calendarEvent.end.dateTime,
        };
      });
      const eventDays = this.availabilityDays;
      return transformUnavailabilityToAvailability(unavailability, eventDays);
    },
  },

  requests: {
    async send() {
      await this.sendAvailability().then(result => {
        this.$parent.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
  }
}

.column {
  height: auto;
}

.subtitle {
  white-space: pre-line;
  text-align: center;
}
</style>
