<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--CUSTOM DESCRIPTION TEXT-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.event_description_custom") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editCustomText">
        {{ customTextLabel }}
      </b-button>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigEventDescription",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  data() {
    const moduleFields = LpConfigConstants.EVENT_DESCRIPTION_FIELDS;
    return {
      [moduleFields.CUSTOM_TEXT]: this.value[moduleFields.CUSTOM_TEXT],
      awaitingFieldForTranslationKey: null,
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.EVENT_DESCRIPTION_FIELDS,

    customTextLabel() {
      return this.$data[this.moduleFields.CUSTOM_TEXT] || this.$t("admin_panel.select_translation_key");
    },

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  methods: {
    editCustomText() {
      this.awaitingFieldForTranslationKey = this.moduleFields.CUSTOM_TEXT;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey) {
        this.$data[this.awaitingFieldForTranslationKey] = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
