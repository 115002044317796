import { Constants } from "@/web/constants";
import ExhibitorModel from "@/web/store/models/ExhibitorModel";
import ExhibitorTagModel from "@/widgets/exhibitors/store/models/ExhibitorTagModel";

export const state = () => ({
  statusExhibitors: null,
  error: null,
  statusExhibitor: null,
  search: "",
  filteredTagIds: [],
});
export const types = {
  LOAD_EXHIBITORS: "loadExhibitors",
  LOAD_EXHIBITORS_SUCCESS: "loadExhibitorsSuccess",
  LOAD_EXHIBITORS_ERROR: "loadExhibitorsError",
  LOAD_EXHIBITOR: "loadExhibitor",
  LOAD_EXHIBITOR_SUCCESS: "loadExhibitorSuccess",
  LOAD_EXHIBITOR_ERROR: "loadExhibitorError",
};
export const mutations = {
  [types.LOAD_EXHIBITORS](state) {
    state.statusExhibitors = Constants.STATUS_LOADING;
  },
  [types.LOAD_EXHIBITORS_SUCCESS](state) {
    state.statusExhibitors = Constants.STATUS_LOADED;
  },
  [types.LOAD_EXHIBITORS_ERROR](state, error) {
    state.statusExhibitors = Constants.STATUS_ERROR;
    state.error = error;
  },
  [types.LOAD_EXHIBITOR](state) {
    state.statusExhibitor = Constants.STATUS_LOADING;
  },
  [types.LOAD_EXHIBITOR_SUCCESS](state) {
    state.statusExhibitor = Constants.STATUS_LOADED;
  },
  [types.LOAD_EXHIBITOR_ERROR](state, error) {
    state.statusExhibitor = Constants.STATUS_ERROR;
    state.error = error;
  },
  setSearch(state, value) {
    state.search = value;
  },
  setFilters(state, value) {
    state.filteredTagIds = value;
  },
};
export const actions = {
  async loadExhibitors({ dispatch }, { eventId, componentId }) {
    return await dispatch("fetch", { eventId, componentId });
  },
  async fetch({ commit }, { eventId, componentId }) {
    commit(types.LOAD_EXHIBITORS);
    return ExhibitorModel.api()
      .get(`events/${eventId}/components/${componentId}/exhibitors`)
      .then(exhibitors => {
        commit(types.LOAD_EXHIBITORS_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_EXHIBITORS_ERROR, err);
        throw err;
      });
  },
  fetchById({ commit }, { eventId, componentId, exhibitorId }) {
    commit(types.LOAD_EXHIBITOR);
    return ExhibitorModel.api()
      .get(`events/${eventId}/components/${componentId}/exhibitor/${exhibitorId}`)
      .then(exhibitor => {
        commit(types.LOAD_EXHIBITOR_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_EXHIBITOR_ERROR, err);
        throw err;
      });
  },
};

export const getters = {
  getById: state => exhibitorId => {
    return ExhibitorModel.query().whereId(exhibitorId).withAllRecursive().first();
  },
  visibleExhibitors: state => componentId => {
    let exhibitors = ExhibitorModel.query().where("event_component_id", componentId).withAllRecursive();
    exhibitors = exhibitors.get();

    if (state.filteredTagIds.length) {
      exhibitors = [...exhibitors].filter(exhibitor => exhibitor.tags_exhibitors.some(tag => state.filteredTagIds.includes(tag.id)));
    }

    if (state.search.length) {
      const normalizedTerm = state.search.toUpperCase();
      exhibitors = [...exhibitors].filter(
        e => e.name.toUpperCase().includes(normalizedTerm) || e.scope_description.toUpperCase().includes(normalizedTerm)
      );
    }

    return exhibitors.sort((a, b) => {
      if (a.vip !== b.vip) {
        return a.vip ? -1 : 1;
      } else {
        return a.order - b.order;
      }
    });
  },
  isLoadedExhibitors(state) {
    return state.statusExhibitors === Constants.STATUS_LOADED;
  },
  isLoadingExhibitors(state) {
    return state.statusExhibitors === Constants.STATUS_LOADING;
  },
  isLoadedExhibitor(state) {
    return state.statusExhibitor === Constants.STATUS_LOADED;
  },
  isLoadingExhibitor(state) {
    return state.statusExhibitor === Constants.STATUS_LOADING;
  },
  search(state) {
    return state.search;
  },
  filteredTagsIds(state) {
    return state.filteredTagIds;
    // return ExhibitorTagModel.query().whereId(state.filteredTagIds).all();
  },
  allTags(state) {
    return ExhibitorTagModel.all();
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
