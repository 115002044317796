export const LpConfigConstants = Object.freeze({
  ROOT_FIELDS: {
    HEADER: "header",
    TIMER: "timer",
    THEME: "theme",
    INBOX: "inbox",
    SIDE_MENU: "side_menu",
    FEATURES: "features",
    HELP: "help",
    NAVBAR: "navbar",
    FOOTER: "footer",
    PAYMENT: "payment",
    MODULES: "modules",
    TRANSLATIONS: "translations",
    REGISTER_FORM: "register_form",
    TICKET_FORMS: "ticket_forms",
    CONFIGURATOR_ENABLED: "configurator_enabled",
    LANGUAGE: "language",
  },

  HEADER_FIELDS: {
    EVENT_LOGO_URL: "event_logo_url",
    IMAGE_URL: "image_url",
    SHOW_DETAILS: "show_details",
    SHOW_DATE: "show_date",
    SHOW_LOCATION: "show_location",
    HEIGHT: "height",
    BACKGROUND_COLOR: "background_color",
    TEXT_COLOR: "text_color",
    IMAGE_AUTO_RESIZE: "image_auto_resize",
    IMAGE_AUTO_RESIZE_MARGIN_TOP: "image_auto_resize_margin_top",
    IMAGE_AUTO_RESIZE_MARGIN_BOTTOM: "image_auto_resize_margin_bottom",
    IMAGE_AUTO_RESIZE_MARGIN_RIGHT: "image_auto_resize_margin_right",
    IMAGE_AUTO_RESIZE_MARGIN_LEFT: "image_auto_resize_margin_left",
    IMAGE_REPEAT: "image_repeat",
    IMAGE_POSITION: "image_position",
    IMAGE_SIZE: "image_size",
  },

  BACKGROUND_REPEAT_VALUES: {
    INITIAL: "initial",
    ROUND: "round",
    SPACE: "space",
    NO_REPEAT: "no-repeat",
    REPEAT: "repeat",
    REPEAT_X: "repeat-x",
    REPEAT_Y: "repeat-y",
  },

  BACKGROUND_SIZE_VALUES: {
    COVER: "cover",
    CONTAIN: "contain",
    AUTO: "auto",
  },

  BACKGROUND_POSITION_VALUES: {
    TOP: "top",
    CENTER: "center",
    BOTTOM: "bottom",
  },

  TIMER_FIELDS: {
    HIDDEN: "hidden",
    DATE: "date",
    BOTTOM_TEXT: "bottom_text",
    BOTTOM_TEXT_COLOR: "bottom_text_color",
    BOTTOM_TEXT_SIZE: "bottom_text_size",
  },

  THEME_FIELDS: {
    THEME_TYPE: "theme_type",
    SIDE_MENU_TYPE: "side_menu_type",
    FONT: "font",
    MAIN_COLOR: "main_color",
    ACCENT_COLOR: "accent_color",
    PRIMARY_TEXT_COLOR: "primary_text_color",
    SECONDARY_TEXT_COLOR: "secondary_text_color",
    BACKGROUND_COLOR: "background_color",
    NAVBAR_BACKGROUND_COLOR: "navbar_background_color",
  },

  THEME_TYPES: {
    SIMPLE_DAY: "simple-day",
    BUSINESS_DAY: "business-day",
    UPGRADE_EVENT_2023: "upgrade-event-2023",
    PISM_2023: "pism-2023",
    SURVIVAL_RACE_KIDS: "survival-race-kids",
    CUSTOM_TUV_FONT: "custom-tuv-font",
    CUSTOM_MRH_FONT: "mrh-font",
    CUSTOM_IT_TOMORROW_FONT: "it-tomorrow-font",
    UNITY_COMMERCE: "unity-commerce",
    GOOGLE_CLOUD_SUMMIT: "google-cloud-summit",
    PED_2023: "ped-2023",
    RZECOMMERCE_2024: "rzecommerce-2024",
  },

  SIDE_MENU_TYPES: {
    BUBBLES: "bubbles",
    SIDE_PANEL: "side_panel",
  },

  FONT_TYPES: {
    POPPINS: "Poppins",
    NUNITO: "Nunito",
    ROBOTO: "Roboto",
    LATO: "Lato",
    DOSIS: "Dosis",
    COMIC_SANS: "Comic Sans",
    OPEN_SANS: "Open Sans",
    MANROPE: 'Manrope',
  },

  INBOX_FIELDS: {
    ENABLED: "enabled",
    COMPONENT_ID: "component_id",
  },

  SIDE_MENU_FIELDS: {
    TOOLTIPS: "tooltips",
    TITLES: "titles",
    ICONS: "icons",
    BUBBLE_TYPES: {
      INBOX_MESSAGES_BUBBLE: "inbox_messages_bubble",
      QUIZZES_BUBBLE: "quizzes_bubble",
      FEED_WALL_BUBBLE: "feed_wall_bubble",
      AGENDA_SESSION_QUESTIONS_BUBBLE: "agenda_session_questions_bubble",
    },
  },

  FEATURES_FIELDS: {
    AGENDA_RATING_POPUP: "agenda_rating_popup",
    AGENDA_RATING_POPUP_FIELDS: {
      EVENT_ID: "event_id",
      QUIZ_COMPONENT_ID: "quiz_component_id",
    },
    CUSTOM_SEPARATOR: "custom_separator",
  },

  HELP_FIELDS: {
    ENABLED: "enabled",
    CUSTOM_SUPPORT_USER_UUID: "customer_support_user_uuid",
    FEEDBACK_TITLE: "feedback_title",
    FEEDBACK_DESCRIPTION: "feedback_description",
    LIVECHAT_TITLE: "livechat_title",
  },

  NAVBAR_FIELDS: {
    BACKGROUND_COLOR: "background_color",
    BACKGROUND_IMAGE_URL: "background_image_url",
    BACKGROUND_IMAGE_REPEAT: "background_image_repeat",
    BACKGROUND_IMAGE_SIZE: "background_image_size",
    BUTTON_TEXT_COLOR: "button_text_color",
    ICON_COLOR: "icon_color",
    CUSTOM_BUTTONS: "custom_buttons",
    LOGO_WITH_OUTLINE: "LOGO_WITH_OUTLINE",
    LOGO_WIDTH: "logo_width",
    LOGO_BACKGROUND_COLOR: "logo_background_color",
    CUSTOM_BUTTONS_FIELDS: {
      TYPE: "type",
      TARGET: "target",
      URL: "url",
      ORDER: "order",
      NAVBAR_TITLE: "navbar_title",
      MODAL_TITLE: "modal_title",
      MODAL_DESCRIPTION: "modal_description",
      MODAL_IMAGE_URL: "modal_image_url",
      TYPES: {
        REDIRECT: "redirect",
        MODAL: "modal",
      },
      TARGETS: {
        BLANK: "_blank",
        SELF: "_self",
      },
    },
  },

  FOOTER_FIELDS: {
    BACKGROUND_COLOR: "background_color",
    TEXT_COLOR: "text_color",
    TEXT_COLOR_HOVER: "text_color_hover",
  },

  PAYMENT_FIELDS: {
    GATEWAYS: "gateways",
    VAT: "vat",
    FOOTER_BUTTON_TEXT_KEY: "footer_button_text_key",
    CHECKBOX_TEXT_KEY: "checkbox_text_key",
    CHECKBOX_SUBSTRING_KEY: "checkbox_substring_key",
    TICKET_TERMS_URL: "ticket_terms_url",
    DISABLE_ORGANIZATIONAL_DATA_STEP: "disable_organizational_data_step",
    CALL_TO_ACTION_IMAGE_URL_KEY: "call_to_action_image_url_key",
    CALL_TO_ACTION_TITLE_KEY: "call_to_action_title_key",
    CALL_TO_ACTION_DESCRIPTION_KEY: "call_to_action_description_key",
    CALL_TO_ACTION_WIDTH: "call_to_action_width",
    CALL_TO_ACTION_HEIGHT: "call_to_action_height",
    CALL_TO_ACTION_TITLE_TEXT_COLOR: "call_to_action_title_text_color",
    CALL_TO_ACTION_DESCRIPTION_TEXT_COLOR: "call_to_action_description_text_color",
  },

  MODULE_TYPES: {
    EVENT_TICKETS: "component_event_tickets",
    APP_INFO: "mobile_app_info",
    SOCIAL_LINKS: "component_social_links",
    TWITTER: "component_twitter",
    IFRAME: "iframe",
    IFRAME_NATIVE: "iframe_native",
    COMPONENT_LIVE_WEBINAR: "component_live_webinar",
    EVENT_DESCRIPTION: "component_event_description",
    COMPONENT_AGENDA: "component_agenda",
    COMPONENT_SPEAKERS: "component_speakers",
    COMPONENT_ATTENDEES: "component_attendees",
    COMPONENT_EXHIBITORS: "component_exhibitors",
    COMPONENT_PARTNERS: "component_partners",
    COMPONENT_FEED_WALL: "component_feed_wall",
    COMPONENT_QUIZZES: "component_quizzes",
    COMPONENT_SRK_CUSTOM_MODULE: "srk_module",
    COMPONENT_EVENT_TIMER: "component_event_timer",
    COMPONENT_FAQ: "component_faq",
    COMPONENT_BUSINESS_MATCHING: "component_business_matching",
    CUSTOM_COMPONENT_MRH: "mrh_module",
    COMPONENT_EVENT_CONTACT_INFO: "component_event_contact_information",
    PISM_ABOUT: "pism_module_1",
    PISM_TOPICS: "pism_module_2",
    PISM_2023_ABOUT: "pism_2023_module_1",
    PISM_2023_TOPICS: "pism_2023_module_2",
    PISM_2024_PRESIDENT_BANNER: "pism_2024_president_banner",
    SPREADSHEET_FORM_MODULE: "spreadsheet_form_module",
    PED_TICKET: "ped_ticket_module",
    PED_INFO: "ped_info_module",
    PED_STAGE: "ped_stages",
    PED_LOCATION: "ped_location_module",
    PED_PREVIOUS_EDITION: "ped_previous_edition_module",
    RZECOMMERCE_INFO: 'rzecommerce_info_module',
    RZECOMMERCE_FAQ: 'rzecommerce_faq_module',
    RZECOMMERCE_BENEFITS: 'rzecommerce_benefits_module',
  },

  NAVBAR_ACTION_TYPES: {
    SCROLL: "scroll",
    PAGE: "page",
  },

  COMMON_MODULE_FIELDS: {
    TYPE: "type",
    COMPONENT_ID: "component_id",
    ORDER: "order",
    TITLE: "title",
    ONLY_FOR_LOGGED_IN: "only_for_logged_in",
    ONLY_FOR_GUESTS: "only_for_guests",
    VISIBLE: "visible",
    NAVBAR_TITLE: "navbar_title",
    NAVBAR_ACTION: "navbar_action",
    SEPARATOR_DISABLED: "separator_disabled",
  },

  TWITTER_MODULE_FIELDS: {
    TWITTER_URL: "twitter_url",
    THEME: "theme",
    HEIGHT: "height",
  },

  MOBILE_APP_INFO_FIELDS: {
    APP_NAME: "app_name",
    DESCRIPTION: "description",
    PREVIEW_IMAGE: "preview_image",
    PREVIEW_IMAGE_VISIBLE: "preview_image_visible",
  },

  SOCIAL_LINKS_MODULE_FIELDS: {
    SOCIAL_LINKS: "social_links",
    SOCIAL_LINK_TYPE: "type",
    SOCIAL_LINK_TARGET: "target",
  },

  SOCIAL_LINK_TYPES: {
    FACEBOOK: "facebook",
    INSTAGRAM: "instagram",
    YOUTUBE: "youtube",
    WWW: "www",
    TWITTER: "twitter",
    LINKEDIN: "linkedin",
  },

  EVENT_TICKETS_MODULE_FIELDS: {
    TICKET_VIEW_TYPE: "ticket_view_type",
    TICKET_IDS: "ticket_ids",
    PRETAX_PRICE_VISIBLE: "pretax_price_visible",
    PRETAX_PRICE_HIGHLIGHTED: "pretax_price_highlighted",
    EQUAL_HEIGHTS: "equal_heights",
    CUSTOM_DESCRIPTIONS: "custom_descriptions",
    SUBTITLE: "subtitle",
  },

  EVENT_TICKET_VIEW_TYPES: {
    REGULAR: "regular",
    CINEMA: "cinema",
  },

  AGENDA_MODULE_FIELDS: {
    LP_MODE: "lp_mode",
    THEME: "theme",
    HIDE_SEARCH: "hide_search",
    HIDE_DAYS: "hide_days",
    HIDE_SESSION_TIME: "hide_session_time",
    SHOW_LIVE_INDICATOR: "show_live_indicator",
    HIDE_LIVE_STREAM_BUTTONS: "hide_live_stream_buttons",
    LIVEWEBINAR_ONLY_FOR_AUTHORIZED: "livewebinar_only_for_authorized",
    PAGE_SIZE: "page_size",
    CUSTOM_SPONSORS: "custom_sponsors",
    COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED: "component_ids_with_rating_always_enabled",
  },

  SPEAKERS_MODULE_FIELDS: {
    PAGE_SIZE: "page_size",
    SHOW_COUNTRY: "show_country",
    SEARCH_DISABLED: "search_disabled",
  },

  ATTENDEES_MODULE_FIELDS: {
    AGENDA_SESSION_ID: "agenda_session_id",
  },

  EXHIBITORS_MODULE_FIELDS: {
    PAGE_SIZE: "page_size",
  },

  PARTNERS_MODULE_FIELDS: {
    VIEW_TYPE: "view_type",
    CAROUSEL_DISABLED: "carousel_disabled",
    OPEN_EXTERNAL_LINK: "open_external_link",
    CARD_BACKGROUND_COLOR: "card_background_color",
    CAROUSEL_BACKGROUND_COLOR: "carousel_background_color"
  },

  PARTNERS_MODULE_VIEW_TYPES: {
    CATEGORIES: "categories",
    SLIDER: "slider",
  },

  EVENT_DESCRIPTION_FIELDS: {
    CUSTOM_TEXT: "custom_text"
  },

  EVENT_CONTACT_INFO_FIELDS: {
    EMAIL_VISIBLE: "email_visible",
    PHONE_VISIBLE: "phone_visible",
    WEBSITE_VISIBLE: "website_visible",
    CUSTOM_EMAIL: "custom_email",
    CUSTOM_WWW: "custom_www",
    CUSTOM_PHONE: "custom_phone",
  },

  EVENT_TIMER_MODULE_FIELDS: {
    SUBTITLE: "subtitle",
    BUTTON_TEXT: "button_text",
    BUTTON_VISIBLE: "button_visible",
    BUTTON_SCROLL_TO_MODULE: "button_scroll_to_module",
  },

  FEED_WALL_FIELDS: {},

  QUIZZES_MODULE_FIELDS: {
    PAGE_SIZE: "page_size",
  },

  IFRAME_MODULE_FIELDS: {
    TABS_ENABLED: "tabs_enabled",
    TABS_DATA: "tabs_data",
    FULL_SCREEN_ENABLED: "full_screen_enabled",
  },

  IFRAME_TAB_FIELDS: {
    HTML: "html",
    SCRIPT: "script",
    TITLE: "title",
  },

  IFRAME_NATIVE_MODULE_FIELDS: {
    SCRIPT: "script",
    HTML: "html",
  },

  LIVEWEBINAR_MODULE_FIELDS: {
    TABS_ENABLED: "tabs_enabled",
    TABS_DATA: "tabs_data",
    TIMEOUT: "timeout",
    LW_CONTAINER_ID: "lw_container_id",
    FULL_SCREEN_ENABLED: "full_screen_enabled",
  },

  LIVEWEBINAR_TAB_FIELDS: {
    LW_LICENSE_KEY: "lw_license_key",
    TAB_TITLE_KEY: "tab_title_key",
  },

  BUTTON_POPUP_MODULE_FIELDS: {
    BUTTON_TEXT: "button_text",
    POPUP_TITLE: "popup_title",
    POPUP_IMAGE_URL: "popup_image_url",
    POPUP_TEXT: "popup_text",
    POPUP_TEXT_ALIGNMENT: "popup_text_alignment",
  },

  POPUP_TEXT_ALIGNMENT_OPTIONS: {
    CENTER: "center",
    LEFT: "left",
    RIGHT: "right",
  },

  FAQ_MODULE_FIELDS: {
    QUESTIONS_DATA: "questions_data",
  },

  FAQ_QUESTION_FIELDS: {
    QUESTION: "question",
    ANSWER: "answer",
  },
});

export function getDefaultModule() {
  const commonModuleFields = LpConfigConstants.COMMON_MODULE_FIELDS;
  return {
    [commonModuleFields.VISIBLE]: true,
    [commonModuleFields.ONLY_FOR_LOGGED_IN]: false,
    [commonModuleFields.ONLY_FOR_GUESTS]: false,
    [commonModuleFields.SEPARATOR_DISABLED]: false,
  };
}

export default LpConfigConstants;
