import { Model } from "@vuex-orm/core";
import EventDisplayModel from "./EventDisplayModel";
import UserSessionTicketModel from "./UserSessionTicketModel";
import TicketModel from "./TicketModel";

export default class UserEventTicketModel extends Model {
  static entity = "userEventTickets";

  static fields() {
    return {
      id: this.number(null),
      ticket_reservation_uuid: this.string(),
      has_invoice: this.boolean(false),
      amount: this.number(null),
      event_display_data_id: this.attr(null),
      event_display_data: this.belongsTo(EventDisplayModel, "event_display_data_id"),
      event_ticket_id: this.attr(null),
      event_ticket: this.belongsTo(TicketModel, "event_ticket_id"),
    };
  }

  static loadUserTickets(eventId) {
    return UserEventTicketModel.api().get(`current_user/tickets`, {
      dataTransformer({ data }) {
        // Filter only the current event tickets
        // eslint-disable-next-line prettier/prettier
        const eventTickets = data.event_ticket_reservations.filter(ticket => {
          const isFromCurrentEvent = ticket.event_display_data.id === eventId;
          const isFromOldPayments = !!ticket.event_ticket;
          return isFromCurrentEvent && isFromOldPayments;
        });
        const agendaSessionTickets = data.agenda_session_ticket_reservations.filter(ticket => {
          const isFromCurrentEvent = ticket.event_display_data.id === eventId;
          const isFromAgendaPlaceReservation = !!ticket.agenda_session_ticket;
          return isFromCurrentEvent && isFromAgendaPlaceReservation;
        });
        UserSessionTicketModel.create({
          data: agendaSessionTickets,
          insertOrUpdate: ["attachments", "agendaDisplaySessions", "eventDisplay", "tickets", "agenda_session_paths"],
        });
        return eventTickets;
      },
    });
  }
}
