<template>
  <div class="container mt-3">
    <separator class="separator is-primary"></separator>
    <div class="has-text-centered title">
      {{ $t("auth.change_password") }}
    </div>
    <edit-password-form class="mt-2" ref="form" :error="error" @submit="updatePassword"></edit-password-form>
    <b-loading :active="isRequesting" :is-full-page="false" class="is-small"></b-loading>
  </div>
</template>

<script>
import EditPasswordForm from "@/web/components/forms/EditPasswordForm";
import Separator from "@/web/components/shared/Separator";
import RequestMixin from "@/shared/mixins/RequestMixin";
import { mapActions } from "vuex";
import Constants from "@/web/constants";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";

export default {
  name: "EditPassword",

  mixins: [RequestMixin, AuthorizedRouteMixin],

  components: { EditPasswordForm, Separator },

  requests: {
    async updatePassword(credentials) {
      await this.updateUserPassword({ ...credentials });
      this.onSuccess();
    },
  },

  methods: {
    ...mapActions("auth", ["updateUserPassword"]),

    onSuccess() {
      this.$router.replace({ name: Constants.ROUTE_MY_PROFILE });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 720px !important;
}
</style>
